import axios from "../../axios";

const state = {
  regions: [],
  customerRegions: [],
};

const getters = {
  getRegions(state) {
    return state.regions;
  },
};

const mutations = {
  FETCH_REGIONS(state, payload) {
    state.regions = payload;
  },
  FETCH_CUSTOMER_REGIONS(state, payload) {
    state.customerRegions = payload;
  },
};

const actions = {
  fetchRegions({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=regions", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_REGIONS", response.data.data);
            resolve(response);
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCustomerRegions({ rootState, commit }, customerID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("customerID", customerID);
      axios
        .post(rootState.baseUrl + "/get.php?type=customerRegions", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_CUSTOMER_REGIONS", response.data.data);
            resolve(response);
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
