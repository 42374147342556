<template>
  <canvas ref="pieChart" :width="width" :height="width" id="pie-chart"></canvas>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "PieChart",
  props: {
    width: {
      required: true,
      type: Number,
      default: 200,
      validator: (value) => value > 0,
    },
    height: {
      required: true,
      type: Number,
      default: 200,
      validator: (value) => value > 0,
    },
    labels: Array,
    datasets: {
      type: Array,
      required: true,
    },
    options: Object,
  },

  mounted() {
    new Chart(this.$refs.pieChart, {
      type: "pie",
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: this.options,
    });
  },
};
</script>

<style lang="scss" scoped>
#pie-chart {
  width: 100%;
  height: 100%;
}
</style>
