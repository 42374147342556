<template>
  <div class="address-form">
    <alerts :errors="errors" />
    <div class="row border rounded bg-light g-3 my-2">
      <div class="col-md-4">
        <label for="streetAddress" class="form-label">Gatuadress</label>
        <input
          id="streetAddress"
          type="text"
          class="form-control"
          v-model="address.streetAddress"
        />
      </div>
      <div class="col-md-4">
        <label for="zipcode" class="form-label">Postnummer</label>
        <input
          id="zipcode"
          type="text"
          class="form-control"
          v-model="address.zipcode"
        />
      </div>
      <div class="col-md-4">
        <label for="city" class="form-label">Ort</label>
        <input
          id="city"
          type="text"
          class="form-control"
          v-model="address.city"
        />
      </div>
      <div class="col-md-4">
        <label for="country" class="form-label">Välj land</label>
        <select class="form-select" id="country" v-model="address.country">
          <option :value="null">Välj land</option>
          <option
            :value="country"
            v-for="(country, index) in countries"
            :key="index"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="addressType" class="form-label">Välj typ</label>
        <select
          class="form-select"
          id="addressType"
          v-model="address.addressType"
        >
          <option value="visiting">Besöksadress</option>
          <option value="billing">Fakturaadress</option>
          <option value="delivery">Leveransadress</option>
          <option value="post">Postadress</option>
        </select>
      </div>
      <div class="col-md-12">
        <label for="comment" class="form-label">Kommentar</label>
        <textarea
          id="comment"
          class="form-control"
          rows="3"
          v-model="address.comment"
        ></textarea>
      </div>
      <div class="d-grid gap-2 mb-3">
        <button
          class="btn btn-secondary fw-bold"
          type="button"
          @click="submit()"
        >
          Spara
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import data from "@/assets/countries.json";
import Alerts from "@/components/Alerts.vue";
export default {
  name: "address-form",
  data() {
    return {
      countries: data,
      address: {
        agency: null,
        addressType: "visiting",
        streetAddress: null,
        zipcode: null,
        city: null,
        country: null,
        comment: null,
        lastUpdated: null,
      },
      errors: [],
    };
  },

  components: { Alerts },

  mounted() {
    this.address.country = this.countries.find(
      (country) => country.code === "SE"
    );
  },

  methods: {
    submit() {
      this.errors = [];
      if (!this.validateForm()) {
        this.$emit("address", this.address);
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.address.streetAddress) {
        hasError = true;
        this.errors.push("Ange gatuadress");
      }
      return hasError;
    },
  },
};
</script>
