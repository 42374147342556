<template>
  <main>
    <div class="paycheck w-100 pb-3">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card mb-3 info">
          <div class="card-body">
            <i class="bi bi-info-circle-fill"></i>
            <p class="m-0">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              culpa sint, provident ut aut molestiae facere quibusdam vitae
              tenetur laboriosam ratione quod sit ipsum, aperiam cupiditate!
              Dolorum voluptates hic voluptatibus!
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "PayCheck",
  data() {
    return {
      navbar: "Pipeline",
    };
  },

  components: {
    Navbar,
  },

  mounted() {
    this.checkRouteName();
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },
  },
};
</script>
