<template>
  <main>
    <div class="aftersales h-100 w-100">
      <navbar title="Aftersales" />
      <div class="content p-3">
        <div class="card mb-2">
          <div class="card-body">
            <h3>Försäljning per utgåva</h3>
            <div class="row my-3">
              <div class="col-2">
                <datepicker
                  class="form-control"
                  placeholder="Välj datum"
                  :locale="locale"
                  :minimumView="'year'"
                  :inputFormat="'yyyy'"
                  :lower-limit="lowerLimit"
                  :upper-limit="upperLimit"
                  v-model="selectedDate"
                  @update:modelValue="yearSelected()"
                />
              </div>
              <div class="col-1">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="exportExcel2()"
                >
                  Excel
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center my-4" v-if="loading">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <table
              class="table table-striped"
              v-if="orders && orders.issues && orders.issues.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">Utgåva</th>
                  <th scope="col">Antal ordrar Region</th>
                  <th scope="col">Totalt Region</th>
                  <th scope="col">Antal ordrar Riks</th>
                  <th scope="col">Totalt Riks</th>
                  <th scope="col">Totalt per utgåva</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(deal, index) in orders.issues"
                  :key="index"
                  @click="selectIssue(deal)"
                  style="cursor: pointer"
                  :class="{ selected: deal.issueName == selectedIssue }"
                >
                  <td>
                    {{ deal.issueName }}
                  </td>
                  <td>
                    {{ deal.regionCount }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.regionTotal) }}
                  </td>
                  <td>
                    {{ deal.riksCount }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.riksTotal) }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.regionTotal + deal.riksTotal) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="alert alert-secondary"
              role="alert"
              v-if="
                !orders ||
                !orders.issues ||
                (orders.issues.length == 0 && !loading)
              "
            >
              <p class="m-0">Ingen försäljning hittades</p>
            </div>
          </div>
        </div>
        <div class="card mb-2 orders" v-if="selectedIssue">
          <div class="card-body">
            <h3>Försäljning per order</h3>
            <button
              type="button"
              class="btn btn-secondary"
              @click="exportExcel()"
            >
              Excel
            </button>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" @click="sortBy('dealID')">
                    Ordernummer
                    <i
                      class="bi bi-arrow-down"
                      v-if="sortColumn === 'dealID' && sortDirection !== 'asc'"
                    ></i>
                    <i
                      class="bi bi-arrow-up"
                      v-if="sortColumn === 'dealID' && sortDirection === 'asc'"
                    ></i>
                  </th>
                  <th scope="col" @click="sortBy('company')">
                    Företag
                    <i
                      class="bi bi-arrow-down"
                      v-if="sortColumn === 'company' && sortDirection !== 'asc'"
                    ></i>
                    <i
                      class="bi bi-arrow-up"
                      v-if="sortColumn === 'company' && sortDirection === 'asc'"
                    ></i>
                  </th>
                  <th scope="col" @click="sortBy('issueName')">
                    Utgåva
                    <i
                      class="bi bi-arrow-down"
                      v-if="
                        sortColumn === 'issueName' && sortDirection !== 'asc'
                      "
                    ></i>
                    <i
                      class="bi bi-arrow-up"
                      v-if="
                        sortColumn === 'issueName' && sortDirection === 'asc'
                      "
                    ></i>
                  </th>
                  <th scope="col" @click="sortBy('realname')">
                    Säljare
                    <i
                      class="bi bi-arrow-down"
                      v-if="
                        sortColumn === 'realname' && sortDirection !== 'asc'
                      "
                    ></i>
                    <i
                      class="bi bi-arrow-up"
                      v-if="
                        sortColumn === 'realname' && sortDirection === 'asc'
                      "
                    ></i>
                  </th>
                  <th scope="col" @click="sortBy('total')">
                    Totalbelopp
                    <i
                      class="bi bi-arrow-down"
                      v-if="sortColumn === 'total' && sortDirection !== 'asc'"
                    ></i>
                    <i
                      class="bi bi-arrow-up"
                      v-if="sortColumn === 'total' && sortDirection === 'asc'"
                    ></i>
                  </th>
                  <th scope="col">Faktura</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(deal, index) in filteredOrders" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'ShowCustomerDeal',
                        params: {
                          personID: deal.personID,
                          customerID: deal.customerID,
                          dealID: deal.dealID,
                        },
                      }"
                      >{{ deal.dealID }}</router-link
                    >
                  </td>
                  <td>{{ deal.company }}</td>
                  <td>
                    {{ deal.issueName }}
                  </td>
                  <td>{{ deal.realname }}</td>
                  <td>
                    {{ formatCurrency(deal.total) }}
                  </td>
                  <td>
                    <a
                      v-if="deal.invoice"
                      target="_blank"
                      :href="
                        'https://mogul.tydalsystems.se/api/getInvoice.php?key=' +
                        apiKey +
                        '&invoiceID=' +
                        deal.invoice
                      "
                      >{{ deal.invoice }}
                    </a>
                    <button
                      type="button"
                      v-if="!deal.invoice"
                      style="width: 100px"
                      class="btn btn-secondary"
                      @click="createInvoice(deal.dealID, deal.issueID)"
                    >
                      <span v-if="!loading || currentDeal != deal.dealID"
                        >Faktura</span
                      >
                      <pulse-loader
                        :loading="loading && currentDeal == deal.dealID"
                      ></pulse-loader>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { sv } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import moment from "moment";

export default {
  components: { Navbar, PulseLoader, Datepicker },
  name: "Aftersales-issues",
  data() {
    return {
      locale: sv,
      selectedIssue: null,
      selectedIssueID: null,
      loading: false,
      sortDirection: "asc",
      sortColumn: "dealID",
      selectedDate: new Date(),
      lowerLimit: new Date("2016-01-01"),
      upperLimit: null,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      orders: (state) => state.order.aftersale,
      apiKey: (state) => state.auth.apiKey,
    }),

    filteredOrders() {
      console.log(this.selectedIssue);
      if (this.orders && this.orders.rows) {
        let filtered = this.orders.rows.filter((order) => {
          return order.issueName == this.selectedIssue;
        });

        console.log(filtered);

        return filtered.slice().sort((a, b) => {
          let modifier = 1;
          if (this.sortDirection == "desc") {
            modifier = -1;
          }
          if (a[this.sortColumn] < b[this.sortColumn]) {
            return -1 * modifier;
          } else if (a[this.sortColumn] > b[this.sortColumn]) {
            return 1 * modifier;
          } else {
            return 0;
          }
        });
      } else {
        return [];
      }
    },
  },

  mounted() {
    let nextYear = moment().add(1, "years");
    this.upperLimit = new Date(nextYear);

    this.checkRouteName();

    document.title = "Aftersales";
    let year = moment(this.selectedDate).format("YYYY");

    this.loading = true;
    this.$store
      .dispatch("order/fetchAftersale", year)
      .then(() => (this.loading = false));
  },
  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    sortBy(column) {
      if (column == this.sortColumn) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
      }
    },

    selectIssue(issue) {
      this.selectedIssue = issue.issueName ?? null;
      this.selectedIssueID = issue.issueID;
    },

    exportExcel2() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      formData.append("issueID", this.selectedIssueID);
      this.$http
        .post(this.baseUrl + "/get.php?type=marketing", formData)
        .then((response) => {
          let data = response.data.data;
          saveExcel({
            data: data,
            fileName: "material_" + this.selectedIssue + ".xlsx",
            columns: [
              { field: "realname", title: "Säljare" },
              { field: "marketingEmail", title: "Materialkontakt" },
              { field: "billingEmail", title: "Fakturamail" },
              { field: "customerNr", title: "Kundnummer" },
              { field: "orgnr", title: "Orgnr" },
              { field: "company", title: "Företag" },
              { field: "email", title: "Företagsmail" },
              { field: "personName", title: "Kontaktperson" },
              { field: "title", title: "Titel" },
              { field: "contactmail", title: "Kontaktmail" },
            ],
          });
        });
    },

    exportExcel() {
      saveExcel({
        data: this.orders.allOrders,
        fileName: "orderrader_" + this.selectedIssue + ".xlsx",
        columns: [
          {
            field: "dealID",
            title: "Ordernr",
          },
          {
            field: "orderDate",
            title: "Orderdatum",
          },
          {
            field: "company",
            title: "Företag",
          },
          {
            field: "orgnr",
            title: "Orgnr",
          },
          {
            field: "netto",
            title: "Netto",
          },
          {
            field: "quantity",
            title: "Antal",
          },
          {
            field: "productName",
            title: "Produkt",
          },
          {
            field: "price",
            title: "Listpris",
          },
          {
            field: "discount",
            title: "Rabatt",
          },
          {
            field: "discountProc",
            title: "Rabatt-%",
          },
          {
            field: "currencyCode",
            title: "Valuta",
          },
          {
            field: "rate",
            title: "Växelkurs",
          },
          {
            field: "realname",
            title: "Säljare",
          },
          {
            field: "region",
            title: "Region",
          },
          {
            field: "category",
            title: "Produktkategori",
          },
          {
            field: "startDate",
            title: "Startdatum",
          },
          {
            field: "issueName",
            title: "Utgåva",
          },
          {
            field: "publishingDate",
            title: "Publiceringsdag",
          },
        ],
      });
    },

    createInvoice(dealID, issueID) {
      this.loading = true;
      this.currentDeal = dealID;
      let year = moment(this.selectedDate).format("YYYY");
      this.$store
        .dispatch("order/createInvoice", { dealID: dealID, issueID: issueID })
        .then(() => {
          this.$store.dispatch("order/fetchAftersale", year).then(() => {
            this.loading = false;
          });
        });
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "SEK",
      });
    },

    yearSelected() {
      let year = moment(this.selectedDate).format("YYYY");
      this.loading = true;
      this.$store.dispatch("order/fetchAftersale", year).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: #ffaaaa;
}

.orders {
  th {
    padding: 1rem 0.75rem;
    cursor: pointer;

    &:hover {
      background: #ebedef;
    }
  }
}
</style>
