<template>
  <canvas ref="barChart" :width="width" :height="height"></canvas>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "BarChart",
  props: {
    width: {
      required: true,
      type: Number,
      default: 150,
      validator: (value) => value > 0,
    },
    height: {
      required: true,
      type: Number,
      default: 150,
      validator: (value) => value > 0,
    },
    labels: Array,
    datasets: {
      type: Array,
      required: true,
    },
    options: Object,
  },
  mounted() {
    new Chart(this.$refs.barChart, {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: this.options,
    });
  },
};
</script>

<style lang="scss" scoped>
canvas {
  height: 100%;
}
</style>
