import axios from "../../axios";

const state = {
  products: [],
  pricelist: [],
  productCategories: [],
};

const mutations = {
  FETCH_PRODUCTS(state, payload) {
    state.products = payload;
  },

  PRODUCT_CATEGORY(state, payload) {
    state.productCategories = payload;
  },

  PRICELIST(state, payload) {
    state.pricelist = payload;
  },
};

const actions = {
  // get.php?type=productCategories
  fetchProductCategory({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=productCategories", formData)
        .then((response) => {
          commit("PRODUCT_CATEGORY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchPricelistForDate({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("categoryID", data.categoryID);
      formData.append("regionID", data.regionID);
      formData.append("date", data.date);
      axios
        .post(rootState.baseUrl + "/get.php?type=pricelistForDate", formData)
        .then((response) => {
          commit("PRICELIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchPricelistForIssue({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("categoryID", data.categoryID);
      formData.append("regionID", data.regionID);
      formData.append("issueID", data.issueID);
      axios
        .post(rootState.baseUrl + "/get.php?type=pricelistForIssue", formData)
        .then((response) => {
          commit("PRICELIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php ? type = addProductCategory med POST- variabel category
  addProductCategory({ rootState, dispatch }, category) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("category", category);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addProductCategory", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchProductCategory");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setPrice({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("productID", data.productID);
      formData.append("price", data.price);
      formData.append("regionID", data.regionID);
      formData.append("year", data.year);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=setPrice", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeProduct({ rootState }, productID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("productID", productID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=removeProduct", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateProductCategory({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("categoryID", data.categoryID);
      formData.append("category", data.category);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(
          rootState.baseUrl + "/edit.php?type=updateProductCategory",
          formData
        )
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchProductCategory");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get.php?type=products
  fetchProducts({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=products", formData)
        .then((response) => {
          commit("FETCH_PRODUCTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addProduct med POST- variabler categoryID, productName, status "active"
  addProduct({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("categoryID", data.categoryID);
      formData.append("productName", data.productName);
      formData.append("status", data.status);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addProduct", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchProducts");
            resolve(response.data.data);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=updateProduct med POST - variabler categoryID, productName, productID och status "active" eller "inactive"
  updateProduct({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("categoryID", data.categoryID);
      formData.append("productName", data.productName);
      formData.append("status", data.status),
        formData.append("productID", data.productID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=updateProduct", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchProducts");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
