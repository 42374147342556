<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end">
      <li
        class="page-item"
        :class="pagination.current_page <= 1 ? 'disabled' : ''"
      >
        <a
          class="page-link"
          href="#"
          tabindex="-1"
          @click.prevent="changePage(pagination.current_page - 1)"
          aria-disabled="true"
        >
          <i class="fas fa-arrow-circle-left"></i> Previous
        </a>
      </li>
      <template v-for="(page, index) in pages" :key="index">
        <li
          class="page-item"
          :class="isCurrentPage(page) ? 'active' : ''"
          aria-current="page"
        >
          <a class="page-link" href="#" @click.prevent="changePage(page)">
            {{ page }}
          </a>
        </li>
      </template>
      <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li> -->
      <li
        class="page-item"
        :class="
          pagination.current_page >= pagination.last_page ? 'disabled' : ''
        "
      >
        <a
          class="page-link"
          href="#"
          tabindex="-1"
          @click.prevent="changePage(pagination.current_page + 1)"
          aria-disabled="true"
        >
          Next <i class="fas fa-arrow-circle-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["pagination", "offset"],
  name: "page-pagination",

  computed: {
    pages() {
      let pages = [];
      let from = this.pagination.current_page - Math.floor(this.offset / 2);

      if (from < 1) {
        from = 1;
      }

      let to = from + this.offset - 1;
      if (to > this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      while (from <= to) {
        pages.push(from);
        from++;
      }

      return pages;
    },
  },

  methods: {
    isCurrentPage(page) {
      return this.pagination.current_page === page;
    },

    changePage(page) {
      if (page > this.pagination.last_page) {
        page = this.pagination.last_page;
      }
      //   this.pagination.current_page = page;
      this.$emit("paginate", page);
    },
  },
};
</script>
