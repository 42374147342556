import axios from "../../axios";

const state = {
  customers: [],
  currentCustomer: {},
  activities: [],
  searchHistory: [],
};

const mutations = {
  FETCH_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  FETCH_CUSTOMER(state, payload) {
    state.currentCustomer = payload.customer;
    state.currentCustomer.contacts = payload.contacts;
    state.currentCustomer.agencies = payload.agencies;
    state.currentCustomer.notes = payload.notes;
    state.currentCustomer.activities = payload.activities;
    state.currentCustomer.deals = payload.deals;
  },
  FETCH_ACTIVITIES(state, payload) {
    state.activities = payload;
  },
  FETCH_HISTORY(state, payload) {
    state.searchHistory = payload;
  },
};

const actions = {
  fetchActivities({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("date", data.date);
      axios
        .post(rootState.baseUrl + "/get.php?type=activities", formData)
        .then((response) => {
          commit("FETCH_ACTIVITIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCustomers({ rootState, commit }, userID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("userID", userID);
      axios
        .post(rootState.baseUrl + "/get.php?type=customers", formData)
        .then((response) => {
          commit("FETCH_CUSTOMERS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchHistory({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=searchHistory", formData)
        .then((response) => {
          commit("FETCH_HISTORY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAssignee({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("userID", data.userID);
      formData.append("customerIDs", data.customerIDs);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=updateAssignee", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCustomer({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", data.customerID);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=deleteCustomer", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assignMe({ rootState }, customerID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", customerID);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=assignMe", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assign({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", data.customerID);
      formData.append("userID", data.userID);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=assign", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveComment({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", data.customerID);
      formData.append("comment", data.comment);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=updateCustomer", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveNote({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("customerID", data.customerID);
      formData.append("personID", data.personID ?? 0);
      formData.append("event", data.event);
      formData.append("eventType", data.eventType ?? "");
      formData.append("eventTime", data.eventTime ?? "");
      formData.append("place", data.place ?? "");
      formData.append("note", data.note ?? "");
      axios
        .post(rootState.baseUrl + "/edit.php?type=saveNote", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCustomer({ rootState, commit }, customerID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", customerID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=customer", formData)
        .then((response) => {
          commit("FETCH_CUSTOMER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
