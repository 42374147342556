<template>
  <div>
    <div class="d-flex justify-content-end py-2">
      <button
        type="button"
        class="btn btn-secondary float-end"
        @click="addAdvertise"
      >
        <i class="bi bi-plus-circle-fill"></i> Lägg in annonsprodukt
      </button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="border-bottom border-top">Produktnamn</th>
          <th class="border-bottom border-top">Kategori</th>
          <th class="border-bottom border-top">Pris</th>
          <th class="border-bottom border-top">Status</th>
          <th class="border-bottom border-top">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product, index) in products" :key="index">
          <td>{{ product.productName }}</td>
          <td>{{ getCategory(product.categoryID) }}</td>
          <td>{{ formatCurrency(findPrice(product.productID)) }}</td>
          <td>
            <span class="badge bg-success" v-if="product.status == 'active'"
              >Aktiv</span
            >
            <span class="badge bg-secondary" v-else>Inaktiv</span>
          </td>
          <td class="actions">
            <button
              type="button"
              class="btn btn-default"
              @click="editProduct(product)"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button
              type="button"
              class="btn btn-default"
              @click="removeProduct(product)"
            >
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <modal ref="refModal" :title="modalTitle" @hidden="clear()">
      <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
        <p class="m-0 fst-italic" v-for="(error, index) in errors" :key="index">
          {{ error }}
        </p>
      </div>
      <div class="mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Titel på annonsprodukt"
          v-model="productName"
        />
      </div>
      <div class="mb-3">
        <select class="form-select" v-model="category">
          <option :value="null">Välj produktkategori</option>
          <template v-if="productCategories.length > 0">
            <option
              :value="category"
              v-for="(category, index) in productCategories"
              :key="index"
            >
              {{ category.category }}
            </option>
          </template>
        </select>
      </div>
      <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="status"
            v-model="status"
          />
          <label class="form-check-label" for="status"> Aktivera </label>
        </div>
      </div>
      <div class="mb-3">
        <p>Pris för {{ region }} år {{ year }}</p>
      </div>
      <div class="mb-3">
        <input
          type="number"
          class="form-control"
          placeholder="Pris"
          v-model="price"
        />
      </div>
      <div class="d-grid gap-2 mb-3">
        <button
          type="button"
          class="btn btn-secondary"
          v-if="!editable"
          @click="saveAdvertising"
        >
          Spara
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          v-if="editable"
          @click="updateProduct()"
        >
          Uppdatera
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "product-table",
  props: [
    "products",
    "pricelist",
    "productCategories",
    "region",
    "year",
    "regionID",
  ],
  data() {
    return {
      errors: [],
      modalTitle: "Lägg in annonsprodukt",
      showModal: false,
      editable: false,
      category: null,
      status: true,
      price: null,
      productName: null,
    };
  },
  components: { Modal },
  methods: {
    findPrice(productID) {
      let product = this.pricelist.find(
        (product) => product.productID == productID
      );
      return product && product.price ? product.price : "";
    },
    getCategory(categoryID) {
      for (let i = 0; i < this.productCategories.length; i++) {
        if (this.productCategories[i].categoryID == categoryID) {
          return this.productCategories[i].category;
        }
      }
      return "";
    },
    formatCurrency(price) {
      let decimaler = 0;
      if (price < 100) {
        decimaler = 2;
      }
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: decimaler,
        maximumFractionDigits: decimaler,
        currency: "SEK",
      });
    },
    removeProduct(product) {
      this.$store
        .dispatch("product/removeProduct", product.productID)
        .then(() => {
          this.$emit("updatePrices");
          this.$emit("updateFilters");
        });
    },
    editProduct(product) {
      this.modalTitle = "Redigera produkt";
      this.productName = product.productName;
      this.category = this.productCategories.find(
        (category) => category.categoryID === product.categoryID
      );
      this.status = product.status == "active" ? true : false;
      this.product = product;
      this.price = this.findPrice(product.productID);
      this.editable = true;
      this.$refs.refModal.open();
    },
    async saveAdvertising() {
      this.errors = [];
      if (!this.checkErrors()) {
        let productStatus = this.status ? "active" : "inactive";

        let response = await this.$store.dispatch("product/addProduct", {
          productName: this.productName,
          categoryID: parseInt(this.category.categoryID),
          status: productStatus,
        });

        if (response) {
          if (this.price != null && this.price > 0) {
            await this.$store.dispatch("product/setPrice", {
              productID: response,
              regionID: this.regionID,
              year: this.year,
              price: this.price,
            });
            this.$emit("updatePrices");
          }
          this.$emit("updateFilters");
          this.$refs.refModal.close();
          this.productName = null;
          this.category = null;
          this.status = true;
        }
      }
      this.price = null;
    },

    async updateProduct() {
      this.errors = [];
      if (!this.checkErrors()) {
        let productStatus = this.status ? "active" : "inactive";
        let response = await this.$store.dispatch("product/updateProduct", {
          productID: this.product.productID,
          productName: this.productName,
          categoryID: this.category.categoryID,
          status: productStatus,
        });

        if (response) {
          console.log(this.price);
          if (this.price != null && this.price > 0) {
            await this.$store.dispatch("product/setPrice", {
              productID: this.product.productID,
              regionID: this.regionID,
              year: this.year,
              price: this.price,
            });
            this.$emit("updatePrices");
          }
          this.$refs.refModal.close();
          this.$store.dispatch("product/fetchProducts").then(() => {
            this.$emit("updateFilters");
          });
        }
      }
      this.price = null;
    },

    checkErrors() {
      let hasError = false;
      if (!this.productName) {
        hasError = true;
        this.errors.push("Du måste ange produkt namn");
      }
      if (!this.category) {
        hasError = true;
        this.errors.push("Välj produktkategori");
      }
      return hasError;
    },

    clear() {
      this.modalTitle = "Lägg in annonsprodukt";
      this.errors = [];
      this.productName = null;
      this.category = null;
      this.status = true;
      this.editable = false;
    },
    addAdvertise() {
      this.showModal = true;
      this.$refs.refModal.open();
    },
  },
};
</script>
