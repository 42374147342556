<template>
  <nav id="sidebar" class="sidebar d-flex flex-column flex-shrink-0 bg-light">
    <div class="side-header">
      <router-link to="/">
        <img class="nav-logo" src="@/assets/logo.svg" alt width="25" />
      </router-link>
      <h4>Mogul 1.5</h4>
    </div>
    <sidebar-header :realname="realname" />
    <sidebar-status />
    <ul class="nav nav-pills flex-column mb-auto bg-light">
      <li
        class="nav-item"
        :class="{ active: menu.name === currentRoute }"
        v-for="(menu, index) in menus"
        :key="index"
      >
        <div
          v-if="
            menu.accessLevel == null ||
            (accessLevel == 'admin' && menu.accessLevel != 'externmanager') ||
            menu.accessLevel == accessLevel
          "
        >
          <router-link
            :to="{ path: menu.path }"
            class="nav-link link-dark"
            v-if="menu.path"
            @click="toggleButton(menu)"
          >
            <i class="bi" :class="menu.icon"></i
            ><span class="menu-text">{{ menu.textContent }}</span>
          </router-link>
          <button
            class="btn btn-default"
            v-else
            @click="toggleButton(menu)"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i class="bi" :class="menu.icon"></i>
            <span class="menu-text">{{ menu.textContent }}</span>
            <i
              v-if="menu.children && menu.children.length > 0"
              class="bi bi-caret-down-fill float-end"
            ></i>
          </button>
          <div
            class="collapse"
            :class="{ active: menu.name === currentRoute }"
            v-if="
              menu.children &&
              menu.children.length > 0 &&
              menu.name === currentRoute
            "
          >
            <ul class="btn-toggle-nav list-unstyled">
              <li v-for="(child, key) in menu.children" :key="key">
                <router-link :to="{ path: child.path }" class="link-dark">{{
                  child.name
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import SidebarHeader from "./SidebarHeader.vue";
import SidebarStatus from "./SidebarStatus.vue";
import Menus from "@/assets/menus.json";
import { mapState } from "vuex";
export default {
  name: "sidebar-menu",
  data() {
    return {
      menus: Menus,
    };
  },

  computed: {
    ...mapState({
      realname: (state) => state.auth.realname,
      accessLevel: (state) => state.auth.accessLevel,
      currentRoute: (state) => state.sidebar.currentRoute,
    }),
  },

  components: {
    SidebarStatus,
    SidebarHeader,
  },

  methods: {
    toggleButton(menu) {
      if (menu.name === "Settings" || menu.name == "Aftersales") {
        menu.collapsed = !menu.collapsed;
      } else if (menu.name === "Logout") {
        this.$swal({
          title: "Vill du verkligen logga ut?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ja, logga ut!",
          cancelButtonText: "Avbryt",
        }).then((result) => {
          if (result.isConfirmed) {
            // Call http request to delete
            this.logout();
          }
        });
      }
      this.$store.commit("sidebar/SET_ROUTE_NAME", menu.name);
    },

    async logout() {
      await this.$store.dispatch("auth/authLogout");
      this.$store.commit("auth/AUTH_LOGOUT");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  left: 250px;
  width: 0;
  margin-left: -250px;
  padding-left: 0;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  .side-header {
    a {
      display: inline-block;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-right: 1rem;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
    }
    h4 {
      padding: 0;
      font-size: 28px;
      text-transform: none;
      letter-spacing: 1px;
      font-weight: 800;
    }
  }

  ul {
    li {
      width: 100% !important;
      .collapse {
        display: none;
      }
      .collapse.active {
        display: block;
      }
      button {
        width: 100% !important;
        text-align: left;
      }
      a {
        padding: 10px 10px 10px 30px;
        font-size: 1.1em;
        display: block;
        position: relative;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 0.5px;
        .bi {
          width: 1.25em;
          margin-right: 0.8em;
          text-align: center;
          font-size: 1.125rem;
          color: #222222;
        }
        .menu-text {
          font-size: 15px;
          color: #222222;
          letter-spacing: 1px;
        }
      }
      &:hover {
        background: #f1f1f1;
        a {
          color: #3498db;
        }
      }
      i {
        margin-right: 15px;
      }
      button {
        padding-left: 30px;
      }
      button:focus {
        box-shadow: none;
      }
      ul {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        li {
          padding: 0.3rem 2rem;
          list-style: none;
          a {
            font-size: 16px;
            text-decoration: none;
            &:hover {
              color: #464646;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
