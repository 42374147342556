<template>
  <div class="user-settings">
    <p class="setting-menu">Budget och försäljning:</p>
    <p class="setting-menu">
      <i class="bi bi-book"></i>
      {{ budgetPrint.toLocaleString() }} kr<br />Försäljning:
      {{ salesPrint.toLocaleString() }} kr
    </p>
    <p class="setting-menu">
      <i class="bi bi-display"></i>
      {{ budgetDigital.toLocaleString() }} kr<br />Försäljning:
      {{ salesDigital.toLocaleString() }} kr
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "side-bar",
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  data() {
    return {
      budgetPrint: 0,
      budgetDigital: 0,
      salesPrint: 0,
      salesDigital: 0,
      message: null,
      timeout: null,
    };
  },

  mounted() {
    // this.fetchBudget();
    this.fetchCurrentSales();
  },

  beforeUnmount() {
    clearTimeout(this.timeout);
  },

  methods: {
    fetchBudget() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/get.php?type=sideBudget", formData)
        .then((response) => {
          this.budgetPrint = response.data.budget[0].total ?? 0;
          this.budgetDigital = response.data.budget[1].total ?? 0;
        });
      this.$http
        .post(this.baseUrl + "/get.php?type=sideSales", formData)
        .then((response) => {
          this.salesPrint = response.data.data.print[0].total ?? 0;
          this.salesDigital = response.data.data.digital[0].total ?? 0;
        });
    },

    fetchCurrentSales() {
      this.$http
        .get(this.baseUrl + "/get.php?type=currentSales&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.budgetPrint = response.data.data.budget[0].total ?? 0;
            this.budgetDigital = response.data.data.budget[1].total ?? 0;
            this.salesPrint = response.data.data.print[0].total ?? 0;
            this.salesDigital = response.data.data.digital[0].total ?? 0;
            this.message = response.data.data.msg ?? null;
            if (this.message) {
              this.$notify({
                group: "app",
                type: "success",
                title: "Ny order",
                text: this.message,
                duration: 10000,
              });
            }
            this.timeout = setTimeout(this.fetchCurrentSales, 60000);
          } else {
            this.timeout = setTimeout(this.fetchCurrentSales, 60000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-settings {
  background-color: #464646;
  color: #e2e2e2;
  padding: 10px;
  text-align: center;
}
</style>
