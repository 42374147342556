import axios from "../../axios";

const state = {
  contacts: [],
  calendar: [],
};

const mutations = {
  FETCH_CONTACT(state, payload) {
    state.contacts = payload;
  },
  FETCH_CALENDAR(state, payload) {
    state.calendar = payload;
  },
};

const actions = {
  // type=contacts, parameter: customerID
  fetchContact({ rootState, commit }, customerID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", customerID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=contacts", formData)
        .then((response) => {
          commit("FETCH_CONTACT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchCalendar({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("year", data.date.getFullYear());
      formData.append("month", data.date.getMonth() + 1);
      formData.append("userID", data.userID ?? 0);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=calendar", formData)
        .then((response) => {
          commit("FETCH_CALENDAR", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllContacts({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=allContacts", formData)
        .then((response) => {
          commit("FETCH_CONTACT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  moveActivity({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("eventID", data.eventID);
      formData.append("date", data.date);
      formData.append("time", data.time);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=moveActivity", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  finishActivity({ rootState }, eventID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("eventID", eventID);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=finishActivity", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveContactPerson({ rootState }, contact) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", contact.customerID);
      formData.append("personName", contact.personName);
      formData.append("title", contact.title);
      formData.append("phone", contact.phone);
      formData.append("mobile", contact.mobile);
      formData.append("email", contact.email);
      formData.append("comment", contact.comment);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=addContactperson", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  hideContact({ rootState }, personID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("personID", personID);

      axios
        .post(rootState.baseUrl + "/edit.php?type=hideContact", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateContactPerson({ rootState }, contact) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", contact.customerID);
      formData.append("personName", contact.personName);
      formData.append("title", contact.title);
      formData.append("phone", contact.phone);
      formData.append("mobile", contact.mobile);
      formData.append("email", contact.email);
      formData.append("personID", contact.personID);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(
          rootState.baseUrl + "/edit.php?type=updateContactperson",
          formData
        )
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  connectCustomerAgency({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("agencyID", data.agencyID);
      formData.append("customerID", data.customerID);
      formData.append("personID", data.personID);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=addCustomerAgency", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            throw false;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
