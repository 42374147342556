<template>
  <main>
    <div class="issues w-100">
      <navbar title="Utgåvor" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="fw-light fst-italic">
                <span v-if="editable">Redigera utgåva </span
                ><span v-else> Lägg till ny utgåva av {{ magazineName }}</span>
              </h3>
            </div>
            <div class="row">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-bottom border-top">Materialdag</th>
                    <th class="border-bottom border-top">Utgivningsdag</th>
                    <th class="border-bottom border-top">Budget</th>
                    <th class="border-bottom border-top">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(issueForm, index) in issueForms"
                    :key="index"
                    :class="{ error: issueForm.hasError }"
                  >
                    <td>
                      <datepicker
                        class="form-control"
                        placeholder="Välj materialdag"
                        :locale="local"
                        v-model="issueForm.deadline"
                      />
                    </td>
                    <td>
                      <datepicker
                        class="form-control"
                        placeholder="Välj utgivningsdag"
                        :locale="local"
                        v-model="issueForm.publishingDate"
                      />
                    </td>
                    <td>
                      <div class="d-flex justify-content-start">
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Ange budget"
                          v-model="issueForm.budget"
                          @keyup="parseIssue()"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="d-grid gap-2 d-md-flex justify-content-md-start"
                      >
                        <button
                          class="btn btn-success me-md-2 fw-bold"
                          type="button"
                          @click="saveIssues()"
                        >
                          {{ editable ? "Uppdatera" : "Spara" }}
                        </button>
                        <button
                          class="btn btn-secondary fw-bold"
                          type="button"
                          v-if="editable"
                          @click="cancelEditing()"
                        >
                          Avbryt
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errorMessage !== null"
              >
                <p class="m-0">{{ errorMessage }}</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="fw-light fst-italic">
                Utgåvor av {{ magazineName }}
                <select v-if="years.length > 0" v-model="selectedYear">
                  <option v-for="(year, index) in years" :key="'year' + index">
                    {{ year }}
                  </option>
                </select>
              </h3>
              <!-- <button class="btn btn-default">
                <i class="bi bi-plus-circle" style="font-size: 1.5rem"></i>
              </button> -->
            </div>
            <div class="row">
              <div class="col-lg-12">
                <table
                  class="table table-striped"
                  v-if="selectedIssues.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="border-bottom border-top">Utgåva</th>
                      <th class="border-bottom border-top">Materialdag</th>
                      <th class="border-bottom border-top">Utgivningsdag</th>
                      <th class="border-bottom border-top">Budget</th>
                      <th class="border-bottom border-top">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(issue, index) in selectedIssues"
                      :key="index"
                      :class="{ editable: issue.editable }"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ issue.deadline }}</td>
                      <td>{{ issue.publishingDate }}</td>
                      <td>
                        {{
                          issue.budget.toLocaleString("sv-SE", {
                            style: "currency",
                            currency: "SEK",
                            maximumFractionDigits: 0,
                          })
                        }}
                      </td>
                      <td class="actions">
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="deleteIssue(issue.issueID)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="editIssue(issue)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" align="right">Summa:</td>
                      <td>
                        {{
                          total.toLocaleString("sv-SE", {
                            style: "currency",
                            currency: "SEK",
                            maximumFractionDigits: 0,
                          })
                        }}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info mt-4" v-else>
                  <p class="m-0 fw-light">Ingen utgåvor hittades</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { ref } from "vue";
import { sv } from "date-fns/locale";

export default {
  name: "admin-issue",
  data() {
    return {
      magazineID: null,
      issues: [],
      issueID: null,
      issue: null,
      publishingDate: null,
      magazineName: null,
      deadline: null,
      budget: null,
      errors: [],
      years: [],
      selectedYear: null,
      editable: false,
      issueForms: [
        {
          issue: 1,
          deadline: ref(new Date()),
          publishingDate: ref(new Date()),
          budget: 0,
          hasError: false,
        },
      ],
      style: {
        maxWidth: "50px",
      },
      local: sv,
      lowerLimit: ref(new Date()),
      errorMessage: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
    selectedIssues() {
      return this.issues.filter((issue) => {
        return issue.publishingDate.substring(0, 4) == this.selectedYear;
      });
    },
    total() {
      let sum = 0;
      for (let i = 0; i < this.selectedIssues.length; i++) {
        sum += this.selectedIssues[i].budget;
      }
      return sum;
    },
  },

  components: { Navbar, Datepicker },

  mounted() {
    document.title = "Utgåvor";
    let params = this.$route.params;
    this.magazineID = params.magazineID ?? null;
    this.fetchIssues();
  },

  methods: {
    fetchIssues() {
      let formData = new FormData();
      formData.append("magazineID", this.magazineID);
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/get.php?type=issues", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            this.issues = response.data.data;
            this.magazineName = response.data.data[0].magazineName;
            let lastYear = 0;
            for (let i = 0; i < this.issues.length; i++) {
              if (lastYear != this.issues[i].publishingDate.substring(0, 4)) {
                lastYear = this.issues[i].publishingDate.substring(0, 4);
                this.years.push(lastYear);
              }
            }
            this.selectedYear =
              this.$route.params.selectedYear ?? this.years[0];
            document.title = "Utgåvor av " + this.magazineName;
          }
        });
    },

    parseIssue() {
      let sum = 0;
      this.issueForms.map((issue, key) => {
        let budget =
          parseFloat(issue.budget) > 0 ? parseFloat(issue.budget) : 0;
        issue.issue = key + 1;
        sum += budget;
      });
      this.total = sum;
    },

    saveIssues() {
      this.errorMessage = null;
      if (!this.validateForm()) {
        this.issueForms.map((issue, index) => {
          let url = null;
          let deadline = moment(issue.deadline).format("YYYY-MM-DD");
          let publishingDate = moment(issue.publishingDate).format(
            "YYYY-MM-DD"
          );
          let formData = new FormData();
          formData.append("publishingDate", publishingDate);
          formData.append("deadline", deadline);
          formData.append("budget", issue.budget);
          formData.append("magazineID", this.magazineID);
          formData.append("key", this.apiKey);
          if (this.editable && this.issueID) {
            formData.append("issueID", this.issueID);
            url = "/edit.php?type=updateIssue";
          } else {
            url = "/edit.php?type=addIssue";
          }
          this.$http.post(this.baseUrl + url, formData).then(() => {
            if (index === this.issueForms.length - 1) {
              this.fetchIssues();
              this.issueForms = [];
              this.issueForms.push({
                issue: 1,
                deadline: ref(new Date()),
                publishingDate: ref(new Date()),
                budget: 0,
                hasError: false,
              });
              this.total = 0;
              this.errorMessage = null;
              this.editable = false;
            }
          });
        });
      } else {
        this.errorMessage = "Alla fält är obligatoriska";
      }
    },

    validateForm() {
      let errorFound = false;
      this.issueForms.map((issue) => {
        if (
          !issue.budget ||
          issue.budget <= 0 ||
          !issue.deadline ||
          !issue.publishingDate
        ) {
          errorFound = true;
          issue.hasError = true;
        } else {
          issue.hasError = false;
        }
      });
      return errorFound;
    },

    editIssue(selectedIssue) {
      this.editable = true;
      this.issueForms = [];
      this.issueForms.push({
        deadline: ref(new Date(selectedIssue.deadline)),
        publishingDate: ref(new Date(selectedIssue.publishingDate)),
        budget: selectedIssue.budget,
        hasError: false,
      });
      this.total = selectedIssue.budget;
      this.errorMessage = null;
      this.issueID = selectedIssue.issueID;

      this.issues.map((issue) =>
        issue.issueID == selectedIssue.issueID
          ? (issue.editable = true)
          : (issue.editable = false)
      );
    },

    cancelEditing() {
      this.editable = false;
      this.issueForms = [];
      this.issueForms.push({
        issue: 1,
        deadline: ref(new Date()),
        publishingDate: ref(new Date()),
        budget: 0,
        hasError: false,
      });
      this.total = 0;
      this.errorMessage = null;

      this.issues.map((issue) => (issue.editable = false));
    },

    updateIssue() {},

    deleteIssue(issueID) {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      formData.append("issueID", issueID);
      this.$http
        .post(this.baseUrl + "/edit.php?type=deleteIssue", formData)
        .then(() => {
          this.fetchIssues();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.issues {
  .table {
    .error {
      background: #ffddda;
    }
    .editable {
      background: #ffeebc;
    }
  }
}
</style>
