<template>
  <main>
    <div class="bo w-100">
      <navbar title="Affärsmöjlighetsfaser" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <p>
              Dessa representerar de olika stadierna en affärsmöjlighet går
              igenom innan affären stängs. Tips, bestäm krav för varje fas så
              att ...
            </p>
            <br />
            <div class="row col-lg-12">
              <div class="col-md-4">
                <form>
                  <input type="text" class="form-control" placeholder="Fas" />
                </form>
              </div>
              <div class="col-md-4">
                <form>
                  <input type="text" class="form-control" placeholder="%" />
                </form>
              </div>

              <div class="col-md-4">
                <button
                  type="submit"
                  class="btn btn-primary float-right btn-blue"
                >
                  <i class="bi bi-plus"></i> Lägg till
                </button>
              </div>
            </div>
            <br />
            <div class="row col-lg-12">
              <h5>Alla affärsmöjlighetsfaser</h5>
            </div>

            <br />

            <div class="col-lg-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Fas</th>
                    <th scope="col">Sannolikhet</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Återbearbetas</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 5%"
                          aria-valuenow="5"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          5%
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Affärsmöjlighet</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 50%"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          50%
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Offert</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="width: 79%"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          70%
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  components: { Navbar },
  name: "BusinessOpportunity",
};
</script>

<style></style>
