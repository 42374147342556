<template>
  <div class="contact-form">
    <alerts :errors="errors" />
    <div class="row g-3 border rounded mx-2 my-2 bg-light px-2">
      <div class="col-md-4">
        <label for="name" class="form-label">Namn</label>
        <input
          id="name"
          type="text"
          class="form-control"
          v-model="contact.personName"
        />
      </div>
      <div class="col-md-4">
        <label for="title" class="form-label">Titel</label>
        <input
          id="title"
          type="text"
          class="form-control"
          v-model="contact.title"
        />
      </div>
      <div class="col-md-4">
        <label for="email" class="form-label">E-post</label>
        <input
          id="email"
          type="email"
          class="form-control"
          v-model="contact.email"
        />
      </div>
      <div class="col-md-4">
        <label for="mobile" class="form-label">Mobilnummer</label>
        <input
          id="mobile"
          type="text"
          class="form-control"
          placeholder="070xxx eller +4670xxx"
          v-model="contact.mobile"
        />
      </div>
      <div class="col-md-4">
        <label for="phone" class="form-label">Telefonnummer</label>
        <input
          id="phone"
          type="text"
          class="form-control"
          v-model="contact.phone"
        />
      </div>
      <div class="col-md-4">
        <label for="hide" class="form-label">Radera kontakt</label>
        <br />
        <button class="btn" type="button" @click="hide()">
          <i class="bi bi-trash"></i>
        </button>
      </div>
      <div class="d-grid gap-2 mb-3">
        <div class="row">
          <div class="col-md-6">
            <button
              class="btn btn-secondary fw-bold"
              type="button"
              @click="submit()"
            >
              Spara
            </button>
            <button
              style="margin-left: 8px"
              class="btn btn-secondary"
              type="button"
              @click="clear"
              v-if="contact.personName != null"
            >
              Rensa
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alerts from "@/components/Alerts.vue";
export default {
  name: "ContactForm",
  props: {
    editContact: { type: Object, required: false },
  },
  data() {
    return {
      contact: {
        personName: null,
        title: null,
        email: null,
        mobile: null,
        phone: null,
        comment: null,
      },
      errors: [],
    };
  },

  components: { Alerts },

  mounted() {
    this.contact = this.editContact;
  },

  methods: {
    submit() {
      this.errors = [];
      if (!this.validateForm()) {
        this.$emit("contact", this.contact);
      }
    },

    hide() {
      this.$emit("hideContact", this.contact.personID);
      this.clear();
    },

    clear() {
      this.contact = {
        personName: null,
        title: null,
        email: null,
        mobile: null,
        phone: null,
        comment: null,
      };
    },

    validateForm() {
      let hasError = false;
      if (!this.contact.personName) {
        hasError = true;
        this.errors.push("Ange personens namn");
      }
      return hasError;
    },
  },
};
</script>
