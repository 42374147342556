<template>
  <notifications
    group="app"
    position="bottom left"
    classes="vue-notification"
  />
  <sidebar v-if="isAuthenticated && !fullscreen" />
  <router-view :style="[isAuthenticated && !fullscreen ? { paddingLeft: '250px' } : {}]" />
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      isAuthenticated: (state) => state.auth.isAuthenticated,
      apiKey: (state) => state.auth.apiKey,
      fullscreen: (state) => state.sidebar.fullscreen,
    }),
  },

  components: {
    Sidebar,
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./assets/style.scss";
@import "./assets/table.scss";

.vue-notification-group {
  min-width: 400px;
  margin: 0 10px 25px;
}

.vue-notification {
  padding: 17px;

  .notification-title {
    font-size: 17px;
  }
  .notification-content {
    font-size: 14px;
  }

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}
</style>
