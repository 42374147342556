const state = {
  currentRoute: null,
  fullscreen: false,
};

const mutations = {
  SET_ROUTE_NAME(state, payload) {
    state.currentRoute = payload;
  },

  SET_FULLSCREEN(state, payload) {
    state.fullscreen = payload;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
