<template>
  <main>
    <div class="p-categroy w-100 h-100">
      <navbar title="Produktkategori" />
      <div class="content p-3">
        <div class="row">
          <div class="col-lg-5">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">
                  Lägg till produktkategori
                </h5>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="errorMessage"
                >
                  {{ errorMessage }}
                </div>
                <div class="mb-3">
                  <label class="form-label">Produktkategori</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Ange kategori namn"
                    v-model="productCategory"
                  />
                </div>
                <div class="d-grid gap-3">
                  <button
                    type="button"
                    class="btn btn-success fw-bold"
                    v-if="!editable"
                    @click="addProductCategory()"
                  >
                    Spara
                  </button>
                  <button
                    class="btn btn-success fw-bold"
                    type="button"
                    v-if="editable"
                    @click="updateProductCategory()"
                  >
                    Uppdatera
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary fw-bold"
                    v-if="editable"
                    @click="close"
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">Produktkategori</h5>
                <table
                  class="table table-striped"
                  v-if="productCategories.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="border-bottom border-top"></th>
                      <th class="border-bottom border-top">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(category, index) in productCategories"
                      :key="index"
                    >
                      <td>{{ category.category }}</td>
                      <td class="actions">
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="deleteProductCategory(category)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="editProductCategory(category)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-primary" role="alert" v-else>
                  <p class="m-0">Ingen produktkategori hittades</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
export default {
  name: "ProductCategory",
  data() {
    return {
      productCategoryID: null,
      productCategory: null,
      editable: false,
      errorMessage: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",

      apiKey: (state) => state.auth.apiKey,
      productCategories: (state) => state.product.productCategories,
    }),
  },

  components: { Navbar },

  mounted() {
    this.checkRouteName();

    document.title = "Produktkategorier";
    this.$store.dispatch("product/fetchProductCategory");
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    async addProductCategory() {
      this.errorMessage = null;
      if (!this.productCategory) {
        this.errorMessage = "Du måste ange kategorinamn.";
      } else {
        let res = await this.$store.dispatch(
          "product/addProductCategory",
          this.productCategory
        );
        if (res) {
          this.productCategory = null;
        }
      }
    },

    editProductCategory(category) {
      this.productCategoryID = category.categoryID;
      this.productCategory = category.category;
      this.editable = true;
    },

    deleteProductCategory(category) {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      formData.append("categoryID", category.categoryID);
      this.$http
        .post(this.baseUrl + "/edit.php?type=deleteProductCategory", formData)
        .then(() => {
          this.$store.dispatch("product/fetchProductCategory");
        });
    },

    async updateProductCategory() {
      this.errorMessage = null;
      if (!this.productCategory) {
        this.errorMessage = "Du måste ange kategori namn.";
      } else {
        let res = await this.$store.dispatch("product/updateProductCategory", {
          categoryID: this.productCategoryID,
          category: this.productCategory,
        });
        if (res) {
          this.productCategoryID = null;
          this.productCategory = null;
          this.editable = false;
        }
      }
    },

    close() {
      this.productCategoryID = null;
      this.productCategory = null;
      this.editable = false;
    },
  },
};
</script>
