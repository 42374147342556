<template>
  <main>
    <div class="profile w-100 h-100">
      <navbar title="Min profil" />
      <div class="content p-3">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-content">
                <div class="mb-3">
                  <label for="name" class="form-label">Namn</label>
                  <input type="text" class="form-control" id="name" placeholder="För- och efternamn" v-model="name"
                    :class="{
                      'is-invalid': submitStatus == 'ERROR' && v$.name.$error,
                    }" />
                  <div class="invalid-feedback" v-if="v$.name.$error">
                    Ange namn
                  </div>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">E-postadress</label>
                  <input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="email"
                    :class="{
                      'is-invalid': submitStatus == 'ERROR' && v$.email.$error,
                    }" />
                  <div class="invalid-feedback" v-if="v$.email.$error">
                    Ange e-postadress
                  </div>
                  <div class="invalid-feedback" v-if="v$.email.email">
                    Ange giltig e-postadress
                  </div>
                </div>
                <div class="mb-3">
                  <div class="row">
                    <div class="col">
                      <label for="phone" class="form-label">Telefon</label>
                      <input type="text" class="form-control" id="phone" placeholder="Telefon" v-model="phone" />
                    </div>
                    <div class="col">
                      <label for="mobile" class="form-label">Mobil</label>
                      <input type="text" class="form-control" id="mobile" placeholder="Telefon" v-model="mobile" :class="{
                        'is-invalid':
                          submitStatus == 'ERROR' && v$.mobile.$error,
                      }" />
                      <div class="invalid-feedback" v-if="v$.mobile.$error">
                        Ange mobilnummer
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="address" class="form-label">Adress</label>
                  <input type="text" class="form-control" id="address" placeholder="Adress" v-model="address" :class="{
                    'is-invalid':
                      submitStatus == 'ERROR' && v$.address.$error,
                  }" />
                  <div class="invalid-feedback" v-if="v$.address.$error">
                    Ange adress
                  </div>
                </div>
                <div class="mb-3">
                  <div class="row">
                    <div class="col">
                      <label for="postcode" class="form-label">Postnummer</label>
                      <input type="text" class="form-control" id="postcode" placeholder="Postnummer" v-model="postcode"
                        :class="{
                          'is-invalid':
                            submitStatus == 'ERROR' && v$.postcode.$error,
                        }" />
                      <div class="invalid-feedback" v-if="v$.postcode.$error">
                        Ange postnummer
                      </div>
                    </div>
                    <div class="col">
                      <label for="city" class="form-label">Ort</label>
                      <input type="text" class="form-control" id="city" placeholder="Ort" v-model="city" :class="{
                        'is-invalid':
                          submitStatus == 'ERROR' && v$.city.$error,
                      }" />
                      <div class="invalid-feedback" v-if="v$.city.$error">
                        Ange ort
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="formFile" class="form-label">Ladda upp bild</label>
                  <input class="form-control" type="file" id="file" @change="onFileChange" required />
                </div>
                <div class="d-grid gap-2">
                  <button class="btn btn-secondary" type="button" @click="updateProfile()">
                    Uppdatera profil
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card">
              <div class="card-content">
                <div class="mb-3">
                  <label for="password" class="form-label">Nuvarande lösenord</label>
                  <input type="password" class="form-control" id="password" placeholder="Nytt lösenord"
                    v-model="password" :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' && v$.password.$error,
                    }" />
                  <div class="invalid-feedback" v-if="v$.password.$error">
                    Ange lösenord
                  </div>
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Nytt lösenord</label>
                  <input type="password" class="form-control" id="newpassword" placeholder="Nytt lösenord"
                    v-model="newpassword" :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' && v$.password.$error,
                    }" />
                  <div class="invalid-feedback" v-if="v$.password.$error">
                    Ange lösenord
                  </div>
                </div>
                <div class="mb-3">
                  <label for="confirm-password" class="form-label">Bekräfta lösenord</label>
                  <input type="password" class="form-control" id="confirm-password" placeholder="Bekräfta lösenord"
                    v-model="confirmPassword" :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' && v$.confirmPassword.$error,
                    }" />
                  <div class="invalid-feedback" v-if="v$.confirmPassword.$error">
                    Bekräfta lösenord
                  </div>
                </div>
                <div class="d-grid gap-2">
                  <button class="btn btn-secondary" type="button" @click="updatePassword()">
                    Uppdatera lösenord
                  </button>
                  <p>{{ passwordError }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import useVuelidate from "@vuelidate/core";
import { mapState } from "vuex";
import { required, email, requiredIf, sameAs } from "@vuelidate/validators";
export default {
  name: "UserProfile",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      mobile: null,
      address: null,
      postcode: null,
      city: null,
      file: null,
      passwordError: null,
      password: null,
      newpassword: null,
      confirmPassword: null,
      extensions: ["png", "jpg", "jpeg"],
      errorMessage: null,
      submitStatus: null,
      updateStatus: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      loggedInAccessLevel: (state) => state.auth.accessLevel,
    }),
  },

  mounted() {
    this.fetchUser();
  },

  components: {
    Navbar,
  },

  validations() {
    return {
      name: { required },
      email: { required, email },
      mobile: { required },
      address: { required },
      postcode: { required },
      city: { required },
      password: {
        required: requiredIf(function () {
          return this.updateStatus === "password";
        }),
      },
      newpassword: {
        required: requiredIf(function () {
          return this.updateStatus === "password";
        }),
      },
      confirmPassword: {
        required: requiredIf(function () {
          return this.updateStatus === "password";
        }),
        sameAsPassword: sameAs(this.newpassword),
      },
    };
  },

  methods: {
    fetchUser() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/get.php?type=user", formData)
        .then((response) => {
          this.name = response.data.data.realname;
          this.email = response.data.data.email;
          this.mobile = response.data.data.mobile;
          this.address = response.data.data.streetAddress;
          this.postcode = response.data.data.zipcode;
          this.city = response.data.data.city;
          this.phone = response.data.data.telephone;
        });
    },

    updateProfile() {
      this.updateStatus = "profile";
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "SUCCESS";
        var formData = new FormData();
        formData.append("realname", this.name),
          formData.append("email", this.email),
          formData.append("telephone", this.phone),
          formData.append("mobile", this.mobile),
          formData.append("streetAddress", this.address),
          formData.append("zipcode", this.postcode),
          formData.append("city", this.city),
          formData.append("file", this.file);
        this.$http.post(this.baseUrl + "/edit.php?type=updateUserinfo&key=" + this.apiKey, formData, {
          Headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(() => {
          this.fetchUser();
        })
      }
    },

    updatePassword() {
      this.updateStatus = "password";
      this.v$.password.$touch();
      this.v$.confirmPassword.$touch();
      if (this.v$.password.$invalid || this.v$.confirmPassword.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var formData = new FormData();
        formData.append("key", this.apiKey);
        formData.append("oldPassword", this.password);
        formData.append("newPassword", this.newpassword);
        formData.append("newPassword2", this.confirmPassword);
        this.$http.post(this.baseUrl + "/edit.php?type=changePassword", formData).then((response) => {
          if (response.data.status == 'ok') {
            this.submitStatus = "SUCCESS";
            this.password = null;
            this.confirmPassword = null;
            this.newpassword = null;
          } else {
            this.submitStatus = "ERROR";
            this.passwordError = response.data.data;
          }
        });
      }
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      if (this.isImageValidity(files[0])) {
        this.file = files[0];
      } else {
        this.errorMessage = "En eller några filer är inte tillåtna att välja";
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[name.length - 1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .card {
    padding: 35px;
  }
}
</style>
