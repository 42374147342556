<template>
  <main>
    <div class="company h-100 w-100">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card pb-3">
          <div class="card-body">
            <div class="headers">
              <div class="row py-3">
                <div class="col-md-5">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="Sök företag"
                      aria-label="Sök företag"
                      v-model="searchKey"
                      @keyup="searchCustomers"
                    />
                    <select
                      id="historySelect"
                      v-model="searchHistory"
                      @change="navigateToCustomer()"
                      class="form-select form-select-lg shadow-none bg-light"
                    >
                      <option value="0">Historik</option>
                      <option
                        v-for="(r, index) in history"
                        :key="index"
                        :value="r.customerID"
                      >
                        {{ r.company }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <select
                    v-model="userID"
                    class="form-select form-select-lg"
                    @change="navigateToUser()"
                  >
                    <option value="0">Alla säljare</option>
                    <option value="-1">Ingen tilldelad säljare</option>
                    <option
                      v-for="(r, index) in users"
                      :key="index"
                      :value="r.userID"
                    >
                      {{ r.realname }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <select
                    v-model="regionID"
                    class="form-select form-select-lg"
                    @change="searchCustomers()"
                  >
                    <option value="">Alla regioner</option>
                    <option
                      v-for="(r, index) in regions"
                      :key="index"
                      :value="r.regionID"
                    >
                      {{ r.region }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <select
                    class="form-select form-select-lg"
                    aria-label="Välj antal per sida"
                    v-model="numberOfHits"
                    @change="savePageSize()"
                  >
                    <option selected>Välj antal per sida</option>
                    <option value="10">10 per sida</option>
                    <option value="20">20 per sida</option>
                    <option value="30">30 per sida</option>
                    <option value="40">40 per sida</option>
                    <option value="50">50 per sida</option>
                    <option value="60">60 per sida</option>
                    <option value="70">70 per sida</option>
                    <option value="80">80 per sida</option>
                    <option value="90">90 per sida</option>
                    <option value="100">100 per sida</option>
                    <option value="125">125 per sida</option>
                    <option value="150">150 per sida</option>
                    <option value="175">175 per sida</option>
                    <option value="200">200 per sida</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3">
                  <select
                    class="form-select form-select-lg"
                    aria-label="Default select example"
                    v-model="picked"
                    @change="searchCustomers()"
                  >
                    <option value="">Välj rating</option>
                    <option value="4">Platina</option>
                    <option value="1">Guld</option>
                    <option value="2">Silver</option>
                    <option value="3">Brons</option>
                    <option value="5">Prospect</option>
                    <option value="6">Inaktiv</option>
                    <option value="-1">Ingen rating</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="prospects"
                      value="prospects"
                      v-model="selectedFilters"
                      @change="searchCustomers()"
                    />
                    <label class="form-check-label" for="prospects"
                      >Icke-betalande</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="buyer"
                      value="buyer"
                      v-model="selectedFilters"
                      @change="searchCustomers()"
                    />
                    <label class="form-check-label" for="buyer"
                      >Köpande kunder</label
                    >
                  </div>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="exportExcel()"
                  >
                    Excel
                  </button>
                </div>
                <div class="col-md-5">
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <div class="number-hits me-md-3">
                      <p class="mt-3 hits">
                        <strong>Antal träffar:</strong>
                        <span style="white-space: nowrap"
                          >{{ companies.length }} st</span
                        >
                      </p>
                    </div>
                    <button
                      v-if="
                        accessLevel == 'admin' || accessLevel == 'externmanager'
                      "
                      type="button"
                      class="btn btn-secondary me-md-2"
                      @click="openModal()"
                    >
                      Ändra säljare
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="addCompany"
                    >
                      <i class="bi bi-plus-circle-fill"></i> Lägg till företag
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="sortedCompanies.length > 0 && !loading">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" @click="sortBy('company')">
                      Företag<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col">Knr</th>
                    <th scope="col">Nivå</th>
                    <th scope="col">Säljare</th>
                    <th scope="col">Bransch</th>
                    <th scope="col" @click="sortBy('regionID')">
                      Region<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col" @click="sortBy('y')">
                      Årets ordervärde<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col" @click="sortBy('d')">
                      Totalt ordervärde<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(company, index) in sortedCompanies" :key="index">
                    <td @click="showCustomer(company)">
                      <img
                        width="24"
                        :src="require('@/assets/villalivet-2.png')"
                        v-if="company.isBuyer"
                      />
                      {{ company.company }}
                    </td>
                    <td>{{ company.n }}</td>
                    <td>
                      <i class="bi" :class="getScoreIcon(company.score)"></i>
                    </td>
                    <td v-if="company.realname" @click="showCustomer(company)">
                      {{ company.realname }}
                    </td>
                    <td v-else>
                      <a href="#" @click="assignMe(company.customerID)"
                        >Tilldela mig</a
                      >
                    </td>
                    <td @click="showCustomer(company)">
                      {{ getCategory(company.categoryID) }}
                    </td>
                    <td @click="showCustomer(customer)">
                      {{ getRegion(company.regionID) }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{
                        company.y != null && company.y > 0
                          ? formatCurrency(company.y)
                          : ""
                      }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{
                        company.d != null && company.d > 0
                          ? formatCurrency(company.d)
                          : ""
                      }}
                    </td>
                    <td class="actions">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'ShowCustomer',
                          params: { customerID: company.customerID },
                        }"
                      >
                        <i class="bi bi-window-plus"></i>
                      </router-link>
                      <button
                        type="button"
                        class="btn btn-default"
                        @click="editCustomer(company.customerID)"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        @click="deleteCustomer(company.customerID)"
                        v-if="accessLevel == 'admin'"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                v-if="pagination.last_page > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="paginate"
              />
            </template>
            <div
              class="alert alert-info"
              v-if="sortedCompanies.length == 0 && !loading"
            >
              <p class="m-0 fst-italic">Inget företag och kontakter hittades</p>
            </div>
          </div>
        </div>
        <div class="card my-2" v-if="loading">
          <div class="card-body">
            <pulse-loader :loading="loading" class="p-3"></pulse-loader>
          </div>
        </div>
      </div>
      <modal ref="refAssign" title="Tilldela säljare" @hidden="clear()">
        <div class="row mb-3">
          <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
            <p
              class="m-0 fst-italic"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
          <div class="col-md-12">
            Ändra tilldelad säljare för {{ companies.length }} kunder:
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <select v-model="assignee" class="form-select">
              <option value="-1">Ingen tilldelad säljare</option>
              <option
                v-for="(r, index) in users"
                :key="index"
                :value="r.userID"
              >
                {{ r.realname }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-secondary fw-bold"
              @click="assign()"
            >
              Uppdatera
            </button>
          </div>
        </div>
      </modal>
      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="row g-3 mb-3">
          <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
            <p
              class="m-0 fst-italic"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
          <fieldset class="border p-2 rounded">
            <legend class="fs-5">Grunduppgifter</legend>
            <div class="row g-3 mb-3">
              <div class="col-md-4">
                <label for="company" class="form-label">Företagsnamn</label>
                <input
                  type="text"
                  class="form-control"
                  id="company"
                  v-model="company"
                />
              </div>
              <div class="col-md-4">
                <label for="orgnr" class="form-label">Organisationsnr</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Tio siffror"
                  id="orgnr"
                  maxlength="10"
                  :disabled="customerLocation"
                  v-model="orgnr"
                  @keypress="numbersOnly"
                />
              </div>
              <div class="col-md-4">
                <label for="vatnr" class="form-label">VAT</label>
                <input
                  type="text"
                  class="form-control"
                  id="vatnr"
                  v-model="vatnr"
                />
              </div>
              <div class="col-md-4">
                <label>Typ av kund</label>
                <div class="form-check form-switch" style="margin-top: 16px">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    v-model="customerType"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked"
                    >Mediabyrå</label
                  >
                </div>
              </div>
              <div class="col-md-4">
                <label for="score" class="form-label">Rating</label>
                <select id="score" class="form-select" v-model="score">
                  <option :value="null">---</option>
                  <option value="4">Platina</option>
                  <option value="1">Guld</option>
                  <option value="2">Silver</option>
                  <option value="3">Brons</option>
                  <option value="5">Prospect</option>
                  <option value="6">Inaktiv</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Land</label>
                <div class="form-check form-switch" style="margin-top: 16px">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked2"
                    v-model="customerLocation"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked2"
                    >Utland</label
                  >
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="border p-3 rounded">
            <div class="row g-3 mb-3">
              <legend class="fs-5">Kontaktuppgifter</legend>
              <div class="col-md-4">
                <label for="email" class="form-label">E-postadress</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  v-model="email"
                />
              </div>
              <div class="col-md-4">
                <label for="billing-email" class="form-label"
                  >Fakturaadress</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="E-post"
                  id="billing-email"
                  v-model="billingEmail"
                />
              </div>
              <div class="col-md-4">
                <label for="website" class="form-label">Hemsida</label>
                <input
                  type="text"
                  class="form-control"
                  id="website"
                  v-model="website"
                />
              </div>
              <div class="col-md-4">
                <label for="marketing" class="form-label"
                  >Materialkontakt</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="marketing"
                  placeholder="e-mail"
                  v-model="marketing"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="border p-3 rounded">
            <div class="row g-3 mb-3">
              <legend class="fs-5">Övrigt om företaget</legend>
              <div class="col-md-4">
                <label for="payment-terms" class="form-label"
                  >Betalningsvillkor</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="payment-terms"
                  v-model="paymentTerms"
                />
              </div>
              <div class="col-md-4">
                <label for="category" class="form-label">Välj bransch</label>
                <select id="category" class="form-select" v-model="category">
                  <option :value="null">---</option>
                  <option
                    :value="category"
                    v-for="(category, index) in categories"
                    :key="index"
                  >
                    {{ category.category }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="region" class="form-label">Välj region</label>
                <select id="region" class="form-select" v-model="region">
                  <option :value="null">---</option>
                  <option
                    :value="region"
                    v-for="(region, index) in regions"
                    :key="index"
                  >
                    {{ region.region }}
                  </option>
                </select>
              </div>
              <div class="col-md-12">
                <label for="comment" class="form-label">Kommentar</label>
                <textarea
                  class="form-control"
                  id="comment"
                  rows="3"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="d-grid gap-2 mb-3">
          <button
            type="button"
            class="btn btn-secondary fw-bold"
            v-if="!editable"
            @click="addCustomer()"
          >
            Spara
          </button>
          <button
            type="button"
            class="btn btn-secondary fw-bold"
            v-if="editable"
            @click="updateCustomer()"
          >
            Uppdatera
          </button>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Modal from "@/components/Modal.vue";
import { mapState } from "vuex";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Pagination from "@/components/Pagination.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var personnummer = require("personnummer.js");

export default {
  name: "customer-companies",
  data() {
    return {
      companies: [],
      navbar: "Företag",
      modalTitle: null,
      customerID: null,
      company: null,
      orgnr: null,
      customerNr: null,
      customerType: false,
      email: null,
      marketing: null,
      billingEmail: null,
      vatnr: null,
      paymentTerms: null,
      category: null,
      region: null,
      assignee: null,
      searchHistory: 0,
      parentCustomerID: null,
      website: null,
      customerLocation: null,
      score: null,
      userID: null,
      regionID: "",
      status: null,
      comment: null,
      contactPersons: [],
      errors: [],
      editable: false,
      contacts: [],
      pagination: {
        current_page: 1,
        last_page: 0,
        size_page: localStorage.getItem("mogul_page_size") || 20,
        start_index: 0,
        end_index: 2,
      },
      currentSort: "company",
      sortDirection: "asc",
      searchKey: null,
      offset: 0,
      loading: false,
      picked: "",
      numberOfHits: localStorage.getItem("mogul_page_size") || 20,
      selectedFilters: ["prospects", "buyer"],
      selectedPros: [
        "platina",
        "guld",
        "silver",
        "brons",
        "prospect",
        "inaktiv",
      ],
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      customers: (state) => state.customer.customers,
      regions: (state) => state.region.regions,
      realname: (state) => state.auth.realname,
      agencies: (state) => state.agency.agencies,
      categories: (state) => state.category.categories,
      users: (state) => state.user.users,
      accessLevel: (state) => state.auth.accessLevel,
      history: (state) => state.customer.searchHistory,
    }),

    sortedCompanies() {
      let data = this.companies.slice().sort((a, b) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });

      return data.slice(this.pagination.start_index, this.pagination.end_index);
    },
  },

  components: {
    Navbar,
    Modal,
    Pagination,
    PulseLoader,
  },

  mounted() {
    this.checkRouteName();

    if (this.$route.params.userID != null) {
      this.userID = this.$route.params.userID;
    }
    if (this.userID == null) {
      this.userID = this.realname;
    }
    if (this.$route.params.regionID) {
      this.regionID = parseInt(this.$route.params.regionID);
    } else {
      this.regionID = "";
    }
    document.title = "Företag";
    this.fetchCustomers();
    this.$store.dispatch("agency/fetchAgency");
    this.$store.dispatch("category/fetchCategory");
    this.$store.dispatch("region/fetchRegions");
    this.$store.dispatch("customer/fetchHistory");
    if (isNaN(this.userID)) {
      this.$store.dispatch("user/fetchUsers").then(() => {
        let user = this.userID;
        user = this.users.find((d) => user === d.realname);
        this.userID = user.userID;
      });
    } else {
      this.$store.dispatch("user/fetchUsers");
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.userID && to.params.regionID && this.accessLevel != "admin") {
      this.userID = to.params.userID;
      this.regionID = to.params.regionID;
    } else if (to.params.userID && this.accessLevel != "admin") {
      this.userID = to.params.userID;
      this.fetchCustomers();
    }
    next();
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    numbersOnly($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault();
      }
    },

    exportExcel() {
      saveExcel({
        data: this.companies,
        fileName: "test.xlsx",
        columns: [
          {
            field: "customerID",
            title: "Mogul-kundnr",
          },
          {
            field: "score",
            title: "Rating",
          },
          {
            field: "company",
            title: "Företag",
          },
          {
            field: "r",
            title: "Region",
          },
          {
            field: "realname",
            title: "Säljare",
          },
          {
            field: "y",
            title: "Årets ordervärde",
          },
          {
            field: "d",
            title: "Totalt ordervärde",
          },
        ],
      });
    },

    deleteCustomer(customerID) {
      this.$store
        .dispatch("customer/deleteCustomer", { customerID: customerID })
        .then(() => {
          this.fetchCustomers();
        });
    },

    updateCustomers() {
      this.pagination.last_page = Math.ceil(
        this.customers.length / this.pagination.size_page
      );

      let pageQuery = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : this.pagination.current_page;

      this.pagination = {
        current_page: pageQuery,
        last_page: this.pagination.last_page,
        size_page: this.pagination.size_page,
        start_index:
          pageQuery * this.pagination.size_page - this.pagination.size_page,
        end_index: pageQuery * this.pagination.size_page,
      };

      this.companies = this.customers;

      this.offset =
        this.companies.length <= 100
          ? Math.ceil(this.companies.length / this.pagination.size_page)
          : 5;

      this.$router.replace({
        query: { page: pageQuery, keyword: this.searchKey },
      });
    },

    assignMe(customerID) {
      this.$store.dispatch("customer/assignMe", customerID).then(() => {
        this.fetchCustomers();
      });
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: "SEK",
      });
    },

    openModal() {
      this.$refs.refAssign.open();
    },

    assign() {
      let customerIDs = [];
      for (let i = 0; i < this.companies.length; i++) {
        customerIDs.push(this.companies[i].customerID);
      }
      customerIDs = customerIDs.join();
      this.$store
        .dispatch("customer/updateAssignee", {
          userID: this.assignee,
          customerIDs: customerIDs,
        })
        .then(() => {
          this.resetForm();
          this.$refs.refAssign.close();
          this.fetchCustomers();
        });
    },

    navigateToCustomer() {
      this.$router.push({
        name: "ShowCustomer",
        params: { customerID: this.searchHistory },
      });
    },

    sortBy(column) {
      if (column === this.currentSort) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      }
      this.currentSort = column;
    },

    paginate(page) {
      let currentPage = !page
        ? parseInt(this.pagination.current_page)
        : parseInt(page);
      let startIndex =
        currentPage * this.pagination.size_page - this.pagination.size_page;
      let endIndex = currentPage * this.pagination.size_page;

      this.pagination = {
        current_page: currentPage,
        last_page: Math.ceil(this.companies.length / this.pagination.size_page),
        size_page: this.pagination.size_page,
        start_index: startIndex,
        end_index: endIndex,
      };

      this.searchCustomers();

      this.$router.replace({
        query: { page: currentPage, keyword: this.searchKey },
      });
    },

    searchCustomers() {
      let isProspect = this.selectedFilters.indexOf("prospects") != -1;
      let isBuyer = this.selectedFilters.indexOf("buyer") != -1;
      this.companies = [];

      if (this.searchKey) {
        let selectedCategories = this.categories.filter((cat) => {
          return cat.category
            .toLowerCase()
            .includes(this.searchKey.toLowerCase());
        });

        this.companies = this.customers.filter((customer) => {
          let company = customer.company.toLowerCase();
          let orgnr = customer.o != null ? customer.o.toString() : "";
          let personName = "";
          let phones = "";
          if (customer.contacts != null) {
            for (var i = 0; i < customer.contacts.length; i++) {
              personName += ";" + customer.contacts[i].n;
              phones +=
                ";" +
                this.fixPhone(customer.contacts[i].p) +
                ";" +
                this.fixPhone(customer.contacts[i].m);
            }
          }
          personName = personName.toLowerCase();
          let keyword = this.searchKey.toLowerCase();

          return (
            (this.regionID == "" || this.regionID == customer.regionID) &&
            (company.includes(keyword) ||
              personName.includes(keyword) ||
              phones.includes(keyword) ||
              orgnr.includes(keyword) ||
              selectedCategories.find(
                (obj) => obj.categoryID == customer.categoryID
              )) &&
            this.ratingMatches(customer.score) &&
            ((isProspect && !isBuyer && !customer.isBuyer) ||
              (!isProspect && isBuyer && customer.isBuyer) ||
              (isProspect && isBuyer) ||
              (!isProspect && !isBuyer))
          );
        });

        this.pagination.last_page = Math.ceil(
          this.companies.length / this.pagination.size_page
        );

        this.offset =
          this.companies.length <= 100
            ? Math.ceil(this.companies.length / this.pagination.size_page)
            : 5;

        if (this.regionID) {
          this.$router.push({
            name: "UserRegionCompanies",
            params: { userID: this.userID, regionID: this.regionID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        } else {
          this.$router.push({
            name: "UserCompanies",
            params: { userID: this.userID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        }
      } else if (this.regionID !== "") {
        this.companies = this.customers.filter((customer) => {
          if (isProspect && isBuyer) {
            return (
              this.regionID == customer.regionID &&
              this.ratingMatches(customer.score)
            );
          } else if (isProspect) {
            return (
              this.regionID == customer.regionID &&
              !customer.isBuyer &&
              this.ratingMatches(customer.score)
            );
          } else if (isBuyer) {
            return (
              this.regionID == customer.regionID &&
              customer.isBuyer &&
              this.ratingMatches(customer.score)
            );
          }
          return (
            this.regionID == customer.regionID &&
            this.ratingMatches(customer.score)
          );
        });

        this.pagination.last_page = Math.ceil(
          this.companies.length / this.pagination.size_page
        );

        this.offset =
          this.companies.length <= 100
            ? Math.ceil(this.companies.length / this.pagination.size_page)
            : 5;

        if (this.regionID) {
          this.$router.push({
            name: "UserRegionCompanies",
            params: { userID: this.userID, regionID: this.regionID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        } else {
          this.$router.push({
            name: "UserCompanies",
            params: { userID: this.userID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        }
      } else {
        this.companies = this.customers.filter((customer) => {
          if (isProspect && isBuyer) {
            return this.ratingMatches(customer.score);
          } else if (isProspect) {
            return !customer.isBuyer && this.ratingMatches(customer.score);
          } else if (isBuyer) {
            return customer.isBuyer && this.ratingMatches(customer.score);
          }
          return this.ratingMatches(customer.score);
        });

        this.pagination.last_page = Math.ceil(
          this.companies.length / this.pagination.size_page
        );

        this.offset =
          this.companies.length <= 100
            ? Math.ceil(this.companies.length / this.pagination.size_page)
            : 5;

        if (this.regionID) {
          this.$router.push({
            name: "UserRegionCompanies",
            params: { userID: this.userID, regionID: this.regionID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        } else {
          this.$router.push({
            name: "UserCompanies",
            params: { userID: this.userID },
            query: {
              page: this.pagination.current_page,
              keyword: this.searchKey,
            },
          });
        }
      }
    },

    getScoreIcon(score) {
      var icon = "";
      if (score == 1) {
        icon = "bi-trophy-fill";
      } else if (score == 2) {
        icon = "bi-award-fill";
      } else if (score == 3) {
        icon = "bi-patch-check-fill";
      } else if (score == 4) {
        icon = "bi-gem";
      } else if (score == 5) {
        icon = "bi-brightness-alt-high-fill";
      }
      return icon;
    },

    ratingMatches(score) {
      return (
        (this.picked == "" && score != 6) ||
        this.picked == score ||
        (this.picked == -1 && !score)
      );
    },

    navigateToUser() {
      if (this.regionID) {
        this.$router.replace({
          name: "UserRegionCompanies",
          params: { userID: this.userID, regionID: this.regionID },
          query: {
            page: this.pagination.current_page,
            keyword: this.searchKey,
          },
        });
      } else {
        this.$router.replace({
          name: "UserCompanies",
          params: { userID: this.userID },
          query: {
            page: this.pagination.current_page,
            keyword: this.searchKey,
          },
        });
      }
      this.fetchCustomers();
    },

    fetchCustomers() {
      this.loading = true;
      this.$store.dispatch("customer/fetchCustomers", this.userID).then(() => {
        this.loading = false;

        this.updateCustomers();

        if (this.$route.query.keyword) {
          this.searchKey = this.$route.query.keyword;
        }
        this.searchCustomers();
      });
    },

    getCategory(categoryID) {
      if (this.categories.length > 0) {
        let category = this.categories.find(
          (category) => category.categoryID === categoryID
        );
        if (category) {
          return category.category;
        } else {
          return "";
        }
      }
    },

    getRegion(regionID) {
      if (regionID == null || regionID == "null") {
        return "(saknas)";
      }
      if (this.regions.length > 0) {
        let region = this.regions.find(
          (region) => region.regionID === regionID
        );
        return region.region;
      }
    },

    addCompany() {
      this.modalTitle = "Lägg till företag";
      this.$refs.refModal.open();
    },

    fetchContactPersons() {
      let formData = new FormData();
      formData.append("customerID", this.customerID);
      formData.append("key", this.apiKey);
      this.$http.post(this.baseUrl + "/get.php?type=contacts", formData);
    },

    fetchAgencies() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http.post(this.baseUrl + "/get.php?type=agency", formData);
    },

    addCustomer() {
      let parsed = personnummer.parseCIN(this.orgnr);

      this.errors = [];

      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("company", this.company);
        if (!this.customerLocation) {
          formData.append("orgnr", parsed.input);
        }
        formData.append("customerNr", this.customerNr);
        formData.append("email", this.email);
        formData.append("marketingEmail", this.marketing);
        formData.append("billingEmail", this.billingEmail);
        formData.append("vatnr", this.vatnr);
        formData.append("paymentTerms", this.paymentTerms);
        formData.append("score", this.score);
        formData.append("categoryID", this.category.categoryID);
        formData.append("regionID", this.region.regionID);
        formData.append("customerType", this.customerType ? "media" : "direct");
        formData.append("website", this.website);
        formData.append("status", this.status);
        formData.append("comment", this.comment);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=addCustomer", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.resetForm();
              this.fetchCustomers();
              this.$refs.refModal.close();
            }
          });
      }
    },

    validateForm() {
      let parsed = personnummer.parseCIN(this.orgnr);
      let hasError = false;
      if (!this.company) {
        hasError = true;
        this.errors.push("Ange företagsnamn");
      } 
      
      if (this.category == null) {
        this.errors.push("Bransch saknas");
        hasError = true;
      } 
      
      if (this.region == null) {
        hasError = true;
        this.errors.push("Region saknas");
      } 
      
      if (!this.customerLocation && !parsed.valid) {
        hasError = true;
        this.errors.push("Organisationsnummer är ogiltigt");
      } 
      
      if (this.customerLocation && this.vatnr.length < 10) {
        hasError = true;
        this.errors.push("Utländskt företag måste ha VAT-nr");
      }
      return hasError;
    },

    resetForm() {
      this.company = null;
      this.orgnr = null;
      this.customerNr = null;
      this.email = null;
      this.billingEmail = null;
      this.vatnr = null;
      this.marketing = null;
      this.paymentTerms = null;
      this.category = null;
      this.score = null;
      this.region = null;
      this.website = null;
      this.status = null;
      this.comment = null;
      this.assignee = null;
    },

    showCustomer(customer) {
      if (customer.customerID) {
        this.$router.push({
          name: "ShowCustomer",
          params: {
            customerID: customer.customerID,
          },
        });
      }
    },

    editCustomer(customerID) {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      formData.append("customerID", customerID);
      this.$http
        .post(this.baseUrl + "/get.php?type=customer", formData)
        .then((res) => {
          let customer = res.data.data.customer;
          this.customerID = customerID;
          this.company = customer.company;
          this.orgnr = customer.orgnr;
          this.customerNr = customer.customerNr;
          this.email = customer.email;
          this.marketing = customer.marketingEmail;
          this.score = customer.score;
          this.billingEmail = customer.billingEmail;
          this.vatnr = customer.vatnr;
          this.customerType = customer.customerType == "media";
          this.paymentTerms = customer.paymentTerms;
          this.category = this.categories.find(
            (category) => category.categoryID == customer.categoryID
          );
          this.region = this.regions.find(
            (region) => region.regionID == customer.regionID
          );
          this.website = customer.website;
          this.status = customer.status;
          this.comment = customer.comment;
        });

      this.fetchAgencies();

      this.editable = true;
      this.modalTitle = "Redigera företag";
      this.$refs.refModal.open();
    },

    fixPhone(str) {
      let out = "";
      if (str == null) {
        return "";
      }
      for (let i = 0; i < str.length; i++) {
        let c = str.substring(i, i + 1);
        if (
          c == "0" ||
          c == "1" ||
          c == "2" ||
          c == "3" ||
          c == "4" ||
          c == "5" ||
          c == "6" ||
          c == "7" ||
          c == "8" ||
          c == "9"
        ) {
          out += c;
        }
      }
      return out;
    },

    updateCustomer() {
      this.errors = [];
      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("customerID", this.customerID);
        formData.append("company", this.company);
        formData.append("orgnr", this.orgnr);
        formData.append("customerNr", this.customerNr);
        formData.append("email", this.email);
        formData.append("marketingEmail", this.marketing);
        formData.append("score", this.score);
        formData.append("billingEmail", this.billingEmail);
        formData.append("vatnr", this.vatnr);
        formData.append("paymentTerms", this.paymentTerms);
        formData.append(
          "categoryID",
          this.category ? this.category.categoryID : null
        );
        formData.append("regionID", this.region ? this.region.regionID : null);
        formData.append("key", this.apiKey);
        formData.append("website", this.website);
        formData.append("status", this.status);
        formData.append("customerType", this.customerType ? "media" : "direct");
        formData.append("comment", this.comment);

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateCustomer", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.resetForm();
              this.fetchCustomers();
              this.$refs.refModal.close();
            }
          });
      }
    },

    savePageSize() {
      this.pagination.size_page = this.numberOfHits;
      localStorage.setItem("mogul_page_size", this.numberOfHits);

      this.paginate();
    },

    clear() {
      this.resetForm();
      this.errors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#historySelect {
  max-width: 300px;
  overflow: auto;
}

.company {
  .headers {
    background: #f3f3f3;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .hits strong {
    color: #707b7c;
  }

  .table {
    thead {
      th {
        cursor: pointer;
        padding: 0.8rem;

        &:hover {
          color: #34495e;
          background: #f0f8fe;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background: #d6eaf8;
        }

        .bi {
          font-size: 20px;
        }

        .bi-trophy-fill {
          color: #ffd700;
        }

        .bi-award-fill {
          color: #c0c0c0;
          font-size: 23px;
        }

        .bi-patch-check-fill {
          color: #cd7f32;
        }

        .bi-gem {
          color: #007bb8;
        }

        .bi-brightness-alt-high-fill {
          color: #e74c3c;
          font-size: 25px;
        }
      }
    }
  }

  .modal {
    .alert p {
      font-size: 14px;
    }

    fieldset {
      background: #f8f9f9;
    }

    .btn-link {
      text-decoration: none;
    }

    .contacts {
      .row {
        background: #f1f1f1;
        position: relative;

        .close {
          margin: 0;
          padding: 0;
          position: absolute;
          top: -5px;
          right: -5px;
          width: auto;
          cursor: pointer;
        }
      }
    }

    .media {
      .row {
        background: #f1f1f1;
        position: relative;

        .close {
          margin: 0;
          padding: 0;
          position: absolute;
          top: -5px;
          right: -5px;
          width: auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
