<template>
  <div
    class="modal fade"
    id="mogulModal"
    ref="mogulModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="mogulModalLabel">{{ title }}</h5>
          <div class="header-actions">
            <slot name="delete"></slot>
            <button type="button" class="btn-close" @click="close()"></button>
          </div>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="footer">
          <button type="button" class="btn btn-secondary" @click="close()">
            Avbryt
          </button>
          <button type="button" class="btn btn-primary" @click="$emit('save')">
            Spara
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  name: "mogulModal",
  props: {
    title: {
      type: String,
      required: false,
      default: "Modal title...",
    },
    footer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modal: null,
      showModal: false,
    };
  },

  mounted() {
    this.modal = new Modal(this.$refs.mogulModal);
  },

  methods: {
    open() {
      this.modal.show();
    },

    delete() {

    },

    close() {
      this.$emit("hidden");
      this.modal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-close {
  margin-left: 15px;
}
</style>
