import axios from "../../axios";

const state = {
  agencies: [],
};

const mutations = {
  FETCH_AGENCY(state, payload) {
    state.agencies = payload;
  },
};

const actions = {
  fetchAgency({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=agency", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_AGENCY", response.data.data);
            resolve(response);
          } else {
            throw new Error("Something goes wrong, try again.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // company, email, website, orgnr, vatnr, billingEmail, customerNr
  addAgency({ rootState }, agent) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("company", agent.company);
      formData.append("email", agent.email);
      formData.append("website", agent.website);
      formData.append("orgnr", agent.orgnr);
      formData.append("vatnr", agent.vatnr);
      formData.append("billingEmail", agent.billingEmail);
      formData.append("customerNr", agent.customerNr);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=addAgency", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        });
    });
  },

  updateAgency() {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
