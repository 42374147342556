<template>
  <main>
    <div class="aftersales h-100 w-100">
      <navbar title="Aftersales" />
      <div class="content p-3">
        <div class="card mb-2">
          <div class="card-body">
            <h3>
              Försäljning per säljare och kategori, försäljning som avser 2022
            </h3>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Säljare</th>
                  <th scope="col">Kategori</th>
                  <th scope="col">Försäljning</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(deal, index) in orders2" :key="index">
                  <td>
                    {{ deal.realname }}
                  </td>
                  <td>
                    {{ deal.category }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">
            <h3>
              Försäljning per säljare och kategori, försäljning som skett under
              2021
            </h3>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Säljare</th>
                  <th scope="col">Kategori</th>
                  <th scope="col">Försäljning</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(deal, index) in orders" :key="index">
                  <td>
                    {{ deal.realname }}
                  </td>
                  <td>
                    {{ deal.category }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";

export default {
  components: { Navbar },
  name: "Aftersales-users",
  data() {
    return {
      startDate: "2021-01-01",
      endDate: "2021-12-31",
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.order.aftersaleUsers,
      orders2: (state) => state.order.aftersaleUsers2,
    }),
  },

  mounted() {
    this.checkRouteName();

    document.title = "Aftersales";
    this.$store.dispatch("order/fetchAftersaleUsers", {
      startDate: this.startDate,
      endDate: this.endDate,
    });
    this.$store.dispatch("order/fetchAftersaleUsers2", {
      startDate: "2022-01-01",
      endDate: "2022-12-31",
    });
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "SEK",
      });
    },
  },
};
</script>

<style></style>
