<template>
  <main>
    <div class="paycheck w-100 pb-3">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card mb-3 info">
          <div class="card-body">
            <i class="bi bi-info-circle-fill"></i>
            <p class="m-0">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              culpa sint, provident ut aut molestiae facere quibusdam vitae
              tenetur laboriosam ratione quod sit ipsum, aperiam cupiditate!
              Dolorum voluptates hic voluptatibus!
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <stacked-chart />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <line-chart />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <pie-chart />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <bar-chart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import StackedChart from "@/components/chart/StackedChart.vue";
import LineChart from "@/components/chart/LineChart.vue";
import PieChart from "@/components/chart/PieChart.vue";
import BarChart from "@/components/chart/BarChart.vue";

export default {
  name: "PayCheck",
  data() {
    return {
      navbar: "Lönesnurra",
    };
  },

  components: {
    Navbar,
    StackedChart,
    LineChart,
    PieChart,
    BarChart,
  },
};
</script>

<style lang="scss" scoped>
.paycheck {
  .info {
    .card-body {
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        font-size: 20px;
        margin-right: 15px;
      }
    }
  }
}
</style>
