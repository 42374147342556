<template>
  <main>
    <div class="budget h-100 w-100">
      <navbar title="Budget" />
      <div class="content p-3">
        <div class="d-flex">
          <div class="mb-3">
            <datepicker
              class="form-control"
              placeholder="Välj datum"
              :locale="locale"
              :lower-limit="lowerLimit"
              :minimumView="'year'"
              :inputFormat="'yyyy'"
              v-model="selectedDate"
              @update:modelValue="dateChanged()"
            />
          </div>
        </div>
        <!-- <pre>{{ users }}</pre> -->
        <div class="card">
          <div class="card-body">
            <div class="col-lg-12">
              <table class="table table-hover">
                <thead>
                  <tr class="th_smtxt">
                    <th scope="col">
                      <p class="vl_sm">Utgåva</p>
                      Användare
                    </th>
                    <th scope="col"></th>
                    <th scope="col">Jan</th>
                    <th scope="col">
                      <p class="vl_sm">Nr 1</p>
                      Feb
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 2</p>
                      Mar
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 3</p>
                      Apr
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 4</p>
                      Maj
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 5</p>
                      Juni
                    </th>
                    <th scope="col">Juli</th>
                    <th scope="col">Aug</th>
                    <th scope="col">
                      <p class="vl_sm">Nr 6</p>
                      Sep
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 7</p>
                      Okt
                    </th>
                    <th scope="col">
                      <p class="vl_sm">Nr 8</p>
                      Nov
                    </th>
                    <th scope="col">Dec</th>
                    <th scupe="col">Summa</th>
                  </tr>
                </thead>
                <tbody v-if="users && pageLoaded">
                  <template v-for="(r, index) in users" :key="index">
                    <tr style="background-color: #d7e7ee">
                      <th
                        scope="row"
                        class="tbl_sum"
                        style="background-color: white"
                      ></th>
                      <th scope="row" class="tbl_borderstyle">
                        <i class="bi bi-book"></i>
                      </th>
                      <td v-for="(b, i) in r.budget1" :key="i">
                        <input
                          :class="{
                            tbl_budget: true,
                            changed:
                              budget1Res[r.userID][i] !=
                              orgBudget1[r.userID][i],
                          }"
                          type="text"
                          @blur="setBudget(r.userID, 1, i + 1)"
                          @keyup.enter="setBudget(r.userID, 1, i + 1)"
                          v-model="budget1Res[r.userID][i]"
                        />
                      </td>
                      <td class="tbl_sum">{{ budget1Sum[r.userID] }}</td>
                    </tr>
                    <tr style="background-color: #efefef">
                      <th
                        scope="row"
                        class="tbl_sum"
                        style="border-top: none; background-color: white"
                      >
                        <p class="p_user" style="margin-top: -22px">
                          {{ r.realname }}
                        </p>
                      </th>
                      <th scope="row" class="tbl_borderstyle">
                        <i class="bi bi-display"></i>
                      </th>
                      <td v-for="(b, i) in r.budget2" :key="i">
                        <input
                          :class="{
                            tbl_budget: true,
                            changed:
                              budget2Res[r.userID][i] !=
                              orgBudget2[r.userID][i],
                          }"
                          type="text"
                          @blur="setBudget(r.userID, 2, i + 1)"
                          @keyup.enter="setBudget(r.userID, 2, i + 1)"
                          v-model="budget2Res[r.userID][i]"
                        />
                      </td>
                      <td class="tbl_sum">{{ budget2Sum[r.userID] }}</td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr class="th_smtxt" style="border-top: 2px solid grey">
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Jan</th>
                    <th scope="col">Feb</th>
                    <th scope="col">Mar</th>
                    <th scope="col">Apr</th>
                    <th scope="col">Maj</th>
                    <th scope="col">Juni</th>
                    <th scope="col">Juli</th>
                    <th scope="col">Aug</th>
                    <th scope="col">Sep</th>
                    <th scope="col">Okt</th>
                    <th scope="col">Nov</th>
                    <th scope="col">Dec</th>
                    <th scupe="col">Summa</th>
                  </tr>
                  <tr class="tbl_sum" style="background-color: #d7e7ee">
                    <td style="background: white">Budget tidning</td>
                    <td></td>
                    <td v-for="(b, i) in totalBudget1" :key="i">
                      {{ b }}
                    </td>
                    <td class="td_strong">{{ sum1 }}</td>
                    <td></td>
                  </tr>
                  <tr class="tbl_sum" style="background-color: #efefef">
                    <td style="background: white">Budget digital</td>
                    <td></td>
                    <td v-for="(b, i) in totalBudget2" :key="i">
                      {{ b }}
                    </td>
                    <td class="td_strong">{{ sum2 }}</td>
                    <td></td>
                  </tr>
                  <tr class="tbl_sum" style="background-color: #389ecb6e">
                    <td style="background: white">Total</td>
                    <td></td>
                    <td v-for="(b, i) in totalBudget1" :key="i">
                      {{ b + totalBudget2[i] }}
                    </td>
                    <td class="td_strong">{{ sum1 + sum2 }}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import Datepicker from "vue3-datepicker";
import { ref } from "vue";
import { sv } from "date-fns/locale";
import moment from "moment";

export default {
  components: { Navbar, Datepicker },
  name: "user-budget",
  data() {
    return {
      budget1Res: [],
      budget2Res: [],
      orgBudget1: [],
      orgBudget2: [],
      pageLoaded: false,
      budget1Sum: [],
      budget2Sum: [],
      sum1: null,
      sum2: null,
      totalBudget1: [],
      totalBudget2: [],
      locale: sv,
      lowerLimit: ref(new Date()),
      selectedDate: new Date(),
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.budget.users,
    }),
  },

  mounted() {
    this.checkRouteName();

    let year = moment(this.selectedDate).format("YYYY");
    document.title = "Budget";
    this.$store.dispatch("budget/fetchUsers", year).then(() => {
      this.updateBudget();
    });
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    updateBudget() {
      this.totalBudget1 = [];
      this.totalBudget2 = [];
      this.sum1 = 0;
      this.sum2 = 0;
      for (var i = 0; i < this.users.length; i++) {
        this.budget1Res[this.users[i].userID] = this.users[i].budget1;
        this.budget2Res[this.users[i].userID] = this.users[i].budget2;
        var sum = 0;
        for (var j = 0; j < this.users[i].budget1.length; j++) {
          sum += this.users[i].budget1[j] ?? 0;
          this.totalBudget1[j] = this.totalBudget1[j]
            ? this.totalBudget1[j] + this.users[i].budget1[j]
            : this.users[i].budget1[j];
        }
        this.budget1Sum[this.users[i].userID] = sum;
        this.sum1 += sum;
        sum = 0;
        for (j = 0; j < this.users[i].budget2.length; j++) {
          sum += this.users[i].budget2[j] ?? 0;
          this.totalBudget2[j] = this.totalBudget2[j]
            ? this.totalBudget2[j] + this.users[i].budget2[j]
            : this.users[i].budget2[j];
        }
        this.budget2Sum[this.users[i].userID] = sum;
        this.sum2 += sum;
      }
      this.orgBudget1 = JSON.parse(JSON.stringify(this.budget1Res));
      this.orgBudget2 = JSON.parse(JSON.stringify(this.budget2Res));
      this.pageLoaded = true;
    },

    setBudget(userID, categoryID, period) {
      let year = moment(this.selectedDate).format("YYYY");
      let data = {
        userID: userID,
        categoryID: categoryID,
        period: year + "-" + period,
        budget:
          categoryID == 1
            ? this.budget1Res[userID][period - 1]
            : this.budget2Res[userID][period - 1],
      };
      this.$store.dispatch("budget/setUserBudget", data).then(() => {
        this.$store.dispatch("budget/fetchUsers", year).then(() => {
          this.updateBudget();
        });
      });
    },

    dateChanged() {
      let year = moment(this.selectedDate).format("YYYY");
      this.$store
        .dispatch("budget/fetchUsers", year)
        .then(() => this.updateBudget());
    },
  },
};
</script>

<style>
.tbl_sum {
  color: rgb(18, 18, 18);
}
.tbl_sum td {
  font-size: 0.75rem;
}
.changed {
  background-color: #ffaaaa;
}
</style>
