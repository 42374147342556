import axios from "../../axios";

const state = {
  orders: {
    rows: [],
  },
  dealContent: {},
  aftersale: {},
  confirmedOrders: [],
  aftersaleUsers: [],
  aftersaleUsers2: [],
  aftersaleOther: [],
  currencyRates: [],
};

const mutations = {
  FETCH_DEALS(state, payload) {
    state.orders = payload;
  },
  FETCH_DEALCONTENT(state, payload) {
    state.dealContent = payload;
  },
  FETCH_AFTERSALE(state, payload) {
    state.aftersale = payload;
  },
  FETCH_AFTERSALE_USERS(state, payload) {
    state.aftersaleUsers = payload;
  },
  FETCH_CURRENCIES(state, payload) {
    state.currencyRates = payload;
  },
  FETCH_AFTERSALE_USERS2(state, payload) {
    state.aftersaleUsers2 = payload;
  },
  FETCH_AFTERSALE_OTHER(state, payload) {
    state.aftersaleOther = payload;
  },
  FETCH_CONFIRMED_ORDERS(state, payload) {
    state.confirmedOrders = payload;
  },
};

const actions = {
  fetchOrders({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=deal", formData)
        .then((response) => {
          commit("FETCH_DEALS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchConfirmedOrders({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=confirmedDeals", formData)
        .then((response) => {
          commit("FETCH_CONFIRMED_ORDERS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchDealContent({ rootState, commit }, dealID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("dealID", dealID);
      axios
        .post(rootState.baseUrl + "/get.php?type=dealContent", formData)
        .then((response) => {
          commit("FETCH_DEALCONTENT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAftersale({ rootState, commit }, year) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("year", year);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=asOrder2", formData)
        .then((response) => {
          commit("FETCH_AFTERSALE", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAftersaleUsers({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=asUser", formData)
        .then((response) => {
          commit("FETCH_AFTERSALE_USERS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAftersaleOther({ rootState, commit }, year) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("year", year);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=asOther", formData)
        .then((response) => {
          commit("FETCH_AFTERSALE_OTHER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchCurrencies({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=currencies", formData)
        .then((response) => {
          commit("FETCH_CURRENCIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAftersaleUsers2({ rootState, commit }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=asUser2", formData)
        .then((response) => {
          commit("FETCH_AFTERSALE_USERS2", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // edit.php?type=addDeal med POST- variabler customerID och personID
  addOrder({ rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", data.customerID);
      formData.append("personID", data.personID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addDeal", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchOrders");
            resolve(response.data.data);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addTemporaryProduct({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      formData.append("dealID", data.dealID);
      formData.append("productName", data.productName);
      formData.append("categoryID", data.categoryID);
      formData.append("price", data.price);
      formData.append("quantity", data.quantity);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      axios
        .post(
          rootState.baseUrl + "/edit.php?type=addTemporaryProduct",
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=updateDeal med POST - variabler dealID och status
  updateOrder({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("status", data.status);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=updateDeal", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  info({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("internalInfo", data.internalInfo);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=changeOrderInfo", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createInvoice({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("issueID", data.issueID ?? null);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=createInvoice", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=addDealContent med POST- variabler dealID, productID, quantity, discount
  addDealContent({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("productID", data.productID);
      formData.append("quantity", data.quantity);
      formData.append("currencyCode", data.currencyCode);
      formData.append(
        "discount",
        data.discount != null && data.discount.toString().length > 0
          ? data.discount
          : 0
      );
      formData.append("price", data.price);
      formData.append("regionID", data.regionID);
      if (data.issueID) {
        formData.append("issueID", data.issueID);
      }
      if (data.startDate) {
        formData.append("startDate", data.startDate);
        if (data.endDate) {
          formData.append("endDate", data.endDate);
        }
      }
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addDealContent", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // edit.php?type=updateDealContent med POST - variabler rowID, productID, quantity, discount
  updateDealContent({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("rowID", data.rowID);
      formData.append("dealID", data.dealID);
      formData.append("productID", data.productID);
      formData.append("currencyCode", data.currencyCode);
      formData.append("quantity", data.quantity);
      formData.append("discount", data.discount ?? 0);
      formData.append("price", data.price);
      formData.append("regionID", data.regionID);
      if (data.issueID) {
        formData.append("issueID", data.issueID);
      }
      if (data.startDate) {
        formData.append("startDate", data.startDate);
        if (data.endDate) {
          formData.append("endDate", data.endDate);
        }
      }
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=updateDealContent", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeDealContent({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("rowID", data.rowID);
      formData.append("dealID", data.dealID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=removeDealContent", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeDeal({ rootState }, dealID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", dealID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=removeDeal", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
