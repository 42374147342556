import axios from "axios";
import store from "./store";

const base = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

// Add a response interceptor
base.interceptors.response.use(
  function (response) {
    // Check if session expired
    if (
      response.data.status === "error" &&
      response.data.data === "Felaktig sessionsnyckel."
    ) {
      store.dispatch("auth/authLogout");
    }
    return response;
  },
  function (error) {
    // Check if response has errors
    return Promise.reject(error);
  }
);

export default base;
