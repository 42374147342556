<template>
  <div class="navbar-holder">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="title" style="margin-left: 40px; margin-top: 10px">
          {{ title }}
        </div>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav me-auto">
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "navbar-menu",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-holder {
  background: #fff;
  height: 79px;
  .title {
    color: rgba(0, 0, 0, 0.9);
    font-size: 20px;
  }
  .nav-item {
    font-size: 17px;
    letter-spacing: 1px;
    color: #000;
  }
}
</style>
