<template>
  <main>
    <div class="home w-100 h-100">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <div class="filters border rounded">
              <div class="row g-3">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    v-model="searchProduct"
                    @keyup="updateFilters()"
                    placeholder="Sök produktnamn"
                  />
                </div>
                <div class="col">
                  <select
                    class="form-select"
                    v-model="searchCategory"
                    @change="updateFilters()"
                  >
                    <option :value="null">Välj produktkategori</option>
                    <option
                      :value="productCategory.categoryID"
                      v-for="(productCategory, index) in productCategories"
                      :key="index"
                    >
                      {{ productCategory.category }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    class="form-select"
                    v-model="searchRegion"
                    @change="updatePrices()"
                  >
                    <option
                      :value="region.regionID"
                      v-for="(region, index) in regions"
                      :key="index"
                    >
                      {{ region.region }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    class="form-select"
                    v-model="searchDate"
                    @change="updatePrices()"
                  >
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                </div>
                <div class="col">
                  <select
                    class="form-select"
                    v-model="searchStatus"
                    @change="updateFilters()"
                  >
                    <option value="">Status</option>
                    <option value="active">Aktiv</option>
                    <option value="inactive">Inaktiv</option>
                  </select>
                </div>
              </div>
            </div>
            <ProductTable
              :products="filteredProducts"
              :pricelist="pricelist"
              :productCategories="productCategories"
              :region="findRegion(searchRegion)"
              :regionID="searchRegion"
              :year="searchDate"
              @updateFilters="reloadProducts"
              @updatePrices="updatePrices"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import ProductTable from "@/components/ProductTable.vue";

export default {
  name: "AdvertisingProduct",
  data() {
    return {
      navbar: "Produkter",
      product: null,
      productCategory: null,
      searchStatus: "active",
      searchProduct: null,
      searchRegion: 0,
      searchDate: "2022",
      searchCategory: null,
      filteredProducts: [],
    };
  },

  computed: {
    ...mapState({
      products: (state) => state.product.products,
      regions: (state) => state.region.regions,
      pricelist: (state) => state.product.pricelist,
      productCategories: (state) => state.product.productCategories,
    }),
  },

  components: {
    Navbar,
    ProductTable,
  },

  mounted() {
    this.checkRouteName();

    document.title = "Produkter";
    this.$store.dispatch("product/fetchProductCategory").then(() => {
      this.$store.dispatch("product/fetchProducts").then(() => {
        this.updateFilters();
      });
    });
    this.$store.dispatch("region/fetchRegions").then(() => {
      this.updatePrices();
    });
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    reloadProducts() {
      this.$store.dispatch("product/fetchProducts").then(() => {
        this.updateFilters();
      });
    },
    updateFilters() {
      this.filteredProducts = this.products.filter((product) => {
        if (this.searchStatus == "active" && product.status != "active") {
          return false;
        } else if (
          this.searchStatus == "inactive" &&
          product.status != "inactive"
        ) {
          return false;
        }
        if (
          this.searchCategory != null &&
          this.searchCategory > 0 &&
          this.searchCategory != product.categoryID
        ) {
          return false;
        }
        if (
          this.searchProduct != null &&
          this.searchProduct.length > 0 &&
          !product.productName.includes(this.searchProduct)
        ) {
          return false;
        }
        return true;
      });
    },
    updatePrices() {
      this.$store.dispatch("product/fetchPricelistForDate", {
        categoryID: 0,
        regionID: this.searchRegion,
        date: this.searchDate + "-01-01",
      });
    },
    findCategory(categoryID) {
      let category = this.productCategories.find(
        (category) => category.categoryID === categoryID
      );
      return category ? category.category : "";
    },
    findRegion(regionID) {
      let region = this.regions.find((region) => region.regionID == regionID);
      return region ? region.region : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  background: #f1f1f1;
  padding: 2rem 1rem;
  margin-bottom: 15px;
}
small {
  font-size: 0.75rem;
  font-weight: 600;
}
</style>
