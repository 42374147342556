<template>
  <main>
    <div class="forgot-password h-100 w-100 bg-white">
      <div class="content p-3">
        <div class="wrapper">
          <div class="header">
            <img
              src="@/assets/villalivet-2.png"
              class="img"
              alt="Mogul logo"
              width="100"
            />
          </div>
          <div class="auth-body">
            <div class="text-center">
              <p>
                Om du behöver ett nytt lösenord anger du din e-postadress här så
                får du ett mail med en länk du kan använda för att välja ett
                nytt lösenord
              </p>
            </div>
            <div
              class="alert alert-danger"
              role="alert"
              v-if="errors.length > 0"
            >
              <p
                class="m-0 fst-italic"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error }}
              </p>
            </div>
            <div class="mb-3">
              <input
                type="email"
                class="form-control form-control-lg"
                placeholder="E-postadress"
                v-model="email"
                @keyup.enter="forgotPassword()"
              />
            </div>
            <div class="d-grid gap-2">
              <button
                class="btn btn-secondary btn-lg"
                type="button"
                @click="forgotPassword()"
              >
                Begär lösenord
              </button>
            </div>
          </div>
          <div class="auth-footer">
            <p>
              Har du konto? <router-link to="/login">Logga in här.</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "forgotPassword",
  data() {
    return {
      email: null,
      errors: [],
    };
  },

  mounted() {
    document.title = "Återställa lösenord";
  },

  methods: {
    forgotPassword() {
      this.errors = [];
      if (!this.email) {
        this.errors.push("Du måste ange e-postadress");
      } else {
        console.log("Pass");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .wrapper {
    width: 32rem;
    background: #fff;
    padding: 5rem 2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15rem;
    .header {
      text-align: center;
      padding-bottom: 40px;
    }

    button {
      font-weight: 600;
      font-size: 1rem;
    }

    .auth-footer {
      text-align: center;
      margin-top: 25px;
      p {
        font-size: 0.85rem;
        font-weight: 600;
        color: #909497;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
