<template>
  <div class="alerts">
    <div
      class="alert alert-danger"
      role="alert"
      v-if="errors != null && errors.length > 0"
    >
      <p class="m-0 fst-italic" v-for="(error, index) in errors" :key="index">
        <small>{{ error }}</small>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-alerts",
  props: { errors: Array },
};
</script>
