<template>
  <main>
    <div class="activities h-100 w-100">
      <navbar title="Ordrar" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Ordernr</th>
                  <th scope="col">Tidpunkt</th>
                  <th scope="col">Företag</th>
                  <th scope="col">Säljare</th>
                  <th scope="col">Kontaktperson</th>
                  <th scope="col">Ordervärde</th>
                  <th scope="col" v-if="accessLevel == 'admin'">Fakturor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(r, index) in orders" :key="index">
                  <td>{{ r.dealID }}</td>
                  <td>{{ dateFormat(r.orderDate) }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'ShowCustomerDeal',
                        params: {
                          customerID: r.customerID,
                          personID: r.personID,
                          dealID: r.dealID,
                        },
                      }"
                      >{{ r.company }}</router-link
                    >
                  </td>
                  <td>{{ r.realname }}</td>
                  <td>{{ r.personName }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'ShowCustomerDeal',
                        params: {
                          customerID: r.customerID,
                          personID: r.personID,
                          dealID: r.dealID,
                        },
                      }"
                    >
                      {{ formatCurrency(r.total) }}
                    </router-link>
                  </td>
                  <td>
                    <ul>
                      <li
                        v-for="(d, i) in r.invoices"
                        :key="'inv' + i"
                        style="
                          display: inline;
                          list-style-type: none;
                          padding-right: 8px;
                        "
                      >
                        <a
                          target="_blank"
                          :href="
                            'https://mogul.tydalsystems.se/api/getInvoice.php?key=' +
                            apiKey +
                            '&invoiceID=' +
                            d
                          "
                          >{{ d }}
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td v-if="accessLevel == 'admin'">
                    <button
                      type="button"
                      v-if="!r.invoices.length"
                      style="width: 100px"
                      class="btn btn-secondary"
                      @click="createInvoice(r.dealID)"
                    >
                      <span v-if="!loading || currentDeal != r.dealID"
                        >Faktura</span
                      >
                      <pulse-loader
                        :loading="loading && currentDeal == r.dealID"
                      ></pulse-loader>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card my-2" v-if="loading">
            <div class="card-body">
              <pulse-loader :loading="loading" class="p-3"></pulse-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
import { sv } from "date-fns/locale";

export default {
  components: { Navbar, PulseLoader },
  name: "all-orders",
  data() {
    return {
      loading: false,
      locale: sv,
      modalTitle: "Flytta aktivitet",
      editable: false,
      selectedEvent: null,
      errors: [],
      currentDeal: null,
      date: null,
      disabled: true,
      time: null,
      lowerLimit: new Date(),
    };
  },
  computed: {
    ...mapState({
      apiKey: (state) => state.auth.apiKey,
      accessLevel: (state) => state.auth.accessLevel,
      baseUrl: "baseUrl",
      orders: (state) => state.order.confirmedOrders,
    }),
  },
  mounted() {
    this.checkRouteName();

    moment.locale("sv");
    document.title = "Ordrar";
    this.$store.dispatch("order/fetchConfirmedOrders");
  },
  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    resetForm() {
      this.date = null;
      this.time = null;
    },
    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "SEK",
      });
    },

    getDate() {
      return new Date(
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"
      );
    },

    createInvoice(dealID) {
      this.loading = true;
      this.currentDeal = dealID;
      this.$store
        .dispatch("order/createInvoice", { dealID: dealID })
        .then(() => {
          this.$store.dispatch("order/fetchConfirmedOrders").then(() => {
            this.loading = false;
          });
        });
    },
    dateFormat(d) {
      if (d == null) {
        return "";
      } else if (d.substring(11, 16) == "00:00") {
        return moment(d).format("D/M");
      } else {
        return moment(d).format("D/M - HH:mm");
      }
    },
    fixPhone(str) {
      let out = "";
      for (let i = 0; i < str.length; i++) {
        let c = str.substring(i, i + 1);
        if (
          c == "0" ||
          c == "1" ||
          c == "2" ||
          c == "3" ||
          c == "4" ||
          c == "5" ||
          c == "6" ||
          c == "7" ||
          c == "8" ||
          c == "9"
        ) {
          out += c;
        }
      }
      if (out.substring(0, 1) == "0") {
        out = "+46" + out.substring(1);
      }
      return out;
    },
  },
};
</script>

<style lang="scss" scoped>
.delayed {
  background-color: #ffcccc;
}
</style>
