<template>
  <main>
    <div class="activities h-100 w-100">
      <navbar title="Aktiviteter" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" @click="sortTable('eventTime')">
                    <span class="name">Tidpunkt</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'eventTime' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'eventTime' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col" @click="sortTable('realname')">
                    <span class="name">Säljare</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'realname' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'realname' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col" @click="sortTable('company')">
                    <span class="name">Företag</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'company' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'company' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col" @click="sortTable('personName')">
                    <span class="name">Kontaktperson</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'personName' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'personName' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col" @click="sortTable('eventType')">
                    <span class="name">Typ</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'eventType' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'eventType' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col" @click="sortTable('description')">
                    <span class="name">Beskrivning</span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'description' && !ascending"
                    >
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span
                      class="chevron"
                      v-if="sortColumn === 'description' && ascending"
                    >
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(r, index) in activities"
                  :key="index"
                  :class="{ delayed: new Date(r.eventTime) < getDate() }"
                >
                  <td>{{ dateFormat(r.eventTime) }}</td>
                  <td>{{ r.realname }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'ShowCustomerContact',
                        params: {
                          customerID: r.customerID,
                          personID: r.personID,
                        },
                      }"
                      >{{ r.company }}</router-link
                    >
                  </td>
                  <td>{{ r.personName }}</td>
                  <td>
                    {{
                      r.eventType == "phone"
                        ? "Telefon"
                        : r.eventType == "meeting"
                        ? "möte"
                        : r.eventType ?? ""
                    }}
                  </td>
                  <td>{{ r.description }}</td>
                  <td style="white-space: nowrap">
                    <button
                      type="button"
                      class="btn btn-default"
                      title="Markera aktiviteten som avslutad"
                      @click="finishActivity(r.eventID)"
                    >
                      <i class="bi bi-check-lg"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      title="Flytta aktiviteten till annan tidpunkt"
                      @click="moveActivity(r)"
                    >
                      <i class="bi bi-calendar-event-fill"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card my-2" v-if="loading">
            <div class="card-body">
              <pulse-loader :loading="loading" class="p-3"></pulse-loader>
            </div>
          </div>
        </div>
      </div>
      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p
            class="m-0 fst-italic"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
        <div class="row g-3">
          <div class="col-md-8">
            <datepicker
              class="form-control"
              placeholder="Välj datum"
              :locale="locale"
              :lower-limit="lowerLimit"
              v-model="date"
            />
          </div>
          <div class="col-md-4">
            <input
              type="time"
              class="form-control"
              placeholder="Välj tid"
              v-model="time"
            />
          </div>
          <div class="col-md-12">
            <div class="d-grid gap-2 mb-3">
              <button
                type="button"
                class="btn btn-secondary"
                @click="activity()"
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
import Modal from "@/components/Modal.vue";
import { sv } from "date-fns/locale";
import Datepicker from "vue3-datepicker";

export default {
  components: { Navbar, PulseLoader, Modal, Datepicker },
  name: "admin-activities",
  data() {
    return {
      loading: false,
      locale: sv,
      modalTitle: "Flytta aktivitet",
      editable: false,
      selectedEvent: null,
      errors: [],
      date: null,
      disabled: true,
      time: null,
      lowerLimit: new Date(),
      ascending: false,
      sortColumn: null,
      sort: {
        time: "asc",
        realname: "asc",
        company: "asc",
        contactPerson: "asc",
        type: "asc",
        description: "asc",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      activities: (state) => state.customer.activities,
    }),
  },

  mounted() {
    this.checkRouteName();

    moment.locale("sv");
    document.title = "Aktiviteter";
    this.$store.dispatch("customer/fetchActivities", {
      date: moment().format("YYYY-MM-DD"),
    });
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    resetForm() {
      this.date = null;
      this.time = null;
    },

    getDate() {
      return new Date(
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"
      );
    },

    clear() {
      this.resetForm();
      this.errors = [];
      this.modalTitle = "Flytta aktivitet";
      this.editable = false;
      this.disabled = true;
    },

    moveActivity(event) {
      this.editable = true;
      this.selectedEvent = event.eventID;
      this.date = new Date(event.eventTime);
      this.time = event.eventTime.substring(11, 16);
      this.modalTitle = "Flytta aktivitet";
      this.$refs.refModal.open();
    },

    finishActivity(eventID) {
      this.$store.dispatch("contact/finishActivity", eventID).then(() => {
        this.$store.dispatch("customer/fetchActivities", {
          date: moment().format("YYYY-MM-DD"),
        });
      });
    },

    activity() {
      this.$store
        .dispatch("contact/moveActivity", {
          eventID: this.selectedEvent,
          date: moment(this.date).format("YYYY-MM-DD"),
          time: this.time,
        })
        .then(() => {
          this.resetForm();
          this.$refs.refModal.close();

          this.$store.dispatch("customer/fetchActivities", {
            date: moment().format("YYYY-MM-DD"),
          });
        });
    },

    sortTable(col) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }

      let ascending = this.ascending;

      this.activities.sort((a, b) => {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },

    dateFormat(d) {
      if (d == null) {
        return "";
      } else if (d.substring(11, 16) == "00:00") {
        return moment(d).format("ddd D/M");
      } else {
        return moment(d).format("ddd D/M - HH:mm");
      }
    },

    fixPhone(str) {
      let out = "";
      for (let i = 0; i < str.length; i++) {
        let c = str.substring(i, i + 1);
        if (
          c == "0" ||
          c == "1" ||
          c == "2" ||
          c == "3" ||
          c == "4" ||
          c == "5" ||
          c == "6" ||
          c == "7" ||
          c == "8" ||
          c == "9"
        ) {
          out += c;
        }
      }
      if (out.substring(0, 1) == "0") {
        out = "+46" + out.substring(1);
      }
      return out;
    },
  },
};
</script>

<style lang="scss" scoped>
.activities {
  .table {
    th {
      cursor: pointer;
      &:hover {
        color: #2c3e50;
      }
      .chevron {
        margin-left: 3px;
        .bi {
          font-weight: 600;
        }
      }
    }
  }

  .delayed {
    background-color: #ffcccc;
  }
}
</style>
