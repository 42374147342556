import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import AdvertisingProduct from "../views/AdvertisingProduct.vue";
import ProductCategory from "../views/ProductCategory.vue";
import Companies from "../views/Companies.vue";
import Contacts from "@/views/Contacts.vue";
import ShowCustomer from "../views/ShowCustomer.vue";
import PayCheck from "../views/PayCheck.vue";
import Profile from "../views/Profile.vue";
import Users from "../views/Users.vue";
import BusinessOpportunity from "@/views/BusinessOpportunity.vue";
import Issue from "@/views/Issue.vue";
import Regions from "@/views/Regions.vue";
import Budget from "@/views/Budget.vue";
import Activities from "@/views/Activities.vue";
import Pipeline from "@/views/Pipeline.vue";
import Calendar from "@/views/Calendar.vue";
import Aftersales from "@/views/Aftersales.vue";
import AftersalesUsers from "@/views/AftersalesUsers.vue";
import AftersalesOther from "@/views/AftersalesOther.vue";
import Branches from "@/views/Branches.vue";
import Magazines from "@/views/Magazines.vue";
import Login from "@/views/Login.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Orders from "@/views/Orders.vue";
import DealContent from "@/views/DealContent.vue";
import TvScreen from "@/views/TvScreen.vue";
import store from "@/store/index";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/affarsmojlighetsfaser",
    name: "BusinessOpportunity",
    component: BusinessOpportunity,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/aftersales",
    name: "Aftersales",
    component: Aftersales,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/aftersales/users",
    name: "AftersalesUsers",
    component: AftersalesUsers,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Aftersales" },
  },
  {
    path: "/aftersales/other",
    name: "AftersalesOther",
    component: AftersalesOther,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Aftersales" },
  },
  {
    path: "/saljare",
    name: "Saljare",
    component: Users,
    meta: { requiresAuth: true, roles: ["admin", "externmanager"] },
  },
  {
    path: "/kalender",
    name: "Calendar",
    component: Calendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/aktiviteter",
    name: "Activities",
    component: Activities,
    meta: { requiresAuth: true, roles: ["admin", "externmanager"] },
  },
  {
    path: "/pipeline",
    name: "Pipeline",
    component: Pipeline,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/annons-produkt",
    name: "AdvertisingProduct",
    component: AdvertisingProduct,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/produkt-kategori",
    name: "ProductCategory",
    component: ProductCategory,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/budget",
    name: "Budget",
    component: Budget,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/regioner",
    name: "Regions",
    component: Regions,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/tidningar/:magazineID/utgavor/:selectedYear",
    name: "Issue",
    component: Issue,
    props: true,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/bransch",
    name: "Branches",
    component: Branches,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/tidningar",
    name: "Magazines",
    component: Magazines,
    meta: { requiresAuth: true, roles: ["admin"], parent: "Settings" },
  },
  {
    path: "/foretag",
    name: "Companies",
    component: Companies,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/foretag/saljare/:userID",
    name: "UserCompanies",
    component: Companies,
    meta: { reload: true, requiresAuth: true, parent: "Companies" },
    props: true,
  },
  {
    path: "/foretag/saljare/:userID/:regionID?",
    name: "UserRegionCompanies",
    component: Companies,
    meta: { reload: true, requiresAuth: true, parent: "Companies" },
    props: true,
  },
  {
    path: "/kontakter",
    name: "Contacts",
    component: Contacts,
    meta: { requiresAuth: true },
  },
  {
    path: "/foretag/:customerID",
    name: "ShowCustomer",
    component: ShowCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: "/foretag/:customerID/:personID",
    name: "ShowCustomerContact",
    component: ShowCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: "/foretag/:customerID/:personID/:dealID",
    name: "ShowCustomerDeal",
    component: ShowCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: "/lonesnurra",
    name: "PayCheck",
    component: PayCheck,
    meta: { requiresAuth: true },
  },
  {
    path: "/profil",
    name: "UserProfile",
    component: Profile,
    meta: { requiresAuth: true, parent: "Settings", }
  },
  {
    path: "/anvandare",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      roles: ["admin", "externmanager"],
      parent: "Settings",
    },
  },
  {
    path: "/ordrar",
    name: "Orders",
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: "/order/:dealID",
    name: "DealContent",
    component: DealContent,
    meta: { requiresAuth: true },
  },
  {
    path: "/tv-skarm",
    name: "TvScreen",
    component: TvScreen,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authenticated = store.state.auth.isAuthenticated;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authenticated) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else if (to.name === "Login" && authenticated) {
      next({ name: "Home" });
    } else if (to.meta.roles) {
      if (to.meta.roles.includes(store.state.auth.accessLevel)) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      window.onpopstate = function () {
        if (to.meta.reload) {
          location.reload();
        }
      };
      next();
    }
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   const authenticated = store.state.auth.isAuthenticated;
//   if (to.name !== "Login" && !authenticated) {
//     next({ name: "Login" });
//   } else if (to.name === "Login" && authenticated) {
//     next({ name: "Home" });
//   } else {
//     next();
//   }
// });

export default router;
