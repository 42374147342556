<template>
  <main>
    <div class="show-customer w-100">
      <navbar title="Företag" />
      <div class="content p-3">
        <div class="row">
          <div class="col-md-5">
            <div class="card mb-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-10">
                    <h3 class="title" @click="editCompany()">
                      {{ customer.company }}
                    </h3>
                  </div>
                  <div
                    v-if="customer.score"
                    class="col-md-2"
                    :style="
                      'text-align: right; color: ' +
                      getScoreColor(customer.score)
                    "
                  >
                    <i class="bi" :class="getScoreIcon(customer.score)"></i>
                  </div>
                </div>
                <p>
                  ({{ customer.customerNr }} |
                  <a
                    v-if="customer.orgnr"
                    target="_blank"
                    :href="'https://www.allabolag.se/' + customer.orgnr"
                    >{{ customer.orgnr }}</a
                  >
                  |
                  <a target="_blank" :href="customer.website">{{
                    customer.website
                  }}</a
                  >)
                </p>
                <select
                  v-if="accessLevel != 'extern'"
                  v-model="userID"
                  :class="{ selected: customerSaving, 'form-select': true }"
                  @change="assign()"
                >
                  <option value="-1">Ingen tilldelad säljare</option>
                  <option
                    v-for="(r, index) in users"
                    :key="index"
                    :value="r.userID"
                  >
                    {{ r.realname }}
                  </option>
                </select>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-striped"
                  v-if="
                    customer != null &&
                    customer.contacts != null &&
                    customer.contacts.length > 0
                  "
                >
                  <thead>
                    <tr>
                      <th scope="col">Kontakter</th>
                      <th scope="col">Titel</th>
                      <th scope="col">Telefon</th>
                      <th scope="col" colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(contact, index) in customer.contacts"
                      :key="index"
                      :class="{ selectedOrder: contact.personID == personID }"
                    >
                      <td @click="setContact(contact.personID)">
                        {{ contact.personName }}
                      </td>
                      <td @click="setContact(contact.personID)">
                        {{ contact.title }}
                      </td>
                      <td>
                        <a
                          v-if="contact.mobile"
                          :href="'tel:' + fixPhone(contact.mobile)"
                          >{{ contact.mobile }}</a
                        ><br v-if="contact.mobile && contact.phone" />
                        <a
                          v-if="contact.phone"
                          :href="'tel:' + fixPhone(contact.phone)"
                          >{{ contact.phone }}</a
                        >
                      </td>
                      <td>
                        <a :href="'mailto:' + contact.email">
                          <i class="bi bi-envelope"></i>
                        </a>
                      </td>
                      <td>
                        <a
                          href="#"
                          title="Redigera kontaktperson"
                          @click="editContact(contact)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info" v-else>
                  <p class="m-0 fst-italic">Inga kontakter inlagda</p>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="showContactForm = !showContactForm"
                >
                  <i
                    class="bi bi-arrow-down-circle-fill"
                    v-if="!showContactForm"
                  ></i
                  ><i
                    class="bi bi-arrow-up-circle-fill"
                    v-if="showContactForm"
                  ></i>
                  {{ showContactForm ? "Stäng" : "Ny kontakt" }}
                </button>
                <contact-form
                  :editContact="editedContact"
                  @contact="saveContact"
                  @hideContact="hideContact"
                  v-if="showContactForm"
                  :key="updateContactKey"
                />
              </div>
            </div>
            <div class="card mt-2">
              <div class="card-body">
                <label class="form-label" for="note">Ny händelse</label>
                <textarea
                  id="note"
                  class="form-control"
                  v-model="note"
                  rows="2"
                ></textarea>
                <div class="form-check form-switch my-2">
                  <label for="event">Skapa ny kalenderbokning</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="event"
                    v-model="showEvent"
                  />
                </div>
                <div v-if="showEvent">
                  <div class="mb-3">
                    <select class="form-select" v-model="personID">
                      <option :value="null">Välj kontaktperson</option>
                      <option
                        :value="contact.personID"
                        v-for="(contact, index) in customer.contacts"
                        :key="index"
                      >
                        {{ contact.personName }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="eventType"
                            id="phone"
                            value="phone"
                            v-model="eventType"
                          />
                          <label class="form-check-label" for="phone">
                            Telefon
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="eventType"
                            id="mail"
                            value="mail"
                            v-model="eventType"
                          />
                          <label class="form-check-label" for="mail">
                            E-post
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="eventType"
                            id="meeting"
                            value="meeting"
                            v-model="eventType"
                          />
                          <label class="form-check-label" for="meeting">
                            Möte
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <input
                      type="text"
                      v-if="eventType == 'meeting'"
                      class="form-control"
                      placeholder="Plats"
                      v-model="place"
                    />
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <datepicker
                        class="form-control"
                        placeholder="Välj datum"
                        :locale="locale"
                        :lower-limit="lowerLimit"
                        v-model="eventTime"
                      />
                    </div>
                    <div class="col">
                      <!-- <vue-timepicker format="HH:mm:ss"></vue-timepicker> -->
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Plats"
                        v-model="selectedTime"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="saveNote"
                >
                  Spara
                </button>
              </div>
            </div>
            <div
              class="card mt-2"
              v-if="
                customer != null &&
                customer.activities != null &&
                customer.activities.length > 0
              "
            >
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2">Pågående aktiviteter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(note, index) in customer.activities"
                      :key="'act' + index"
                    >
                      <td>
                        {{ fixDateTime(note.eventTime) }}
                        {{ note.realname != username ? note.realname : "" }}
                      </td>
                      <td>
                        {{ note.description }} ({{ fixDateTime(note.ts) }})
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-default"
                          title="Markera aktiviteten som avslutad"
                          @click="finishActivity(note.eventID)"
                        >
                          <i class="bi bi-check-lg"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          title="Flytta aktiviteten till annan tidpunkt"
                          @click="moveActivity(note)"
                        >
                          <i class="bi bi-calendar-event-fill"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="card mt-2"
              style="height: 500px; overflow: auto"
              v-if="
                customer != null &&
                customer.notes != null &&
                customer.notes.length > 0
              "
            >
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2">Historik</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(note, index) in customer.notes" :key="index">
                      <td>
                        {{ fixDate(note.ts) }}
                        {{ note.realname != username ? note.realname : "" }}
                      </td>
                      <td>
                        {{
                          note.note
                            ? note.note
                            : note.description
                            ? note.description
                            : note.notes
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <!-- <pre>{{ customer.deals }}</pre> -->
            <div class="card mb-2">
              <div class="card-body">
                <strong>Region:</strong>
                {{ customer != null ? customer.region : "" }}
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body" style="height: 340px; overflow: auto">
                <div class="d-flex justify-content-between">
                  <div>
                    <strong>
                      Total försäljning:
                      {{
                        totalSales != null ? formatCurrencySEK(totalSales) : ""
                      }}
                    </strong>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="addDeal()"
                    >
                      <i class="bi bi-plus-circle-fill"></i> Ny offert
                    </button>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Påbörjad</th>
                      <th scope="col">Orderrader</th>
                      <th scope="col">Belopp</th>
                      <th scope="col">Utgåvor</th>
                      <th scope="col">Status</th>
                      <th scope="col" style="text-align: right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(deal, index) in customer.deals"
                      :key="index"
                      :class="{ selectedOrder: deal.dealID == selectedOrder }"
                    >
                      <td @click="openDeal(deal.dealID, deal)">
                        {{ deal.dealID }}
                      </td>
                      <td @click="openDeal(deal.dealID, deal)">
                        <strong>{{ deal.created.substring(0, 10) }}</strong>
                      </td>
                      <td @click="openDeal(deal.dealID, deal)">
                        {{ deal.dealContentRows }}
                      </td>
                      <td @click="openDeal(deal.dealID, deal)">
                        {{ formatCurrencySEK(parseFloat(deal.total)) }}
                      </td>
                      <td @click="openDeal(deal.dealID, deal)">
                        {{ deal.issues }}
                      </td>
                      <td @click="openDeal(deal.dealID, deal)">
                        <span
                          class="badge bg-dark"
                          v-if="deal.status == 'opportunity'"
                          >utkast</span
                        >
                        <span
                          class="badge bg-primary"
                          v-if="deal.status == 'offer'"
                          >offert</span
                        >
                        <span
                          class="badge bg-success"
                          v-if="deal.status == 'confirmed'"
                          >order</span
                        >
                        <span
                          class="badge bg-danger"
                          v-if="deal.status == 'rejected'"
                          >avvisad</span
                        >
                      </td>
                      <td class="actions">
                        <button
                          type="button"
                          v-if="
                            deal.status == 'opportunity' ||
                            accessLevel == 'admin'
                          "
                          class="btn btn-default"
                          @click="removeOrder(deal.dealID)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card mb-2" v-if="false">
              <div class="card-body">
                <label for="info" class="form-label">Övrigt</label>
                <textarea
                  class="form-control"
                  id="info"
                  rows="5"
                  v-model="customer.comment"
                ></textarea>
                <button @click="saveComment()">Spara</button>
              </div>
            </div>
            <div class="card mb-2" v-if="selectedOrder && currentDeal">
              <div class="card-body">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-if="
                    currentDeal.status == 'opportunity' ||
                    currentDeal.status == 'offer' ||
                    accessLevel == 'admin'
                  "
                  @click="showOrderForm = !showOrderForm"
                >
                  <i
                    class="bi bi-arrow-down-circle-fill"
                    v-if="!showOrderForm"
                  ></i
                  ><i
                    class="bi bi-arrow-up-circle-fill"
                    v-if="showOrderForm"
                  ></i>
                  {{ showOrderForm ? "Stäng" : "Ny rad" }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  style="margin-left: 8px"
                  @click="createOffer()"
                  v-if="currentDeal.status == 'opportunity'"
                >
                  Skapa offert
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  style="margin-left: 8px"
                  @click="createOrder()"
                  v-if="currentDeal.status == 'offer'"
                >
                  Skapa order
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  style="margin-left: 8px"
                  @click="denyOffer()"
                  v-if="currentDeal.status == 'offer'"
                >
                  Avvisa
                </button>
                <a
                  :href="
                    'https://mogul.tydalsystems.se/api/order.php?dealID=' +
                    selectedOrder +
                    '&key=' +
                    apiKey
                  "
                  target="_blank"
                  v-if="currentDeal.status == 'confirmed'"
                  >Orderbekräftelse</a
                >
                <a
                  :href="
                    'https://mogul.tydalsystems.se/api/offert.php?dealID=' +
                    selectedOrder +
                    '&key=' +
                    apiKey
                  "
                  target="_blank"
                  style="margin-left: 8px"
                  v-if="currentDeal.status == 'offer'"
                  >Offert</a
                >
                <div class="row mt-3 align-items-center">
                  <div class="col-md-3" v-if="accessLevel == 'admin'">
                    <p>Vår referens:</p>
                  </div>
                  <div class="col-md-6" v-if="accessLevel == 'admin'">
                    <select
                      v-model="orderUserID"
                      :class="{ selected: orderSaving, 'form-select': true }"
                      @change="assignOrder()"
                    >
                      <option
                        v-for="(r, index) in users"
                        :key="index"
                        :selected="
                          currentDeal != null && currentDeal.userID == r.userID
                        "
                        :value="r.userID"
                      >
                        {{ r.realname }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <select v-model="currency" class="form-select">
                      <option v-for="(r, index) in currencies" :key="index">
                        {{ r }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3 align-items-center">
                  <div class="col-md-3">
                    <p>Intern info:</p>
                  </div>
                  <div class="col-md-6">
                    <textarea
                      v-model="orderInfo"
                      rows="2"
                      class="form-control"
                    ></textarea>
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="updateInternal()"
                    >
                      Spara
                    </button>
                  </div>
                </div>
                <div class="row mt-3 align-items-center" v-if="handledByAgency">
                  <div class="col-md-3">
                    <p>Provision: (%)</p>
                  </div>
                  <div class="col-md-6">
                    <input type="number" class="form-control" v-model="proc" />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="addFee()"
                    >
                      Lägg till
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2" v-if="selectedOrder && showOrderForm">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-md-3">Kategori</div>
                  <div class="col-md-6">
                    <select
                      class="form-select"
                      v-model="pCategoryID"
                      @change="resetQuantity()"
                    >
                      <option
                        v-for="(r, index) in productCategories"
                        :key="index"
                        :value="r.categoryID"
                      >
                        {{ r.category }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="col-md-3"
                    v-if="
                      pCategoryID == 1 ||
                      (pCategoryID == 6 && issueOrDate) ||
                      pCategoryID == 7 ||
                      pCategoryID == 8 ||
                      pCategoryID == 10
                    "
                  >
                    <select
                      class="form-select"
                      v-model="pIssueID"
                      v-if="pCategoryID == 10"
                    >
                      <option
                        v-for="(r, index) in digitalIssues"
                        :key="index"
                        :value="r.issueID"
                      >
                        #{{ r.issueName }} -
                        {{ formatIssueDate(r.publishingDate) }}
                      </option>
                    </select>
                    <select class="form-select" v-model="pIssueID" v-else>
                      <option
                        v-for="(r, index) in printIssues"
                        :key="index"
                        :value="r.issueID"
                      >
                        #{{ r.issueName }} -
                        {{ formatIssueDate(r.publishingDate) }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3" v-else>
                    <datepicker
                      class="form-control"
                      placeholder="Startdatum"
                      v-model="startDate"
                      :locale="locale"
                    />
                  </div>
                </div>
                <div class="row align-items-center" v-if="pCategoryID == 1">
                  <div class="col-md-3">Region</div>
                  <div class="col-md-6">
                    <select
                      class="form-select"
                      v-model="pRegionID"
                      @change="updatePricelist()"
                    >
                      <option
                        v-for="(r, index) in customerRegions"
                        :key="index"
                        :value="r.regionID"
                      >
                        {{ r.region }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-3">Produkt</div>
                  <div class="col-md-6">
                    <select
                      class="form-select"
                      v-model="pProductID"
                      @change="updateListPrice()"
                    >
                      <option
                        v-for="(r, index) in pricelist"
                        :key="index"
                        :value="r.productID"
                      >
                        {{ r.productName }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3" v-if="pCategoryID == 6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="issueOrDate"
                        id="issueOrDate"
                      />
                      <label class="form-check-label" for="issueOrDate"
                        >Utgåva</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-3">Antal</div>
                  <div class="col-md-6">
                    <input
                      type="number"
                      v-model="pQuantity"
                      @keyup="calcTotal()"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">
                    à
                    {{ formatCurrency(listPrice) }}
                  </div>
                </div>
                <div class="row align-items-center" v-if="!noListPrice">
                  <div class="col-md-3">Rabatt</div>
                  <div class="col-md-6">
                    <input
                      type="number"
                      v-model="procDiscount"
                      @keyup="calcProc()"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">%</div>
                </div>
                <div class="row align-items-center" v-else>
                  <div class="col-md-3">Styckpris</div>
                  <div class="col-md-6">
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      v-model="listPrice"
                      @keyup="calcTotal()"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">kr</div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-3">Nettopris</div>
                  <div class="col-md-6">
                    <input
                      type="number"
                      :disabled="noListPrice"
                      v-model="pTotal"
                      @keyup="calcDiscount()"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      :disabled="dealSaving || !completedOrderRow"
                      class="btn btn-primary"
                      @click="addDealContent()"
                    >
                      Spara
                    </button>
                    <button
                      style="margin-left: 8px"
                      v-if="selectedRow"
                      type="button"
                      class="btn btn-secondary"
                      @click="cancelRow()"
                    >
                      Avbryt
                    </button>
                  </div>
                </div>
                <div
                  class="row align-items-center"
                  v-if="currency != 'SEK'"
                  style="padding-top: 12px"
                >
                  <div class="col-md-3">SEK</div>
                  <div class="col-md-6">
                    {{
                      getRate(pTotal).toLocaleString("sv-SE", {
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        currency: "SEK",
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2" v-if="selectedOrder">
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Antal</th>
                      <th scope="col">Produkt</th>
                      <th scope="col">Period</th>
                      <th scope="col">Region</th>
                      <th scope="col">A-pris</th>
                      <th scope="col">Rabatt</th>
                      <th scope="col">Nettopris</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="dealContent != null && dealContent.length > 0">
                    <tr
                      v-for="(r, index) in dealContent"
                      :key="index"
                      :class="{ selected: r.rowID == selectedRow }"
                    >
                      <td @click="fillContentForm(r)">{{ r.quantity }}</td>
                      <td @click="fillContentForm(r)">{{ r.productName }}</td>
                      <td @click="fillContentForm(r)">{{ r.issueName }}</td>
                      <td @click="fillContentForm(r)">
                        {{ r.categoryID == 1 ? r.region : "" }}
                      </td>
                      <td @click="fillContentForm(r)">
                        {{ formatCurrency(r.price) }}
                      </td>
                      <td @click="fillContentForm(r)">
                        {{
                          Math.round(
                            (100 * r.discount) / (r.price * r.quantity)
                          )
                        }}%
                      </td>
                      <td @click="fillContentForm(r)">
                        {{ formatCurrency(r.price * r.quantity - r.discount) }}
                      </td>
                      <td class="actions">
                        <button
                          v-if="
                            status == 'opportunity' ||
                            status == 'offer' ||
                            accessLevel == 'admin'
                          "
                          type="button"
                          class="btn btn-default"
                          @click="removeDealContent(r)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <td colspan="4">Summa:</td>
                    <td>{{ formatCurrency(sumPrice) }}</td>
                    <td>{{ formatCurrency(sumDiscount) }}</td>
                    <td>{{ formatCurrency(sumNet) }}</td>
                  </tfoot>
                </table>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="showTemporaryProduct()"
                  v-if="accessLevel == 'admin'"
                >
                  Ny produkt
                </button>
              </div>
            </div>
            <div class="card header-box">
              <div class="card-body">
                <div class="d-flex justify-content-end">
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="address"
                      id="address"
                      value="address"
                      v-model="selected"
                    />
                    <label class="form-check-label mt-1 ms-2" for="address">
                      Adresser
                    </label>
                  </div>
                  <span class="d-flex align-items-center mx-4">|</span>
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="agency"
                      id="agency"
                      value="agency"
                      v-model="selected"
                    />
                    <label class="form-check-label mt-1 ms-2" for="agency">
                      Kopplingar
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card agency" v-if="selected === 'agency'">
              <div class="card-body">
                <table
                  class="table table-striped"
                  v-if="customer.agencies.length > 0"
                >
                  <thead>
                    <tr>
                      <th scope="col">Företag</th>
                      <th scope="col">Organisationsnummer</th>
                      <th scope="col">E-postadress</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(agency, index) in customer.agencies"
                      :key="index"
                    >
                      <td>{{ agency.company }}</td>
                      <td>{{ agency.orgnr }}</td>
                      <td>{{ agency.email }}</td>
                      <td>
                        <span class="unlink" @click="unlink(agency)"
                          ><i class="bi bi-trash"></i
                        ></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info" v-else>
                  <p class="m-0 fst-italic">Ingen mediabyrå kopplad.</p>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="showConnectionForm()"
                >
                  <i
                    class="bi bi-arrow-down-circle-fill"
                    v-if="!showAgencyForm"
                  ></i
                  ><i
                    class="bi bi-arrow-up-circle-fill"
                    v-if="showAgencyForm"
                  ></i>
                  {{ showAgencyForm ? "Stäng" : "Ny mediabyrå" }}
                </button>
                <div v-if="showAgencyForm" class="mt-3">
                  <p>Välj mediabyrå</p>
                  <select
                    class="form-select"
                    aria-label="Välj mediabyrå"
                    v-model="agency"
                    @change="selectedMedia"
                  >
                    <option :value="null">---</option>
                    <option
                      :value="agency"
                      v-for="(agency, index) in agencies"
                      :key="index"
                    >
                      {{ agency.company }}
                    </option>
                  </select>
                </div>
                <div v-if="showAgencyForm && agency" class="mt-3">
                  <label for="contact" class="form-label"
                    >Välj kontaktperson</label
                  >
                  <select class="form-select" id="contact" v-model="contact">
                    <option :value="null">Välj kontaktperson</option>
                    <option
                      :value="contact"
                      v-for="(contact, index) in contacts"
                      :key="index"
                    >
                      {{ contact.personName }}
                    </option>
                  </select>
                </div>
                <div class="mt-3" v-if="showAgencyForm && agency">
                  <datepicker
                    class="form-control"
                    placeholder="Välj startdatum"
                    :locale="locale"
                    v-model="startDate"
                  />
                </div>
                <div v-if="showAgencyForm" class="d-grid gap-2 mt-3">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    :disabled="!contact"
                    @click="connectMedia"
                  >
                    Koppla
                  </button>
                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="errorMessage"
                  >
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card address" v-if="selected === 'address'">
              <div class="card-body">
                <table class="table table-striped" v-if="addresses.length > 0">
                  <thead>
                    <tr>
                      <th scope="col">Typ</th>
                      <th scope="col">Gatuadress</th>
                      <th scope="col">Postnr</th>
                      <th scope="col">Ort</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(address, index) in addresses" :key="index">
                      <td>
                        {{
                          address.addressType === "visiting"
                            ? "Besöksadress"
                            : address.addressType == "delivery"
                            ? "Leveransadress"
                            : address.addressType == "post"
                            ? "Postadress"
                            : "Fakturaadress"
                        }}
                        <i
                          class="bi bi-info-circle-fill"
                          v-if="address.comment"
                          :title="address.comment"
                        ></i>
                      </td>
                      <td>{{ address.streetAddress }}</td>
                      <td>{{ address.zipcode }}</td>
                      <td>{{ address.city }}</td>
                      <td class="actions">
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="deleteAddress(address.addressID)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info" v-else>
                  <p class="m-0 fst-italic">Inga adresser inlagda</p>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="showAddressForm = !showAddressForm"
                >
                  <i
                    class="bi bi-arrow-down-circle-fill"
                    v-if="!showAddressForm"
                  ></i
                  ><i
                    class="bi bi-arrow-up-circle-fill"
                    v-if="showAddressForm"
                  ></i>
                  {{ showAddressForm ? "Stäng" : "Ny adress" }}
                </button>
                <address-form @address="saveAddress" v-if="showAddressForm" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal ref="refProduct" title="Lägg till ny produkt" @hidden="clear()">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p
            class="m-0 fst-italic"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
        <div class="row g-3">
          <div class="col-md-12">
            <input
              type="text"
              class="form-control"
              v-model="tpProductName"
              placeholder="Produktnamn"
            />
          </div>
        </div>
        <div class="row g-3 mt-2">
          <select v-model="tpCategoryID" class="form-select">
            <option value="0">Välj produktkategori</option>
            <option
              v-for="(r, index) in productCategories"
              :key="index"
              :value="r.categoryID"
            >
              {{ r.category }}
            </option>
          </select>
        </div>
        <div class="row g-3 mt-2 align-items-center">
          <div class="col-md-2">
            <p>Antal:</p>
          </div>
          <div class="col-md-4">
            <input type="number" class="form-control" v-model="tpQuantity" />
          </div>
          <div class="col-md-2">
            <p>Pris:</p>
          </div>
          <div class="col-md-4">
            <input type="number" class="form-control" v-model="tpPrice" />
          </div>
        </div>
        <div class="row g-3 mt-2">
          <div class="col-md-6">
            <datepicker
              class="form-control"
              placeholder="Välj startdatum"
              :locale="locale"
              v-model="tpStartDate"
            />
          </div>
          <div class="col-md-6">
            <datepicker
              class="form-control"
              placeholder="Välj slutdatum"
              :locale="locale"
              :lower-limit="lowerLimit"
              v-model="tpEndDate"
            />
          </div>
        </div>
        <div class="row g-3 mt-2">
          <button
            type="button"
            class="btn btn-primary"
            @click="addTemporaryProduct()"
          >
            Spara
          </button>
        </div>
      </modal>
      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p
            class="m-0 fst-italic"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
        <div class="row g-3">
          <div class="col-md-8">
            <datepicker
              class="form-control"
              placeholder="Välj datum"
              :locale="locale"
              :lower-limit="lowerLimit"
              v-model="date"
            />
          </div>
          <div class="col-md-4">
            <input
              type="time"
              class="form-control"
              placeholder="Välj tid"
              v-model="time"
            />
          </div>
          <div class="col-md-12">
            <div class="d-grid gap-2 mb-3">
              <button
                type="button"
                class="btn btn-secondary"
                @click="activity()"
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </modal>
    </div>

    <modal ref="refEditCompany" title="Redigera företaget" @hidden="clear()">
      <div class="row g-3 mb-3">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p
            class="m-0 fst-italic"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
        <fieldset class="border p-2 rounded">
          <legend class="fs-5">Grunduppgifter</legend>
          <div class="row g-3 mb-3">
            <div class="col-md-4">
              <label for="company" class="form-label">Företagsnamn</label>
              <input
                type="text"
                class="form-control"
                id="company"
                v-model="company.name"
              />
            </div>
            <div class="col-md-4">
              <label for="orgnr" class="form-label">Organisationsnr</label>
              <input
                type="text"
                class="form-control"
                placeholder="Tio siffror"
                id="orgnr"
                v-model="company.orgnr"
              />
            </div>
            <div class="col-md-4">
              <label for="vatnr" class="form-label">VAT</label>
              <input
                type="text"
                class="form-control"
                id="vatnr"
                v-model="company.vatnr"
              />
            </div>
            <div class="col-md-4">
              <label>Typ av kund</label>
              <div class="form-check form-switch" style="margin-top: 16px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  v-model="company.customerType"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked"
                  >Mediabyrå</label
                >
              </div>
            </div>
            <div class="col-md-4">
              <label for="score" class="form-label">Rating</label>
              <select id="score" class="form-select" v-model="company.score">
                <option :value="null">---</option>
                <option value="4">Platina</option>
                <option value="1">Guld</option>
                <option value="2">Silver</option>
                <option value="3">Brons</option>
                <option value="5">Prospect</option>
                <option value="6">Inaktiv</option>
              </select>
            </div>
          </div>
        </fieldset>
        <fieldset class="border p-3 rounded">
          <div class="row g-3 mb-3">
            <legend class="fs-5">Kontaktuppgifter</legend>
            <div class="col-md-4">
              <label for="email" class="form-label">E-postadress</label>
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="company.email"
              />
            </div>
            <div class="col-md-4">
              <label for="billing-email" class="form-label"
                >Fakturaadress</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="E-post"
                id="billing-email"
                v-model="company.billingEmail"
              />
            </div>
            <div class="col-md-4">
              <label for="website" class="form-label">Hemsida</label>
              <input
                type="text"
                class="form-control"
                id="website"
                v-model="company.website"
              />
            </div>
          </div>
        </fieldset>
        <fieldset class="border p-3 rounded">
          <div class="row g-3 mb-3">
            <legend class="fs-5">Övrigt om företaget</legend>
            <div class="col-md-4">
              <label for="payment-terms" class="form-label"
                >Betalningsvillkor</label
              >
              <input
                type="text"
                class="form-control"
                id="payment-terms"
                v-model="company.paymentTerms"
              />
            </div>
            <div class="col-md-4">
              <label for="category" class="form-label">Välj bransch</label>
              <select
                id="category"
                class="form-select"
                v-model="company.category"
              >
                <option :value="null">---</option>
                <option
                  :value="category"
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  {{ category.category }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="region" class="form-label">Välj region</label>
              <select id="region" class="form-select" v-model="company.region">
                <option :value="null">---</option>
                <option
                  :value="region"
                  v-for="(region, index) in regions"
                  :key="index"
                >
                  {{ region.region }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <label for="comment" class="form-label">Kommentar</label>
              <textarea
                class="form-control"
                id="comment"
                rows="3"
                v-model="company.comment"
              ></textarea>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="d-grid gap-2 mb-3">
        <button
          type="button"
          class="btn btn-secondary fw-bold"
          @click="updateCompany()"
        >
          Uppdatera
        </button>
      </div>
    </modal>
  </main>
</template>

<script>
import data from "@/assets/countries.json";
import Navbar from "../components/Navbar.vue";
import ContactForm from "@/components/ContactForm.vue";
import AddressForm from "@/components/AddressForm.vue";
import moment from "moment";
import Modal from "@/components/Modal.vue";
import { mapState } from "vuex";
import Datepicker from "vue3-datepicker";
import { ref } from "vue";
import { sv } from "date-fns/locale";
export default {
  name: "ShowCustomer",
  data() {
    return {
      countries: data,
      customerID: null,
      comment: null,
      completedOrderRow: false,
      dealSaving: false,
      orderSaving: false,
      currentDeal: null,
      selected: "address",
      showContactForm: false,
      showAgencyForm: false,
      showAddressForm: false,
      showOrderForm: false,
      proc: 4,
      tpProductName: null,
      tpCategoryID: 0,
      tpPrice: 0,
      tpQuantity: 1,
      tpStartDate: null,
      tpEndDate: null,
      agency: null,
      note: null,
      updateContactKey: 1,
      errors: [],
      listPrice: 0,
      discount: 0,
      date: null,
      time: null,
      pTotal: 0,
      totalSales: null,
      sumPrice: 0,
      sumDiscount: 0,
      sumNet: 0,
      selectedRow: null,
      selectedOrder: null,
      personID: null,
      orderUserID: null,
      procDiscount: 0,
      userID: null,
      editedContact: {
        personName: null,
        title: null,
        email: null,
        mobile: null,
        phone: null,
        comment: null,
      },
      issueOrDate: null,
      eventTime: null,
      eventType: "phone",
      place: null,
      orderInfo: null,
      contact: null,
      customerSaving: false,
      pQuantity: 1,
      pRegionID: null,
      pProductID: null,
      pIssueID: null,
      pCategoryID: null,
      currency: "SEK",
      currencies: ["SEK", "DKK", "EUR", "NOK"],
      handledByAgency: false,
      showEvent: true,
      startDate: new Date(),
      endDate: "9999-12-31",
      locale: sv,
      lowerLimit: ref(new Date()),
      errorMessage: null,
      selectedTime: null,
      modalTitle: null,
      editable: false,
      noListPrice: false,
      status: null,
      company: {
        name: null,
        orgnr: null,
        vatnr: null,
        score: null,
        customerType: false,
        email: null,
        billingEmail: null,
        website: null,
        paymentTerms: null,
        category: null,
        region: null,
        comment: null,
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      username: (state) => state.auth.realname,
      customer: (state) => state.customer.currentCustomer,
      customerRegions: (state) => state.region.customerRegions,
      productCategories: (state) => state.product.productCategories,
      agencies: (state) => state.agency.agencies,
      addresses: (state) => state.address.addresses,
      contacts: (state) => state.contact.contacts,
      issues: (state) => state.issue.issues,
      currencyRates: (state) => state.order.currencyRates,
      notes: (state) => state.customer.notes,
      accessLevel: (state) => state.auth.accessLevel,
      users: (state) => state.user.users,
      pricelist: (state) => state.product.pricelist,
      dealContent: (state) => state.order.dealContent,
      categories: (state) => state.category.categories,
      regions: (state) => state.region.regions,
    }),
    printIssues() {
      return this.issues.filter((issue) => issue.magazineID == 1);
    },
    digitalIssues() {
      return this.issues.filter((issue) => issue.magazineID == 5);
    },
  },

  components: {
    Navbar,
    ContactForm,
    AddressForm,
    Datepicker,
    Modal,
  },

  mounted() {
    this.customerID = parseInt(this.$route.params.customerID);
    this.personID = parseInt(this.$route.params.personID);
    this.selectedOrder = this.$route.params.dealID ?? null;
    this.$store.dispatch("order/fetchCurrencies");

    this.$store.dispatch("customer/fetchCustomer", this.customerID).then(() => {
      document.title = this.customer.company;
      if (this.customer.activities && this.customer.activities.length > 0) {
        this.showEvent = false;
      }
      this.userID = this.customer.userID;
      if (this.userID == null) {
        this.userID = -1;
      }
      if (!this.personID && this.customer.activities.length > 0) {
        this.personID = this.customer.activities[0].personID;
        this.contact = this.customer.contacts.find(
          (contact) => parseInt(contact.personID) === this.personID
        );
      } else if (!this.personID && this.customer.contacts.length > 0) {
        this.contact = this.customer.contacts[0];
        this.personID = this.contact.personID;
      } else if (this.personID) {
        this.contact = this.customer.contacts.find(
          (contact) => parseInt(contact.personID) === this.personID
        );
      }
      this.totalSales = 0;
      for (let i = 0; i < this.customer.deals.length; i++) {
        if (
          this.customer.deals[i].status == "confirmed" &&
          this.customer.deals[i].dealContentRows > 0
        ) {
          this.totalSales += parseFloat(this.customer.deals[i].total);
        }
      }
      if (this.selectedOrder) {
        this.currentDeal = this.customer.deals.find(
          (d) => this.selectedOrder === d.dealID
        );
        this.orderInfo = this.currentDeal.internalInfo;
        this.fetchDealContent(this.selectedOrder);
      }
      this.$store.dispatch("user/fetchUsers");
    });
    this.$store.dispatch("agency/fetchAgency");
    this.$store.dispatch("issue/fetchIssues").then(() => {
      this.pIssueID = this.issues[0].issueID;
      this.$store.dispatch("product/fetchProductCategory").then(() => {
        this.pCategoryID = 1;
        this.$store
          .dispatch("region/fetchCustomerRegions", this.customerID)
          .then(() => {
            this.pRegionID = this.customerRegions[0].regionID;
            this.updatePricelist();
          });
      });
    });
    this.$store.dispatch("address/fetchAddress", this.customerID);
    this.$store.dispatch("category/fetchCategory");
    this.$store.dispatch("region/fetchRegions");
  },

  methods: {
    assign() {
      this.customerSaving = true;
      this.$store
        .dispatch("customer/assign", {
          customerID: this.customerID,
          userID: this.userID,
        })
        .then((res) => {
          if (res) {
            this.customerSaving = false;
          }
        });
    },
    updateInternal() {
      this.$store
        .dispatch("order/info", {
          dealID: this.selectedOrder,
          internalInfo: this.orderInfo,
        })
        .then((res) => {
          if (res) {
            this.updateCustomer();
          }
        });
    },
    assignOrder() {
      this.orderSaving = true;
      this.$store
        .dispatch("deal/assign", {
          dealID: this.selectedOrder,
          userID: this.orderUserID,
        })
        .then((res) => {
          if (res) {
            this.orderSaving = false;
          }
        });
    },
    editContact(contact) {
      this.showContactForm = true;
      this.editedContact = contact;
      this.updateContactKey++;
    },
    addFee() {
      this.$store
        .dispatch("deal/addFee", {
          dealID: this.selectedOrder,
          proc: this.proc,
        })
        .then(() => {
          this.fetchDealContent(this.selectedOrder);
        });
    },
    hideContact(personID) {
      this.showContactForm = false;
      this.$store.dispatch("contact/hideContact", personID).then(() => {
        this.$store.dispatch("customer/fetchCustomer", this.customerID);
      });
    },

    getRate(amount) {
      for (var i = 0; i < this.currencyRates.length; i++) {
        if (this.currencyRates[i].currencyCode == this.currency) {
          return amount * this.currencyRates[i].rate;
        }
      }
      return amount;
    },

    fixDateTime(d) {
      let date = d.substring(0, 10);
      let res = "";
      if (date == moment().format("YYYY-MM-DD")) {
        res = "i dag";
      } else if (date.substring(0, 4) == moment().format("YYYY")) {
        res = moment(date).format("D/M");
      } else {
        res = date;
      }
      if (d.substring(11, 16) != "00:00") {
        res += " " + d.substring(11, 16);
      }
      return res;
    },

    fixDate(d) {
      let date = d.substring(0, 10);
      let res = "";
      if (date == moment().format("YYYY-MM-DD")) {
        res = "i dag";
      } else if (date.substring(0, 4) == moment().format("YYYY")) {
        res = moment(date).format("D/M");
      } else {
        res = date;
      }
      return res;
    },
    setContact(personID) {
      this.personID = personID;
      this.contact = this.customer.contacts.find(
        (contact) => parseInt(contact.personID) === this.personID
      );
      if (this.selectedOrder) {
        this.$router.push({
          name: "ShowCustomerDeal",
          params: {
            customerID: this.customerID,
            personID: this.personID,
            dealID: this.selectedOrder,
          },
        });
      } else {
        this.$router.push({
          name: "ShowCustomerContact",
          params: {
            customerID: this.customerID,
            personID: this.personID,
          },
        });
      }
    },
    resetForm() {
      this.date = null;
      this.time = null;
    },
    getScoreIcon(score) {
      var icon = "";
      if (score == 1) {
        icon = "bi-trophy-fill";
      } else if (score == 2) {
        icon = "bi-award-fill";
      } else if (score == 3) {
        icon = "bi-patch-check-fill";
      } else if (score == 4) {
        icon = "bi-gem";
      } else if (score == 5) {
        icon = "bi-brightness-alt-high-fill";
      }
      return icon;
    },
    getScoreColor(score) {
      var color = "";
      if (score == 1) {
        color = "#ffd700";
      } else if (score == 2) {
        color = "#c0c0c0";
      } else if (score == 3) {
        color = "#b08d57";
      } else if (score == 4) {
        color = "#007bb8";
      } else if (score == 5) {
        color = "#e74c3c";
      }
      return color;
    },
    clear() {
      this.resetForm();
      this.errors = [];
      this.modalTitle = "Flytta aktivitet";
      this.editable = false;
      this.disabled = true;
    },

    moveActivity(event) {
      this.editable = true;
      this.selectedEvent = event.eventID;
      this.date = new Date(event.eventTime);
      this.time = event.eventTime.substring(11, 16);
      this.modalTitle = "Flytta aktivitet";
      this.$refs.refModal.open();
    },
    showTemporaryProduct() {
      this.$refs.refProduct.open();
    },
    addTemporaryProduct() {
      let data = {
        dealID: this.selectedOrder,
        productName: this.tpProductName,
        categoryID: this.tpCategoryID,
        price: this.tpPrice,
        quantity: this.tpQuantity,
        startDate: this.tpStartDate
          ? moment(this.tpStartDate).format("YYYY-MM-DD")
          : "",
        endDate: this.tpEndDate
          ? moment(this.tpEndDate).format("YYYY-MM-DD")
          : "",
      };
      this.$store.dispatch("order/addTemporaryProduct", data).then(() => {
        this.$refs.refProduct.close();
        this.tpStartDate = null;
        this.tpEndDate = null;
        this.tpQuantity = 1;
        this.tpPrice = 0;
        this.tpCategoryID = 0;
        this.tpProductName = null;
        this.fetchDealContent(this.selectedOrder);
      });
    },
    finishActivity(eventID) {
      this.$store.dispatch("contact/finishActivity", eventID).then(() => {
        this.$store.dispatch("customer/fetchCustomer", this.customerID);
      });
    },
    activity() {
      this.$store
        .dispatch("contact/moveActivity", {
          eventID: this.selectedEvent,
          date: moment(this.date).format("YYYY-MM-DD"),
          time: this.time,
        })
        .then(() => {
          this.resetForm();
          this.$refs.refModal.close();

          this.$store.dispatch("customer/fetchCustomer", this.customerID);
        });
    },

    cancelRow() {
      this.pQuantity = 1;
      this.discount = 0;
      this.pTotal = 0;
      this.listPrice = 0;
      this.pProductID = null;
      this.selectedRow = null;
    },
    calcTotal() {
      this.pTotal = Math.round(this.pQuantity * this.listPrice - this.discount);
      this.procDiscount = Math.round(
        (this.discount / (this.pQuantity * this.listPrice)) * 100
      );
    },
    calcDiscount() {
      this.discount = Math.round(this.pQuantity * this.listPrice - this.pTotal);
      this.procDiscount = Math.round(
        (this.discount / (this.pQuantity * this.listPrice)) * 100
      );
    },
    calcProc() {
      this.discount = Math.round(
        this.pQuantity * this.listPrice * (this.procDiscount / 100)
      );
      this.pTotal = this.pQuantity * this.listPrice - this.discount;
    },
    resetQuantity() {
      this.pQuantity = 1;
      this.updatePricelist();
    },
    deleteAddress(addressID) {
      this.$store.dispatch("address/deleteAddress", addressID).then(() => {
        this.$store.dispatch("address/fetchAddress", this.customerID);
      });
    },
    updateListPrice() {
      let product = this.pricelist.find(
        (product) => product.productID == this.pProductID
      );
      if (product) {
        if (product.price) {
          this.listPrice = product.price;
          this.calcTotal();
          this.noListPrice = false;
        } else {
          this.noListPrice = true;
          this.listPrice = 0;
          this.discount = 0;
          this.calcTotal();
        }
        this.completedOrderRow = true;
      } else {
        this.listPrice = 0;
        this.discount = 0;
        this.calcTotal();
      }
    },
    fillContentForm(d) {
      this.selectedRow = d.rowID;
      this.pCategoryID = d.categoryID;
      if (
        d.categoryID == 1 ||
        d.categoryID == 6 ||
        d.categoryID == 7 ||
        d.categoryID == 8 ||
        d.categoryID == 10
      ) {
        this.pIssueID = d.issueID;
      }
      this.pRegionID = d.regionID;
      this.updatePricelist().then(() => {
        this.pProductID = d.productID;
        this.pQuantity = d.quantity;
        this.discount = d.discount;
        this.updateListPrice();
        if (d.price && !this.listPrice) {
          this.listPrice = d.price;
          this.calcTotal();
        }
      });
      if (this.accessLevel == "admin") {
        this.showOrderForm = true;
      }
    },
    async updatePricelist() {
      this.completedOrderRow = false;
      if (
        this.pCategoryID == 1 ||
        this.pCategoryID == 6 ||
        this.pCategoryID == 7 ||
        this.pCategoryID == 8 ||
        this.pCategoryID == 10
      ) {
        this.startDate = null;
        this.endDate = null;
        await this.$store.dispatch("product/fetchPricelistForIssue", {
          categoryID: this.pCategoryID,
          regionID: this.pCategoryID == 1 ? this.pRegionID : null,
          issueID: this.pIssueID,
        });
      } else {
        await this.$store.dispatch("product/fetchPricelistForDate", {
          categoryID: this.pCategoryID,
          regionID: this.pCategoryID == 1 ? this.pRegionID : null,
          date: this.startDate
            ? moment(this.startDate).format("YYYY-MM-DD")
            : "",
        });
      }
      if (this.pProductID != null) {
        this.updateListPrice();
      }
    },
    async addDealContent() {
      console.log("Discount: " + this.discount);
      this.dealSaving = true;
      let deal = {
        dealID: this.selectedOrder,
        productID: this.pProductID,
        quantity: this.pQuantity,
        discount: this.discount,
        price: this.listPrice,
        rowID: this.selectedRow,
        currencyCode: this.currency,
        regionID: this.pCategoryID == 1 ? this.pRegionID : 0,
        issueID:
          this.pCategoryID == 1 ||
          (this.pCategoryID == 6 && this.issueOrDate) ||
          this.pCategoryID == 7 ||
          this.pCategoryID == 8 ||
          this.pCategoryID == 10
            ? this.pIssueID
            : null,
        startDate: this.startDate
          ? moment(this.startDate).format("YYYY-MM-DD")
          : null,
        endDate: this.endDate
          ? moment(this.endDate).format("YYYY-MM-DD")
          : null,
      };
      let response = null;
      if (this.selectedRow == null) {
        response = await this.$store.dispatch("order/addDealContent", deal);
      } else {
        response = await this.$store.dispatch("order/updateDealContent", deal);
      }
      if (response) {
        this.fetchDealContent(this.selectedOrder);
      }
      this.dealSaving = false;
      this.selectedRow = null;
    },
    async removeDealContent(deal) {
      await this.$store.dispatch("order/removeDealContent", deal);
      this.fetchDealContent(deal.dealID);
    },
    formatIssueDate(d) {
      return moment(d).format("MMM YY");
    },
    formatCurrency(price) {
      if (price == null) {
        price = 0;
      }
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: this.currency,
      });
    },

    formatCurrencySEK(price) {
      if (price == null) {
        price = 0;
      }
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "SEK",
      });
    },
    async saveContact(contact) {
      contact.customerID = this.customerID;
      let response;
      if (contact.personID != null) {
        response = await this.$store.dispatch(
          "contact/updateContactPerson",
          contact
        );
        this.showContactForm = false;
      } else {
        response = await this.$store.dispatch(
          "contact/saveContactPerson",
          contact
        );
      }
      if (response) {
        this.editedContact = {
          personName: null,
          title: null,
          email: null,
          mobile: null,
          phone: null,
          comment: null,
        };
        this.updateContactKey++;
        this.updateCustomer();
      }
    },

    async saveNote() {
      let dateTime =
        moment(this.eventTime).format("YYYY-MM-DD") +
        (this.selectedTime ? " " + this.selectedTime : "");

      let response = await this.$store.dispatch("customer/saveNote", {
        customerID: this.customerID,
        personID: this.personID,
        event: this.showEvent,
        eventTime: dateTime,
        place: this.place,
        note: this.note,
        eventType: this.eventType,
      });
      if (response) {
        if (response) {
          if (this.customer.activities && this.customer.activities.length > 0) {
            this.showEvent = false;
          } else {
            this.showEvent = true;
          }
          this.eventTime = null;
          this.place = null;
          this.note = null;
          this.eventType = "phone";
          this.note = null;
          this.updateCustomer();
        }
      }
    },

    async saveComment() {
      let response = await this.$store.dispatch("customer/saveComment", {
        customerID: this.customerID,
        comment: this.customer.comment,
      });
      if (response) {
        this.updateCustomer();
      }
    },

    async saveAgency(agency) {
      agency.customerNr = this.customer.customerNr;
      let response = await this.$store.dispatch("agency/addAgency", agency);
      if (response) {
        agency.company = null;
        agency.email = null;
        agency.website = null;
        agency.orgnr = null;
        agency.vatnr = null;
        agency.billingEmail = null;
        agency.customerNr = null;
        this.$store.dispatch("agency/fetchAgency");
      }
    },

    async saveAddress(address) {
      address.customerID = this.customerID;
      address.lastUpdated = moment().format("YYYY-MM-DD HH:mm:ss");
      let response = await this.$store.dispatch("address/addAddress", address);
      if (response) {
        this.$store.dispatch("address/fetchAddress", this.customerID);

        address.streetAddress = null;
        address.zipcode = null;
        address.city = null;
        address.country = this.countries.find(
          (country) => country.code === "SE"
        );
        address.comment = null;
        address.addressType = "visiting";
      }
    },

    selectedMedia() {
      this.contact = null;
      this.$store.dispatch("contact/fetchContact", this.agency.customerID);
    },

    unlink(agency) {
      let agencyID = parseInt(agency.customerID);
      let personID = parseInt(agency.personID);

      let formData = new FormData();
      formData.append("agencyID", agencyID);
      formData.append("customerID", this.customerID);
      formData.append("personID", personID);
      formData.append("key", this.apiKey);

      this.$http
        .post(this.baseUrl + "/edit.php?type=removeCustomerAgency", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            this.updateCustomer();
          } else {
            throw false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showConnectionForm() {
      this.contact = null;
      this.agency = null;
      this.errorMessage = null;
      this.showAgencyForm = !this.showAgencyForm;
    },

    async connectMedia() {
      this.errorMessage = null;
      let response = await this.$store.dispatch(
        "contact/connectCustomerAgency",
        {
          agencyID: this.agency.customerID,
          customerID: this.customerID,
          personID: this.contact.personID,
          startDate: moment(this.startDate).format("YYYY-MM-DD"),
          endDate: this.endDate,
        }
      );

      if (response) {
        this.agency = null;
        this.contact = null;
        this.startDate = null;
        this.updateCustomer();
      } else {
        this.errorMessage = "Fel meddelande, det gick inte koppla mediabyrå.";
      }
    },

    async addDeal() {
      if (this.personID != null) {
        let customerID = this.customerID;
        let personID = this.personID;
        let response = await this.$store.dispatch("order/addOrder", {
          customerID,
          personID,
        });
        if (response) {
          this.updateCustomer();
          this.openDeal(response, null);
          this.showOrderForm = true;
        }
      }
    },

    submit() {
      this.errors = [];
      if (!this.validation()) {
        let customerID = this.customer.customerID;
        let personID = this.contact.personID;
        this.$store.dispatch("order/addOrder", {
          customerID,
          personID,
        });
      }
    },

    fetchDealContent(dealID) {
      this.$store.dispatch("order/fetchDealContent", dealID).then(() => {
        this.sumPrice = 0;
        this.sumDiscount = 0;
        this.sumNet = 0;
        if (this.dealContent.length == 0) {
          this.currency = "SEK";
        }
        for (let i = 0; i < this.dealContent.length; i++) {
          this.currency =
            this.dealContent[i].currencyCode != null &&
            this.dealContent[i].currencyCode.length > 1
              ? this.dealContent[i].currencyCode
              : "SEK";
          this.sumPrice += this.dealContent[i].price;
          this.sumDiscount += this.dealContent[i].discount;
          this.sumNet +=
            this.dealContent[i].quantity * this.dealContent[i].price -
            this.dealContent[i].discount;
        }
      });
      this.updateCustomer();
    },

    createOrder() {
      this.$store
        .dispatch("order/updateOrder", {
          dealID: this.selectedOrder,
          status: "confirmed",
        })
        .then(() => {
          this.updateCustomer();
        });
    },
    createOffer() {
      this.$store
        .dispatch("order/updateOrder", {
          dealID: this.selectedOrder,
          status: "offer",
        })
        .then(() => {
          this.updateCustomer();
        });
    },
    denyOffer() {
      this.$store
        .dispatch("order/updateOrder", {
          dealID: this.selectedOrder,
          status: "rejected",
        })
        .then(() => {
          this.updateCustomer();
        });
    },

    openDeal(dealID, deal) {
      this.$router.push({
        name: "ShowCustomerDeal",
        params: {
          dealID: dealID,
          customerID: this.customer.customerID,
          personID: this.personID,
        },
      });
      this.selectedOrder = dealID;
      this.fetchDealContent(dealID);
      if (deal != null) {
        this.status = deal.status;
        this.orderInfo = deal.internalInfo;
        if (deal.status == "opportunity") {
          this.showOrderForm = true;
        } else {
          this.showOrderForm = false;
        }
      } else {
        this.orderInfo = null;
      }
    },

    updateCustomer() {
      this.$store
        .dispatch("customer/fetchCustomer", this.customerID)
        .then(() => {
          this.pRegionID = this.customer.regionID;
          this.currentDeal = this.customer.deals.find(
            (d) => this.selectedOrder == d.dealID
          );
          this.updatePricelist();
          if (this.currentDeal) {
            this.orderUserID = this.currentDeal.userID;
            let orderContactID = this.currentDeal.personID;
            let orderContact = this.customer.contacts.find(
              (d) => orderContactID == d.personID
            );
            if (orderContact) {
              this.handledByAgency =
                orderContact.customerID != this.customer.customerID;
            }
          }
        });
    },

    fixPhone(str) {
      let out = "";
      for (let i = 0; i < str.length; i++) {
        let c = str.substring(i, i + 1);
        if (
          c == "0" ||
          c == "1" ||
          c == "2" ||
          c == "3" ||
          c == "4" ||
          c == "5" ||
          c == "6" ||
          c == "7" ||
          c == "8" ||
          c == "9"
        ) {
          out += c;
        }
      }
      if (out.substring(0, 1) == "0") {
        out = "+46" + out.substring(1);
      }
      return out;
    },

    removeOrder(dealID) {
      if (dealID) {
        this.$swal({
          title: "Vill du verkligen radera?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Ja, ta bort!",
          cancelButtonText: "Avbryt",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("order/removeDeal", dealID).then(() => {
              this.updateCustomer();
            });
          }
        });
      }
    },

    validation() {
      let hasError = false;
      if (!this.contact) {
        hasError = true;
        this.errors.push("Du måste välja kontaktperson");
      }
      return hasError;
    },

    editCompany() {
      this.company = {
        customerNr: this.customer.customerNr,
        name: this.customer.company,
        orgnr: this.customer.orgnr,
        vatnr: this.customer.vatnr,
        score: this.customer.score,
        customerType: this.customer.customerType == "media",
        email: this.customer.email,
        billingEmail: this.customer.billingEmail,
        website: this.customer.website,
        paymentTerms: this.customer.paymentTerms,
        category: this.categories.find(
          (category) => category.categoryID == this.customer.categoryID
        ),
        region: this.regions.find(
          (region) => region.regionID === parseInt(this.customer.regionID)
        ),
        comment: this.customer.comment,
        status: this.customer.status,
      };

      this.$refs.refEditCompany.open();
    },

    updateCompany() {
      this.errors = [];
      if (!this.company.name) {
        this.errors.push("Ange företagsnamn");
      } else if (!this.checkSSN(this.company.orgnr)) {
        this.errors.push("Organisationsnummer är ogiltigt");
      } else {
        let formData = new FormData();
        formData.append("customerID", this.customerID);
        formData.append("company", this.company.name);
        formData.append("orgnr", this.company.orgnr);
        formData.append("customerNr", this.company.customerNr);
        formData.append("email", this.company.email);
        formData.append("score", this.company.score);
        formData.append("billingEmail", this.company.billingEmail);
        formData.append("vatnr", this.company.vatnr);
        formData.append("paymentTerms", this.company.paymentTerms);
        formData.append(
          "categoryID",
          this.company.category ? this.company.category.categoryID : null
        );
        formData.append(
          "regionID",
          this.company.region ? this.company.region.regionID : null
        );
        formData.append("key", this.apiKey);
        formData.append("website", this.company.website);
        formData.append("status", this.company.status);
        formData.append(
          "customerType",
          this.company.customerType ? "media" : "direct"
        );
        formData.append("comment", this.company.comment);
        this.$http
          .post(this.baseUrl + "/edit.php?type=updateCustomer", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.dispatch("customer/fetchCustomer", this.customerID);
              this.$refs.refEditCompany.close();
            }
          });
      }
    },

    checkSSN(ssn) {
      return /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/.test(ssn);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  cursor: pointer;

  &:hover {
    color: #ddd;
  }
}

.card {
  .bi-gem {
    color: #007bb8;
  }

  .bi-brightness-alt-high-fill {
    color: #e74c3c;
    font-size: 25px;
  }
}

.finished {
  background-color: #ddffdd;
}

.selectedOrder {
  background-color: #ffdddd;
}

.selected {
  background-color: #ffaaaa;
}

.header-box {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.address,
.agency {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.table td {
  cursor: pointer;
  .unlink {
    &:hover {
      color: #e74c3c;
    }
  }
}

.btn-ord {
  max-width: 190px;
  margin: 5px;
}

.modal {
  .alert p {
    font-size: 14px;
  }

  fieldset {
    background: #f8f9f9;
  }

  .btn-link {
    text-decoration: none;
  }

  .contacts {
    .row {
      background: #f1f1f1;
      position: relative;

      .close {
        margin: 0;
        padding: 0;
        position: absolute;
        top: -5px;
        right: -5px;
        width: auto;
        cursor: pointer;
      }
    }
  }

  .media {
    .row {
      background: #f1f1f1;
      position: relative;

      .close {
        margin: 0;
        padding: 0;
        position: absolute;
        top: -5px;
        right: -5px;
        width: auto;
        cursor: pointer;
      }
    }
  }
}
</style>
