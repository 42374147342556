const subscribe = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type == "auth/AUTH_LOGIN") {
      localStorage.setItem(
        "_mogul_apiKey",
        JSON.stringify(mutation.payload.key)
      );
      localStorage.setItem(
        "_mogul_realname",
        JSON.stringify(mutation.payload.realname)
      );
      localStorage.setItem(
        "_mogul_accessLevel",
        JSON.stringify(mutation.payload.accessLevel)
      );
    }

    if (mutation.type == "auth/AUTH_LOGOUT") {
      localStorage.removeItem("_mogul_apiKey");
    }

    // if (mutation.type == "category/FETCH_CATEGORY") {
    //   console.log('FETCH_CATEGORY');
    // }
    // console.log(mutation)
  });
};

export default subscribe;
