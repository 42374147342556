<template>
  <main>
    <div class="aftersales h-100 w-100">
      <navbar title="Aftersales" />
      <div class="content p-3">
        <div class="card mb-2">
          <div class="card-body">
            <h3>Försäljning trycksaker/digitalt per månad</h3>
            <button
              type="button"
              class="btn btn-secondary"
              @click="exportExcel()"
            >
              Excel
            </button>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Månad</th>
                  <th scope="col">Antal ordrar</th>
                  <th scope="col">Totalt</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(deal, index) in orders.months"
                  :key="index"
                  @click="selectMonth(deal.month)"
                  style="cursor: pointer"
                  :class="{ selected: deal.month == selectedMonth }"
                >
                  <td>
                    {{ deal.month }}
                  </td>
                  <td>
                    {{ deal.count }}
                  </td>
                  <td>
                    {{ formatCurrency(deal.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">
            <h3>Försäljning per order</h3>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Ordernummer</th>
                  <th scope="col">Företag</th>
                  <th scope="col">Lanseringsdatum</th>
                  <th scope="col">Säljare</th>
                  <th scope="col">Totalbelopp</th>
                  <th scope="col">Faktura</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(deal, index) in filteredOrders" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'ShowCustomerDeal',
                        params: {
                          personID: deal.personID,
                          customerID: deal.customerID,
                          dealID: deal.dealID,
                        },
                      }"
                      >{{ deal.dealID }}</router-link
                    >
                  </td>
                  <td>{{ deal.company }}</td>
                  <td>
                    {{ deal.startDate }}
                  </td>
                  <td>{{ deal.realname }}</td>
                  <td>
                    {{ formatCurrency(deal.total) }}
                  </td>
                  <td>
                    <a
                      v-if="deal.invoice"
                      target="_blank"
                      :href="
                        'https://mogul.tydalsystems.se/api/getInvoice.php?key=' +
                        apiKey +
                        '&invoiceID=' +
                        deal.invoice
                      "
                      >{{ deal.invoice }}
                    </a>
                    <button
                      type="button"
                      v-if="!deal.invoice"
                      style="width: 100px"
                      class="btn btn-secondary"
                      @click="createInvoice(deal.dealID, deal.startDate)"
                    >
                      <span v-if="!loading || currentDeal != deal.dealID"
                        >Faktura</span
                      >
                      <pulse-loader
                        :loading="loading && currentDeal == deal.dealID"
                      ></pulse-loader>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import moment from "moment";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { Navbar, PulseLoader },
  name: "Aftersales-other",
  data() {
    return {
      selectedMonth: null,
      loading: false,
      year: moment().format("YYYY"),
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.order.aftersaleOther,
      apiKey: (state) => state.auth.apiKey,
    }),
    filteredOrders() {
      if (this.orders && this.orders.rows) {
        return this.orders.rows.filter((order) => {
          return order.orderMonth == this.selectedMonth;
        });
      } else {
        return [];
      }
    },
  },

  mounted() {
    this.checkRouteName();

    document.title = "Aftersales";
    this.$store.dispatch("order/fetchAftersaleOther", this.year);
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    selectMonth(month) {
      this.selectedMonth = month;
    },

    exportExcel() {
      saveExcel({
        data: this.orders.rows,
        fileName: "test.xlsx",
        columns: [
          {
            field: "dealID",
            title: "Ordernr",
          },
          {
            field: "issueName",
            title: "Utgåva",
          },
          {
            field: "company",
            title: "Företag",
          },
          {
            field: "region",
            title: "Region",
          },
          {
            field: "realname",
            title: "Säljare",
          },
          {
            field: "total",
            title: "Totalbelopp",
          },
        ],
      });
    },
    createInvoice(dealID, startDate) {
      this.loading = true;
      this.currentDeal = dealID;
      this.$store
        .dispatch("order/createInvoice", {
          dealID: dealID,
          startDate: startDate,
        })
        .then(() => {
          this.$store.dispatch("order/fetchAftersale").then(() => {
            this.loading = false;
          });
        });
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "SEK",
      });
    },
  },
};
</script>

<style>
.selected {
  background-color: #ffaaaa;
}
</style>
