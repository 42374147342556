export const barHorizontalChart = {
  type: "bar",
  data: {
    labels: ["2021"],
    datasets: [
      {
        label: "Min försäljning",
        data: [1950000],
        backgroundColor: "#F57D01",
        // hoverBackgroundColor: ["#66A2EB", "#FCCE56"]
      },
      {
        label: "Kommande försäljning",
        data: [800000],
        backgroundColor: "#C1175B",
      },
      {
        label: "Budget",
        data: [50000],
        backgroundColor: "#0097A6",
      },
    ],
  },
  options: {
    plugins: {
      title: {
        display: true,
        text: "Budget 2 800 000",
      },
    },
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  },
};

export default barHorizontalChart;
