import axios from "../../axios";

const state = {
  addresses: [],
};

const mutations = {
  FETCH_ADDRESS(state, payload) {
    state.addresses = payload;
  },
};

const actions = {
  fetchAddress({ rootState, commit }, customerID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", customerID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=address", formData)
        .then((response) => {
          commit("FETCH_ADDRESS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteAddress({ rootState }, addressID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("addressID", addressID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=deleteAddress", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAddress({ rootState, dispatch }, address) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("customerID", address.customerID);
      formData.append("addressType", address.addressType);
      formData.append("streetAddress", address.streetAddress);
      formData.append("zipcode", address.zipcode);
      formData.append("city", address.city);
      formData.append("country", address.country.name);
      formData.append("comment", address.comment);
      formData.append("lastUpdated", address.lastUpdated);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addAddress", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchAddress");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
