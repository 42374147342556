import axios from "../../axios";

const state = {
  users: [],
  start: {},
};

const getters = {
  getIssues(state) {
    return state.users;
  },
};

const mutations = {
  FETCH_USERS(state, payload) {
    state.users = payload;
  },
  FETCH_START(state, payload) {
    state.start = payload;
  },
};

const actions = {
  fetchUsers({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=activeUsers", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_USERS", response.data.data);
            resolve(response);
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchStart({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=start", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_START", response.data.data);
            resolve(response);
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
