import axios from "../../axios";

const state = {
  issues: [],
};

const getters = {
  getIssues(state) {
    return state.issues;
  },
};

const mutations = {
  FETCH_ISSUES(state, payload) {
    state.issues = payload;
  },
};

const actions = {
  fetchIssues({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=currentIssues", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            commit("FETCH_ISSUES", response.data.data);
            resolve(response);
          } else {
            throw new Error(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
