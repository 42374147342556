<template>
  <main>
    <div class="region w-100">
      <navbar title="Regioner" />
      <div class="content p-3">
        <div class="row">
          <div class="col-lg-5">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">Lägg till region</h5>
                <div class="mb-3">
                  <label for="region-code" class="form-label">Kod</label>
                  <input
                    type="text"
                    class="form-control"
                    id="region-code"
                    placeholder="Kod"
                    v-model="regionCode"
                  />
                </div>
                <div class="mb-3">
                  <label for="region" class="form-label">Region</label>
                  <input
                    type="text"
                    class="form-control"
                    id="region"
                    placeholder="Region"
                    v-model="region"
                  />
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="status"
                      v-model="status"
                    />
                    <label class="form-check-label" for="status">
                      vill du aktivera?
                    </label>
                  </div>
                </div>
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-success fw-bold"
                    type="button"
                    v-if="!editable"
                    @click="saveRegion()"
                  >
                    Spara
                  </button>
                  <button
                    class="btn btn-success fw-bold"
                    type="button"
                    v-if="editable"
                    @click="updateRegion()"
                  >
                    Uppdatera
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary fw-bold"
                    v-if="editable"
                    @click="close"
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">Regioner</h5>
                <table class="table table-striped" v-if="regions.length > 0">
                  <thead>
                    <tr>
                      <th class="border-bottom border-top">Kod</th>
                      <th class="border-bottom border-top">Region 0-RIKS</th>
                      <th class="border-bottom border-top">Aktiv/inaktiv</th>
                      <th class="border-bottom border-top">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(region, index) in regions" :key="index">
                      <th scope="row">{{ region.regionCode }}</th>
                      <td>{{ region.region }}</td>
                      <td>
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="activate"
                            :checked="region.status == 'active'"
                          />
                        </div>
                      </td>
                      <td class="actions">
                        <button type="button" class="btn btn-default">
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="editRegion(region)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-primary" role="alert" v-else>
                  <p class="m-0 fst-italic">Ingen region hittades</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "../components/Navbar.vue";
export default {
  data() {
    return {
      regionID: null,
      regionCode: null,
      region: null,
      status: false, // active, inactive
      editable: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      regions: (state) => state.region.regions,
    }),
  },

  name: "admin-regions",

  components: { Navbar },

  mounted() {
    this.checkRouteName();

    document.title = "Regioner";
    this.$store.dispatch("region/fetchRegions");
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    saveRegion() {
      let status = this.status ? "active" : "inactive";

      let formData = new FormData();
      formData.append("regionCode", this.regionCode);
      formData.append("region", this.region);
      formData.append("status", status);
      formData.append("key", this.apiKey);

      this.$http
        .post(this.baseUrl + "/edit.php?type=addRegion", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("region/fetchRegions");
            this.regionCode = null;
            this.region = null;
            this.status = false;
          }
        });
    },

    editRegion(region) {
      this.regionID = region.regionID;
      this.regionCode = region.regionCode;
      this.region = region.region;
      this.status = region.status == "active" ? true : false;
      this.editable = true;
    },

    updateRegion() {
      if (this.regionID !== null) {
        let status = this.status ? "active" : "inactive";

        let formData = new FormData();
        formData.append("regionID", this.regionID);
        formData.append("regionCode", this.regionCode);
        formData.append("region", this.region);
        formData.append("status", status);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateRegion", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.dispatch("region/fetchRegions");
              this.regionID = null;
              this.regionCode = null;
              this.region = null;
              this.status = false;
              this.editable = false;
            }
          });
      }
    },

    close() {
      this.regionID = null;
      this.regionCode = null;
      this.region = null;
      this.status = false;
      this.editable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.region {
  .form-check label {
    margin-top: 3px;
  }
  .table {
    .actions {
      text-align: right;
      button {
        margin: 0 5px;
        padding: 0;
      }
    }
  }
}
</style>
