<template>
  <div class="lineChart">
    <canvas id="lineChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import lineChartData from "@/charts/line-chart.js";
export default {
  name: "LineChart",
  data() {
    return {
      chartData: lineChartData,
    };
  },

  mounted() {
    const ctx = document.getElementById("lineChart");
    ctx.width = 250;
    ctx.height = 250;
    new Chart(ctx, this.chartData);
  },
};
</script>
