<template>
  <main>
    <div class="login h-100 w-100 bg-white">
      <div class="content p-3">
        <div class="wrapper">
          <div class="header">
            <img
              src="@/assets/villalivet-2.png"
              class="img"
              alt="Mogul logo"
              width="100"
            />
          </div>
          <div class="auth-body">
            <div
              class="alert alert-danger"
              role="alert"
              v-if="errors.length > 0"
            >
              <p
                class="m-0 fst-italic"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error }}
              </p>
            </div>
            <div class="mb-3">
              <input
                type="email"
                class="form-control form-control-lg"
                placeholder="E-postadress"
                v-model="email"
              />
            </div>
            <div class="mb-3">
              <input
                type="password"
                class="form-control form-control-lg"
                placeholder="Lösenord"
                @keyup.enter="login()"
                v-model="password"
              />
            </div>
            <div class="d-grid gap-2">
              <button
                class="btn btn-secondary btn-lg fw-bold"
                type="button"
                @click="login()"
              >
                Logga in
              </button>
            </div>
          </div>
          <div class="auth-footer">
            <p>
              Behöver du ett nytt lösenord?
              <router-link to="/forgot-password"
                >Beställ nytt lösenord.</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "user-login",
  data() {
    return {
      email: null,
      password: null,
      errors: [],
    };
  },
  mounted() {
    document.title = "Logga in";
  },

  methods: {
    async login() {
      this.errors = [];
      if (!this.validateForm()) {
        let res = await this.$store.dispatch("auth/authLogin", {
          email: this.email,
          password: this.password,
        });
        if (res.data.status == "error") {
          this.errors.push(res.data.data);
        } else {
          this.$store.commit("auth/AUTH_LOGIN", res.data.data);
          this.$router.push({ name: "Home" });
        }
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.email) {
        hasError = true;
        this.errors.push("Du måste ange e-postadress");
      }
      if (!this.password) {
        hasError = true;
        this.errors.push("Ange lösenord");
      }
      return hasError;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    // background: #fff;
  }
  .wrapper {
    width: 32rem;
    background: #fff;
    padding: 5rem 2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15rem;
    // background: #eee;
    // box-shadow: 5px 10px #ececee;
    .header {
      text-align: center;
      padding-bottom: 40px;
    }

    button {
      font-weight: 600;
      font-size: 1rem;
    }

    .auth-footer {
      text-align: center;
      margin-top: 25px;
      p {
        font-size: 0.85rem;
        font-weight: 600;
        color: #909497;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
