<template>
  <main>
    <div class="users w-100">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end py-2">
              <button type="button" class="btn btn-secondary float-end" @click="addUser">
                <i class="bi bi-plus-circle-fill"></i> Lägg till ny användare
              </button>
            </div>
            <table class="table table-striped" v-if="users.length > 0">
              <thead>
                <tr>
                  <th class="border-bottom border-top">Namn</th>
                  <th class="border-bottom border-top">E-postadress</th>
                  <th class="border-bottom border-top">Telefon</th>
                  <th class="border-bottom border-top">Mobil</th>
                  <th class="border-bottom border-top">Roll</th>
                  <th class="border-bottom border-top">Status</th>
                  <th class="border-bottom border-top">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in users" :key="index">
                  <td>{{ user.realname }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.telephone }}</td>
                  <td>{{ user.mobile }}</td>
                  <td>
                    {{ user.accessLevel }}
                  </td>
                  <td>
                    <span class="badge bg-success" v-if="user.active == 'y'">Aktiv</span>
                    <span class="badge bg-secondary" v-else>Inaktiv</span>
                    <button type="button" class="btn btn-default" v-if="user.apikey" @click="logout(user.userID)">
                      <i class="bi bi-box-arrow-right"></i></button>
                  </td>
                  <td class="actions">
                    <button type="button" class="btn btn-default" @click="editUser(user)">
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="alert alert-primary" role="alert" v-else>
              <p class="m-0">Ingen användare hittades</p>
            </div>
          </div>
        </div>
      </div>

      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p class="m-0 fst-italic" v-for="(error, index) in errors" :key="index">
            {{ error }}
          </p>
        </div>
        <div class="row g-3">
          <div class="col-md-12">
            <input type="text" class="form-control" placeholder="Namn" v-model="realname" />
          </div>
          <div class="col-md-12">
            <input type="email" class="form-control" placeholder="E-postadress" v-model="email" />
          </div>
          <div :class="{ 'col-md-12': !editable, 'col-md-8': editable }">
            <input type="password" class="form-control" placeholder="Lösenord" v-model="password"
              :disabled="editable && disabled" />
          </div>
          <div class="col-md-4" v-if="editable">
            <div class="mb-2">
              <div class="form-check mt-1">
                <input class="form-check-input" type="checkbox" id="disabled" v-model="disabled" @change="onChange()" />
                <label class="form-check-label" for="disabled">
                  behåll nuvarande lösenord
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="loggedInAccessLevel == 'admin'">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="accessLevel" id="accessExtern" value="extern"
                v-model="accessLevel" />
              <label class="form-check-label" for="accessExtern">Extern säljare</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="accessLevel" id="accessExternmanager"
                value="externmanager" v-model="accessLevel" />
              <label class="form-check-label" for="accessExternmanager">Extern säljchef</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="accessLevel" id="accessIntern" value="intern"
                v-model="accessLevel" />
              <label class="form-check-label" for="accessIntern">Intern säljare</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="accessLevel" id="accessAdmin" value="admin"
                v-model="accessLevel" />
              <label class="form-check-label" for="accessAdmin">Admin</label>
            </div>
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Telefon" v-model="telephone" />
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Mobil" v-model="mobile" />
          </div>
          <div class="col-md-12">
            <input type="text" class="form-control" placeholder="Adress" v-model="streetAddress" />
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Postnummer" v-model="zipcode" />
          </div>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Ort" v-model="city" />
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="active" v-model="status" />
                <label class="form-check-label" for="active">
                  vill du aktivera användaren?
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-grid gap-2 mb-3">
              <button type="button" class="btn btn-secondary" v-if="!editable" @click="saveUser()">
                Spara
              </button>
              <button type="button" class="btn btn-secondary" v-if="editable" @click="updateUser()">
                Uppdatera
              </button>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import data from "@/assets/users.json";
import Modal from "@/components/Modal.vue";
import { mapState } from "vuex";

export default {
  name: "PayCheck",
  data() {
    return {
      navbar: "Användare",
      users: [],
      userID: null,
      realname: null,
      email: null,
      password: null,
      telephone: null,
      accessLevel: null,
      mobile: null,
      streetAddress: null,
      zipcode: null,
      city: null,
      status: true,
      errors: [],
      modalTitle: "Lägg till ny användare",
      editable: false,
      disabled: true,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      loggedInAccessLevel: (state) => state.auth.accessLevel,
    }),
  },

  components: {
    Navbar,
    Modal,
  },

  mounted() {
    this.checkRouteName();

    document.title = "Användare";
    this.fetchUsers();
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    addUser() {
      this.$refs.refModal.open();
    },

    fetchUsers() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/get.php?type=users", formData)
        .then((response) => {
          this.users = response.data.data;
        });
    },

    saveUser() {
      this.errors = [];
      if (!this.validateForm()) {
        let status = this.status ? "y" : "n";
        let formData = new FormData();
        formData.append("realname", this.realname);
        formData.append("email", this.email);
        formData.append("password", this.password);
        formData.append("telephone", this.telephone);
        formData.append("mobile", this.mobile);
        formData.append("streetAddress", this.streetAddress);
        formData.append("zipcode", this.zipcode);
        formData.append("city", this.city);
        formData.append(
          "accessLevel",
          this.loggedInAccessLevel == "admin" ? this.accessLevel : "extern"
        );
        formData.append("active", status);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=addUser", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.resetForm();
              this.$refs.refModal.close();
              this.fetchUsers();
            }
          });
      }
    },

    editUser(user) {
      this.editable = true;
      this.userID = user.userID;
      this.realname = user.realname;
      this.email = user.email;
      this.telephone = user.telephone;
      this.mobile = user.mobile;
      this.streetAddress = user.streetAddress;
      this.zipcode = user.zipcode;
      this.city = user.city;
      this.status = user.active == "y" ? true : false;
      this.accessLevel = user.accessLevel;
      this.modalTitle = "Uppdatera användare";
      this.$refs.refModal.open();
    },

    updateUser() {
      this.errors = [];
      if (!this.validateForm()) {
        let status = this.status ? "y" : "n";
        let formData = new FormData();
        formData.append("userID", this.userID);
        formData.append("realname", this.realname);
        formData.append("email", this.email);
        formData.append("telephone", this.telephone);
        formData.append("mobile", this.mobile);
        formData.append("streetAddress", this.streetAddress);
        formData.append("zipcode", this.zipcode);
        formData.append("city", this.city);
        formData.append("accessLevel", this.accessLevel);
        formData.append("active", status);
        formData.append("key", this.apiKey);

        if (this.editable && !this.disabled) {
          formData.append("password", this.password);
        }

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateUser", formData)
          .then((response) => {
            if (response.data.status === "ok") {
              this.resetForm();
              this.$refs.refModal.close();
              this.fetchUsers();
            }
          });
      }
    },

    logout(userID) {
      let formData = new FormData();
      formData.append("userID", userID);
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/edit.php?type=logout", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            this.fetchUsers();
          }
        });
    },

    onChange() {
      if (this.disabled && this.password !== null) {
        this.password = null;
      }
    },

    deleteUser() {
      this.$swal({
        title: "Vill du verkligen radera?",
        text: "Du kommer inte att kunna återställa detta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, radera!",
        cancelButtonText: "Avbryt",
      }).then((result) => {
        if (result.isConfirmed) {
          // Call http request to delete
        }
      });
    },

    validateForm() {
      let hasError = false;
      if (!this.realname) {
        hasError = true;
        this.errors.push("Ange namn");
      }
      if (!this.email) {
        hasError = true;
        this.errors.push("Ange e-postadress");
      }
      if (!this.mobile) {
        hasError = true;
        this.errors.push("Ange mobil telefon");
      }
      return hasError;
    },

    resetForm() {
      this.realname = null;
      this.email = null;
      this.password = null;
      this.telephone = null;
      this.mobile = null;
      this.streetAddress = null;
      this.zipcode = null;
      this.city = null;
      this.accessLevel = "extern";
      this.status = true;
    },

    clear() {
      this.resetForm();
      this.errors = [];
      this.modalTitle = "Lägg till ny användare";
      this.editable = false;
      this.disabled = true;
      this.userID = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.users {
  .table {
    .actions {
      text-align: right;

      button {
        margin: 0 5px;
        padding: 0;
      }
    }
  }

  .modal {
    .alert p {
      font-size: 14px;
    }

    .form-check label {
      margin-top: 3px;
    }
  }
}
</style>
