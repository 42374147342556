<template>
  <main>
    <div class="calendar h-100 w-100 section">
      <navbar title="Kalender" />
      <div class="content p-3">
        <div class="d-flex justify-content-between calendar-header">
          <select
            class="form-select"
            name="userID"
            v-model="userID"
            v-if="accessLevel == 'admin'"
            @change="updateCalendar"
          >
            <option v-for="(r, index) in users" :key="index" :value="r.userID">
              {{ r.realname }}
            </option>
          </select>
          <a
            :href="icsURL"
            class="btn btn-secondary"
            @click="exportAllEvents()"
            :download="icsFileName"
          >
            <i class="bi bi-calendar-check-fill"></i> Exportera till kalender
          </a>
        </div>
        <v-calendar
          class="custom-calendar max-w-full"
          :masks="masks"
          show-iso-weeknumbers
          :attributes="attributes"
          disable-page-swipe
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10 overflow-hidden">
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <p
                  v-for="attr in attributes"
                  :title="
                    attr.customData.title + ': ' + attr.customData.description
                  "
                  :key="attr.key"
                  @click="
                    moveActivity({
                      eventID: attr.customData.eventID,
                      eventTime: attr.customData.eventTime,
                      description: attr.customData.description,
                      personID: attr.customData.personID,
                      customerID: attr.customData.customerID,
                      company: attr.customData.title,
                      finished: attr.customData.finished,
                    })
                  "
                  class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 cursor"
                  :class="attr.customData.class"
                  style="white-space: nowrap"
                >
                  {{
                    attr.customData.eventTime != null &&
                    attr.customData.eventTime.length > 16 &&
                    attr.customData.eventTime.substring(11, 16) != "00:00"
                      ? attr.customData.eventTime.substring(11, 16)
                      : ""
                  }}
                  {{ attr.customData.title }}
                </p>
              </div>
            </div>
          </template>
        </v-calendar>
      </div>
      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
          <p
            class="m-0 fst-italic"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
        <template v-slot:delete>
          <button class="btn btn-outline-danger" @click="deleteActivity()">
            <i class="bi bi-trash"></i> Ta bort
          </button>
        </template>
        <div class="row mb-3">
          <div class="col-md-3">
            <p><strong>Kund: </strong></p>
          </div>
          <div class="col-md-6">
            <a href="#" @click="goToCustomer()">
              {{ selectedCompany }}
            </a>
          </div>
          <div class="col-md-3" v-if="!isFinished">
            <button
              type="button"
              class="btn btn-secondary"
              @click="finishActivity()"
            >
              <i class="bi bi-check-lg"></i>
              Avsluta aktivitet
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <p><strong>Datum och tid:</strong></p>
          </div>
          <div class="col-md-6">
            {{
              selectedDateTime != null ? selectedDateTime.substring(0, 10) : ""
            }}
            {{
              selectedDateTime != null &&
              selectedDateTime.length > 16 &&
              selectedDateTime.substring(11, 16) != "00:00"
                ? selectedDateTime.substring(11, 16)
                : ""
            }}
          </div>
          <div class="col-md-3">
            <a
              :href="icsURL"
              class="btn btn-secondary"
              @click="exportEvent()"
              :download="icsFileName"
            >
              <i class="bi bi-calendar-check-fill"></i> Lägg till kalender
            </a>
          </div>
        </div>
        <hr />
        <div class="row g-3" v-if="!isFinished">
          <div class="col-md-3">
            <p><strong>Flytta aktivitet: </strong></p>
          </div>
          <div class="col-md-3">
            <datepicker
              class="form-control"
              placeholder="Välj datum"
              :locale="locale"
              :lower-limit="lowerLimit"
              v-model="date"
            />
          </div>
          <div class="col-md-3">
            <input
              type="time"
              class="form-control"
              placeholder="Välj tid"
              v-model="time"
            />
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-secondary" @click="activity()">
              <i class="bi bi-calendar-event-fill"></i>&nbsp; Spara
            </button>
          </div>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
import Modal from "@/components/Modal.vue";
import { sv } from "date-fns/locale";
import Datepicker from "vue3-datepicker";
import moment from "moment";

export default {
  components: { Navbar, Modal, Datepicker },
  name: "user-calendar",
  data() {
    return {
      date: new Date(),
      startDate: new Date(),
      locale: sv,
      user: null,
      userID: null,
      modalTitle: "Flytta aktivitet",
      editable: false,
      selectedEvent: null,
      errors: [],
      isFinished: null,
      selectedDateTime: null,
      disabled: true,
      time: null,
      lowerLimit: new Date(),
      selectedPersonID: null,
      selectedCustomerID: null,
      selectedCompany: null,
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      icsURL: null,
      icsFileName: null,
      icsEvent: null,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      calendar: (state) => state.contact.calendar,
      accessLevel: (state) => state.auth.accessLevel,
      realname: (state) => state.auth.realname,
      users: (state) => state.user.users,
    }),
  },
  mounted() {
    this.checkRouteName();

    document.title = "Kalender";
    this.$store.dispatch("user/fetchUsers").then(() => {
      let user = this.users.find((d) => this.realname == d.realname);
      this.userID = user.userID;
      this.user = user;
    });
  },
  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    goToCustomer() {
      if (this.selectedPersonID) {
        this.$router.push({
          name: "ShowCustomerContact",
          params: {
            personID: this.selectedPersonID,
            customerID: this.selectedCustomerID,
          },
        });
      } else {
        this.$router.push({
          name: "ShowCustomer",
          params: { customerID: this.selectedCustomerID },
        });
      }
      this.$refs.refModal.close();
    },
    updateCalendar() {
      this.$store
        .dispatch("contact/fetchCalendar", {
          date: this.startDate,
          userID: this.userID,
        })
        .then(() => {
          this.setAttributes();
        });
    },

    finishActivity() {
      this.$store
        .dispatch("contact/finishActivity", this.selectedEvent)
        .then(() => {
          this.resetForm();
          this.$refs.refModal.close();

          this.$store
            .dispatch("contact/fetchCalendar", this.startDate)
            .then(() => {
              this.setAttributes();
            });
        });
    },

    resetForm() {
      this.date = null;
      this.time = null;
    },

    deleteActivity() {
      if (this.selectedEvent) {
        let formData = new FormData();
        formData.append("eventID", this.selectedEvent);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=removeEvent", formData)
          .then((response) => {
            if (response.data.status === "ok") {
              this.updateCalendar();
              this.$refs.refModal.close();
            } else {
              throw false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    clear() {
      this.resetForm();
      this.errors = [];
      this.modalTitle = "Flytta aktivitet";
      this.editable = false;
      this.disabled = true;
    },

    moveActivity(event) {
      this.icsEvent = event;
      this.editable = true;
      this.selectedEvent = event.eventID;
      this.selectedCustomerID = event.customerID;
      this.selectedPersonID = event.personID;
      this.selectedCompany = event.company;
      this.date = new Date(event.eventTime);
      this.selectedDateTime = event.eventTime;
      this.isFinished = event.finished == "y";
      this.time = event.eventTime.substring(11, 16);
      this.modalTitle = event.description;
      this.$refs.refModal.open();
    },
    activity() {
      this.$store
        .dispatch("contact/moveActivity", {
          eventID: this.selectedEvent,
          date: moment(this.date).format("YYYY-MM-DD"),
          time: this.time,
        })
        .then(() => {
          this.resetForm();
          this.$refs.refModal.close();

          this.$store
            .dispatch("contact/fetchCalendar", this.startDate)
            .then(() => {
              this.setAttributes();
            });
        });
    },

    setAttributes() {
      let temp = [];
      for (var i = 0; i < this.calendar.length; i++) {
        temp.push({
          key: i + 1,
          dates: new Date(this.calendar[i].eventTime),
          customData: {
            title: this.calendar[i].company,
            customerID: this.calendar[i].customerID,
            personID: this.calendar[i].personID,
            eventTime: this.calendar[i].eventTime,
            eventID: this.calendar[i].eventID,
            finished: this.calendar[i].finished,
            description: this.calendar[i].description,
            class:
              this.calendar[i].eventTime.substring(11, 16) != "00:00"
                ? "bg-danger text-white rounded"
                : "text-black rounded",
          },
        });
      }
      this.attributes = temp;
    },

    parseEvent(event) {
      let icsEvent = null;
      if (event) {
        let startDate = moment(event.eventTime).format("YYYYMMDD[T]HHmmss");
        let endDate = startDate;

        icsEvent =
          "BEGIN:VEVENT\n" +
          "UID:" +
          event.eventID +
          "\n" +
          "DTSTART;TZID=Europe/Stockholm:" +
          startDate +
          "\n" +
          "DTEND;TZID=Europe/Stockholm:" +
          endDate +
          "\n" +
          "SUMMARY:" +
          event.company +
          "\n" +
          "DESCRIPTION:" +
          event.description +
          "\n" +
          "END:VEVENT\n";
      }
      return icsEvent;
    },

    exportEvent() {
      let parsedEvent = this.parseEvent(this.icsEvent);
      var iCal =
        "BEGIN:VCALENDAR\n" +
        "VERSION:2.0\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "PRODID:Mogul iCalender\n" +
        parsedEvent +
        "END:VCALENDAR";

      var data = new File([iCal], { type: "text/plain" });

      // Manually revoke the object URL to avoid memory leaks
      if (this.icsURL !== null) {
        window.URL.revokeObjectURL(this.icsURL);
      }

      let icsFile = window.URL.createObjectURL(data);

      if (icsFile) {
        this.icsURL = icsFile;
        this.icsFileName = this.user
          ? this.user.realname + "-mogul-event.ics"
          : "mogul-event.ics";
      }
      this.$refs.refModal.close();
    },

    exportAllEvents() {
      this.icsFileName = null;
      let icsEvents = "";

      this.calendar.map((cal) => {
        icsEvents = icsEvents + this.parseEvent(cal);
      });

      var iCalender =
        "BEGIN:VCALENDAR\n" +
        "VERSION:2.0\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "PRODID:Mogul iCalender\n" +
        icsEvents +
        "END:VCALENDAR";

      let data = new File([iCalender], { type: "text/plain" });

      if (this.icsURL !== null) {
        window.URL.revokeObjectURL(this.icsURL);
      }

      let icsFile = window.URL.createObjectURL(data);

      if (icsFile) {
        this.icsURL = icsFile;
        this.icsFileName = this.user
          ? this.user.realname + "-mogul-events.ics"
          : "mogul-all-events.ics";
      }
    },
  },
};
</script>

<style lang="scss">
$day-border: 1px solid #b8c2cc;
$day-border-highlight: 1px solid #b8c2cc;
$day-width: 90px;
$day-height: 90px;
$weekday-bg: #f8fafc;
$weekday-border: 1px solid #eaeaea;
.calendar-header {
  margin-bottom: 20px;
  select {
    max-width: 350px;
  }
}
.link {
  color: white;
}
.scrollbar {
  width: 0px;
}
.cursor {
  cursor: pointer;
}
.scrollbar-track {
  display: none;
}
.bg-red-600 {
  background: #e74c3c;
  border-radius: 3px;
  margin-top: 5px;
}
.custom-calendar.vc-container {
  border-radius: 0;
  width: 100%;
  .vc-header {
    background-color: #f1f5f8;
    padding: 40px;
    color: black;
  }
  .vc-weeks {
    padding: 0;
  }
  .vc-weekday {
    background-color: $weekday-bg;
    border-bottom: $weekday-border;
    border-top: $weekday-border;
    padding: 5px 0;
  }
  .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    min-height: $day-height;
    min-width: $day-width;
    overflow: auto;
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: $day-border;
      &.weekday-1 {
        border-bottom: $day-border-highlight;
      }
    }
    &:not(.on-right) {
      border-right: $day-border;
    }
  }
  .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
