import { createApp } from "vue";
import VCalendar from "v-calendar";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";
import Notifications from "@kyvg/vue3-notification";
import "v-calendar/style.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const app = createApp(App);

app.config.globalProperties.$http = axios;
app.config.productionTip = false;

app.use(VueSweetalert2);
app.use(store);
app.use(router);
app.use(VCalendar, {});
app.use(Notifications);

app.mount("#app");
