import { createStore } from "vuex";
import subscribe from "./modules/subscribe.store";
import auth from "./modules/auth.store";
import sidebar from "./modules/sidebar.store";
import issue from "./modules/issue.store";
import region from "./modules/region.store";
import user from "./modules/user.store";
import category from "./modules/category.store";
import agency from "./modules/agency.store";
import product from "./modules/product.store";
import customer from "./modules/customer.store";
import contact from "./modules/contact.store";
import address from "./modules/address.store";
import budget from "./modules/budget.store";
import order from "./modules/order.store";
import deal from "./modules/deal.store";

export default createStore({
  state: {
    baseUrl: process.env.VUE_APP_URL,
  },

  plugins: [subscribe],

  modules: {
    auth,
    sidebar,
    region,
    category,
    agency,
    product,
    customer,
    issue,
    user,
    budget,
    contact,
    address,
    order,
    deal,
  },

  mutations: {},

  actions: {},
});
