<template>
  <main>
    <div class="fullscreen-wrapper w-100">
      <button type="button" class="btn btn-outline-link toggle" @click="toggle">
        <i class="bi bi-fullscreen-exit" v-if="fullscreen"></i>
        <i class="bi bi-arrows-fullscreen" v-else></i>
      </button>
      <div class="fullscreen-content">
        <!-- <pre>{{ sales }}</pre> -->
        <div class="top-sales">
          <div class="latest-order">
            <div class="row g-2" v-if="!loading.orders && order">
              <div class="col-7">
                <div class="border bg-light issues">
                  <p class="company">
                    Senaste order:
                    {{ order.company }}
                  </p>
                  <p class="total">
                    {{ formatCurrency(order.total) }}
                  </p>
                  <table class="table-sm table-borderless" v-if="order.issues.length > 0">
                    <tbody>
                      <tr v-for="(issue, index) in order.issues" :key="index">
                        <td><i class="bi bi-check2-square"></i></td>
                        <td>villalivet #{{ issue }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-5">
                <figure class="figure">
                  <img :src="baseUrl + '/profile.php?userID=' + order.userID + '&key=' + apiKey" alt="Säljare"
                    height="100" class="img-thumbnail" />
                  <figcaption class="figure-caption text-center fw-bold">
                    Säljare: {{ order ? order.realname : "" }}
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="d-flex justify-content-center my-4" v-if="loading.orders && !order">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="offers">
            <div class="row">
              <div class="col-3">
                <h3>Offerter ute</h3>
              </div>
              <div class="col-9">
                <div class="charts" v-if="!loading.offers">
                  <pie-chart :width="300" :height="300" :labels="offers.labels" :datasets="offers.datasets"
                    :options="offers.options" :key="offersKey" />
                  <h5>Totalt värde: {{ formatCurrency(offerTotal) }}</h5>
                </div>
                <div class="d-flex justify-content-center my-4" v-if="loading.offers">
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sales-report">
          <div class="persons">
            <h3 class="text-center">Årets försäljning</h3>
            <div class="row" v-if="!loading.sellers">
              <template v-for="(seller, index) in sellers" :key="index">
                <div class="col" v-if="index <= 3">
                  <figure class="figure">
                    <img :src="baseUrl + '/profile.php?userID=' + seller.userID + '&key=' + apiKey" alt="Säljare" />
                    <figcaption class="figure-caption text-center fw-bold">
                      {{ seller.realname }}
                    </figcaption>
                    <p class="m-0 fw-bold text-center text-muted">
                      <small>{{ formatCurrency(seller.total) }}</small>
                    </p>
                  </figure>
                  <!-- <ul>
                    <li>Tidning</li>
                    <li>Trycksaker</li>
                    <li>Digitalt</li>
                  </ul> -->
                </div>
              </template>
            </div>
            <div class="d-flex justify-content-center my-4" v-if="loading.sellers">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="total-sales">
            <template v-if="!loading.start">
              <div class="weekly-sales">
                <h4>Veckans försäljning</h4>
                <p>
                  {{
                      start.currentWeek != null
                        ? formatCurrency(start.currentWeek)
                        : formatCurrency(0)
                  }}
                </p>
              </div>
              <div class="monthly-sales">
                <h4>Månadens försäljning</h4>
                <p>
                  {{
                      start.currentMonth != null
                        ? formatCurrency(start.currentMonth)
                        : formatCurrency(0)
                  }}
                </p>
              </div>
            </template>
            <div class="d-flex justify-content-center my-4" v-if="loading.start">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bars">
          <template v-if="!loading.editions">
            <div class="bar" v-for="(e, index) in editions" :key="index">
              <bar-chart :width="125" :height="offsetHeight" :labels="e.labels" :datasets="e.datasets"
                :options="e.options" :key="editionKey" v-if="isMounted" />
            </div>
          </template>
          <div class="d-flex justify-content-center align-self-center my-4" v-if="loading.editions">
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { api as fullscreen } from "vue-fullscreen";
import PieChart from "@/components/chart/PieChart.vue";
import BarChart from "@/components/chart/BarChart.vue";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
export default {
  name: "TvScreen",
  data() {
    return {
      order: null,
      sellers: [],
      offsetWidth: 0,
      offsetHeight: 0,
      isMounted: false,
      fullscreen: false,
      teleport: true,
      offersKey: 0,
      offerTotal: 0,
      offers: {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              align: "center",
              labels: {
                font: {
                  size: 14,
                  weight: "bold",
                },
              },
            },
          },
        },
      },
      editionKey: 0,
      editions: [],
      editionOptions: {
        responsive: false,
        scales: {
          y: {
            display: false,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "villalivet #1",
          },
        },
      },
      totalBudget: [],
      sales: null,
      timeout: null,
      loading: {
        start: false,
        orders: false,
        offers: false,
        sellers: false,
        editions: false,
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      start: (state) => state.user.start,
      users: (state) => state.budget.users,
    }),
  },

  components: {
    PieChart,
    BarChart,
  },

  mounted() {
    this.queryElement().then(() => (this.isMounted = true));
    this.updateTvScreen();
  },

  created() {
    window.addEventListener("resize", this.queryElement);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.queryElement);
    clearTimeout(this.timeout);
  },

  methods: {
    queryElement() {
      return new Promise((resolve) => {
        let bars = document.querySelector(".bars");
        this.offsetWidth = parseInt(bars.offsetWidth) - 10;
        this.offsetHeight = parseInt(bars.offsetHeight) - 10;
        this.editionKey++;
        resolve(true);
      });
    },

    async toggle() {
      await fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
          this.$store.commit("sidebar/SET_FULLSCREEN", this.fullscreen);
        },
      });
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: "SEK",
      });
    },

    updateTvScreen() {
      this.loading = {
        start: true,
        orders: true,
        offers: true,
        sellers: true,
      };
      this.offers.labels = [];
      this.offers.datasets = [];
      this.sales = null;
      this.$store.dispatch("user/fetchStart").then(() => {
        let orders = this.start.orders;
        this.order = orders.length > 0 ? orders[0] : null;
        this.sellers = this.start.sellers;

        let offers = _.groupBy(this.start.offers, (offer) => offer.realname);

        let datasets = [];
        for (const [key, value] of Object.entries(offers)) {
          let sum = 0;
          value.map((v) => {
            sum += v.total;
          });
          this.offers.labels.push(key + " (" + this.formatCurrency(sum) + ")");
          datasets.push(sum);
          this.offerTotal += sum;
        }
        this.offers.datasets.push({
          label: "Offetrer & Ordrar",
          data: datasets,
          backgroundColor: [
            "rgb(255 99 132)",
            "rgb(74 192 192)",
            "rgb(255 159 64)",
            "rgb(255 205 86)",
          ],
          hoverOffset: 4,
        });

        let year = moment().add(1, "years").format("YYYY");
        this.sales = this.start.issueSales.find((sale) => sale.label == year);

        this.updateBudget();

        this.loading = {
          start: false,
          orders: false,
          offers: false,
          sellers: false,
        };
        this.offersKey++;

        this.timeout = setTimeout(this.updateTvScreen, 60000);
      });
    },

    updateBudget() {
      this.loading.editions = true;
      let year = moment().format("YYYY");
      this.totalBudget = [];
      this.editions = [];
      this.$store.dispatch("budget/fetchUsers", year).then(() => {
        let temps = [];
        for (let i = 0; i < this.users.length; i++) {
          const newspapers = this.users[i].budget1;
          for (let j = 0; j < newspapers.length; j++) {
            let budgetNewspaper = newspapers[j] ?? 0;
            temps[j] = temps[j] ? temps[j] + budgetNewspaper : budgetNewspaper;
          }
        }
        this.totalBudget = temps.filter((temp) => temp);
        let budgetData = [];
        this.totalBudget.map((budget, index) => {
          let budgetIndex = index + 1;
          let sale = this.sales.data[index] ?? 0;
          budgetData.push(budget, sale);
          this.editions.push({
            labels: ["Budget", "Försäljning"],
            datasets: [
              {
                data: budgetData,
                backgroundColor: ["rgba(75, 192, 192)", "rgba(54, 162, 235)"],
              },
            ],
            options: {
              responsive: false,
              scales: {
                y: {
                  display: false,
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: "villalivet #" + budgetIndex,
                },
              },
            },
          });
          budgetData = [];
        });
        this.loading.editions = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-wrapper {
  background: #fff;
  position: relative;

  .d-flex {
    width: 100%;
  }

  .toggle {
    position: absolute;
    top: 0;
    left: 0;

    .bi {
      font-weight: 600;
      font-size: 20px;
    }

    &:hover {
      .bi {
        color: #21618c;
      }
    }
  }

  .fullscreen-content {
    height: 100vh;
    margin: 2.5rem;
    padding: 1rem;

    figcaption {
      margin-top: 5px;
    }

    .top-sales {
      display: flex;
      flex-direction: row;
      max-height: 31%;

      .latest-order,
      .offers {
        border: 2px solid #7f8c8d;
        border-radius: 3px;
        padding: 25px;
        background: #e5e8e8;
        max-height: 100%;
      }

      .latest-order {
        flex: 3;
        margin-right: 15px;
        overflow-y: scroll;

        .issues {
          padding: 20px !important;
          height: 100%;

          .company {
            font-size: 1rem;
          }

          .total {
            font-size: 25px;
            font-weight: 900;
          }

          table {
            margin-top: 5px;

            td {
              font-size: 14px;
              font-weight: 600;

              .bi {
                font-size: 15px;
              }
            }
          }
        }

        img {
          max-height: 20vh;
        }

        p {
          margin: 0;
          font-size: 1.15rem;
          font-weight: 600;
        }
      }

      .offers {
        flex: 4;
        padding: 0;

        h3 {
          padding-top: 15px;
          padding-left: 25px;
          font-weight: 600;
          font-size: 1.25rem;
        }

        .charts {
          padding: 25px;
          position: relative;
          height: 30vh;

          h5 {
            position: absolute;
            bottom: 10px;
            right: 60px;
            font-size: 19px;
          }
        }
      }
    }

    .sales-report {
      display: flex;
      flex-direction: row;
      height: 30%;
      margin-top: 10px;

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 25px;
      }

      .persons,
      .total-sales {
        padding: 25px;
        border: 2px solid #7f8c8d;
        border-radius: 3px;
      }

      .persons {
        flex: 5;
        background: #e5e8e8;
        margin-right: 15px;
        overflow-y: scroll;

        img {
          height: 15vh;
        }
      }

      .total-sales {
        flex: 3;
        background: #aeb6bf;

        .weekly-sales,
        .monthly-sales {
          padding-bottom: 1.5rem;

          h4 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #1a5276;
          }

          p {
            font-size: 2.35rem;
            font-weight: 600;
          }
        }
      }
    }

    .bars {
      display: inline-flex;
      flex-direction: row;
      border: 2px solid #7f8c8d;
      width: 100%;
      margin-top: 10px;
      height: 32%;

      .bar {
        flex: 1;
        padding: 10px;

        &:not(:last-child) {
          border-right: 2px solid #7f8c8d;
        }

        canvas {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
