<template>
  <main>
    <div class="home h-100 w-100">
      <navbar :title="navbar" />
      <div class="content p-3">
        <!-- <pre>{{ sales.datasets }}</pre> -->
        <div class="stats">
          <div class="chart weeksales row1">
            <h5 class="text-uppercase">
              <i class="bi bi-trophy"></i>

              <router-link :to="{ name: 'Orders' }">
                Veckans försäljning</router-link
              >
            </h5>
            <p class="fw-bolder fs-1">
              {{
                start.currentWeek != null
                  ? formatCurrency(start.currentWeek)
                  : formatCurrency(0)
              }}
            </p>
            <h5 class="text-uppercase">
              <router-link :to="{ name: 'Orders' }"
                ><i class="bi bi-trophy-fill"></i> Månadens försäljning
              </router-link>
            </h5>
            <p class="fw-bolder fs-1">
              {{
                start.currentMonth != null
                  ? formatCurrency(start.currentMonth)
                  : formatCurrency(0)
              }}
            </p>
          </div>
          <div class="chart monthsales row1">
            <h5 class="text-uppercase">
              <i class="bi bi-newspaper"></i> Försäljning per utgåva
            </h5>
            <!-- <bar-chart :width="200" :height="130" /> -->
            <bar-chart
              :width="200"
              :height="200"
              :labels="edition.labels"
              :datasets="edition.datasets"
              :options="edition.options"
              :key="editionKey"
            />
          </div>
          <div class="chart nextedition row1">
            <h5 class="text-uppercase">
              <i class="bi bi-wallet2"></i> Offerter och ordrar
            </h5>
            <pie-chart
              :width="200"
              :height="200"
              :labels="offers.labels"
              :datasets="offers.datasets"
              :options="offers.options"
              :key="offersKey"
            />
          </div>
          <div class="chart mysales row1">
            <h5 class="text-uppercase">
              <i class="bi bi-cash-coin"></i> Min försäljning i år
            </h5>
            <bar-chart
              :width="200"
              :height="200"
              :labels="sales.labels"
              :datasets="sales.datasets"
              :options="sales.options"
              :key="salesKey"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="stats">
            <div class="box sales activitys row2">
              <h5 class="text-uppercase">
                {{ start.actWeek }} aktivitet{{
                  start.actWeek != 1 ? "er" : ""
                }}
                i dag
              </h5>
              <ol v-if="start.activitiesWeek">
                <li v-for="(r, index) in start.activitiesWeek" :key="index">
                  <router-link
                    v-if="r.personID"
                    :to="{
                      name: 'ShowCustomerContact',
                      params: {
                        customerID: r.customerID,
                        personID: r.personID,
                      },
                    }"
                  >
                    {{ r.company }}</router-link
                  >
                  <router-link
                    v-if="r.customerID && !r.personID"
                    :to="{
                      name: 'ShowCustomer',
                      params: {
                        customerID: r.customerID,
                      },
                    }"
                  >
                    {{ r.company }}</router-link
                  >
                  {{
                    r.eventTime != null &&
                    r.eventTime.length > 16 &&
                    r.eventTime.substring(11, 16) != "00:00"
                      ? "(" + r.eventTime.substring(11, 16) + ")"
                      : ""
                  }}
                </li>
              </ol>
            </div>
            <div class="box products offerout row2">
              <h5 class="text-uppercase">
                {{ start.nOffers }} offert{{ start.nOffers != 1 ? "er" : "" }}
                ute
              </h5>
              <table class="table table-striped" v-if="start.offers">
                <tbody>
                  <tr v-for="(r, index) in start.offers" :key="index">
                    <td>{{ r.company }}</td>
                    <td style="text-align: right">
                      <router-link
                        v-if="r.personID"
                        :to="{
                          name: 'ShowCustomerContact',
                          params: {
                            customerID: r.customerID,
                            personID: r.personID,
                          },
                        }"
                      >
                        {{ formatCurrency(r.total) }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box company row2">
              <h5 class="text-uppercase">Senaste ordrar</h5>
              <table class="table table-striped">
                <tbody>
                  <tr v-for="(r, index) in start.orders" :key="index">
                    <td>{{ r.company }}</td>
                    <td style="text-align: right">
                      <router-link
                        :to="{
                          name: 'ShowCustomerDeal',
                          params: {
                            customerID: r.customerID,
                            personID: r.personID,
                            dealID: r.dealID,
                          },
                        }"
                      >
                        {{ formatCurrency(r.total) }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="box totalyear row2">
              <h5 class="text-uppercase">Totalt i år</h5>

              <p class="fw-bolder fs-1">
                {{
                  start.yearTotal != null ? formatCurrency(start.yearTotal) : ""
                }}
              </p>
              <ol
                class="col-12 toplist"
                v-if="accessLevel == 'admin' && start.sellers != null"
              >
                <li v-for="(r, index) in start.sellers" :key="index">
                  {{ r.realname }}
                  <span style="float: right">{{
                    formatCurrency(r.total)
                  }}</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="row" v-if="accessLevel == 'admin'">
          <div class="stats">
            <div class="box activeusers">
              <h5 class="text-uppercase">
                <i class="bi bi-people-fill"></i>

                {{ start.nUsers }} aktiv{{ start.nUsers != 1 ? "a" : " " }}
                användare
              </h5>
              <table class="table table-striped" v-if="start.users">
                <tr v-for="(r, index) in start.users" :key="index">
                  <td>{{ r.realname }}</td>
                  <td style="text-align: right">
                    {{ r.ts.substring(11, 16) }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="box latestactive">
              <h5 class="text-uppercase">
                <i class="bi bi-people-fill"></i> Senast aktiva
              </h5>
              <table class="table table-striped" v-if="start.usersTs">
                <tr v-for="(r, index) in start.usersTs" :key="index">
                  <td>{{ r.realname }}</td>
                  <td style="text-align: right">{{ r.ts }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import BarChart from "@/components/chart/BarChart.vue";
import PieChart from "@/components/chart/PieChart.vue";

export default {
  name: "user-start",
  data() {
    return {
      navbar: "Start",
      budgetPrint: 0,
      budgetDigital: 0,
      salesPrint: 0,
      salesDigital: 0,
      editionKey: 0,
      offersKey: 0,
      salesKey: 0,
      edition: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8],
        datasets: [],
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      },
      offers: {
        labels: ["Offerter", "Ordrar"],
        datasets: [
          {
            label: "Offetrer & Ordrar",
            data: [],
            backgroundColor: ["rgb(220, 220, 220)", "rgb(169, 169, 169)"],
            hoverOffset: 4,
          },
        ],
        options: {
          responsive: true,
        },
      },
      sales: {
        labels: ["Tryck", "Digital"],
        datasets: [],
        options: {
          plugins: {
            legend: { display: false },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      },
    };
  },

  components: {
    Navbar,
    BarChart,
    PieChart,
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      apiKey: (state) => state.auth.apiKey,
      start: (state) => state.user.start,
      history: (state) => state.customer.searchHistory,
      accessLevel: (state) => state.auth.accessLevel,
      salesYear: (state) => state.sidebar.salesYear,
    }),
  },

  watch: {
    start() {
      if (this.start.issueSales.length > 0) {
        this.edition.datasets = this.start.issueSales;
        this.editionKey++;
      }

      if (this.start.ooo) {
        this.offers.datasets[0].data = [];
        this.offers.datasets[0].data.push(
          this.start.ooo.offerter,
          this.start.ooo.ordrar
        );
        this.offersKey++;
      }
    },
  },

  mounted() {
    this.checkRouteName();

    document.title = "Start";
    this.$store.dispatch("user/fetchStart");
    this.$store.dispatch("customer/fetchHistory");
    this.fetchCurrentSales();
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    formatCurrency(price) {
      return price.toLocaleString("sv-SE", {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: "SEK",
      });
    },

    fetchCurrentSales() {
      this.$http
        .get(this.baseUrl + "/get.php?type=currentSales&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.budgetPrint = response.data.data.budget[0].total ?? 0;
            this.budgetDigital = response.data.data.budget[1].total ?? 0;
            this.salesPrint = response.data.data.print[0].total ?? 0;
            this.salesDigital = response.data.data.digital[0].total ?? 0;

            this.salesPerYear();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    salesPerYear() {
      this.sales.datasets = [];
      this.sales.datasets.push({
        label: "Försäljning",
        data: [
          this.salesPrint < this.budgetPrint
            ? this.salesPrint
            : this.budgetPrint,
          this.salesDigital < this.budgetDigital
            ? this.salesDigital
            : this.budgetDigital,
        ],
        backgroundColor: "rgba(70,130,180)",
      });

      let printColor =
        this.salesPrint >= this.budgetPrint
          ? "rgba(75, 192, 192)"
          : "rgb(255, 99, 71)";
      let digitalColor =
        this.salesDigital >= this.budgetDigital
          ? "rgba(75, 192, 192)"
          : "rgb(255, 99, 71)";

      this.sales.datasets.push({
        label: "Budget",
        data: [
          this.budgetPrint < this.salesPrint
            ? this.salesPrint - this.budgetPrint
            : this.budgetPrint - this.salesPrint,
          this.budgetDigital < this.salesDigital
            ? this.salesDigital - this.budgetDigital
            : this.budgetDigital - this.salesDigital,
        ],
        backgroundColor: [printColor, digitalColor],
      });
      this.salesKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: top;
    margin-bottom: 20px;

    .chart {
      background: #fff;
      width: 100%;
      overflow: auto;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      padding: 15px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    .box {
      background: #fff;
      width: 100%;
      height: 300px;
      overflow: auto;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      padding: 15px;

      h5 {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 1px;

        i {
          font-size: 18px;
        }
      }

      a:link {
        letter-spacing: 1.2px;
        line-height: 1.5;
        font-size: 16px;
        text-underline-offset: 1px;
      }

      p {
        letter-spacing: 1.2px;
        line-height: 1.5;
        font-size: 16px;
      }
    }

    .box:not(:last-child) {
      margin-right: 20px;
    }

    .activitys {
      background-color: #e6d6e6;
    }

    .offerout {
      background-color: #d5e0d9;
    }

    .company {
      background-color: #d5e0d9;
    }

    .company td {
      background-color: #d6e1d9;
    }

    .company a:link a:visited {
      color: black;
      background-color: none;
    }

    .mysales {
      background-color: #c9d2df;
    }

    .weeksales {
      background-color: #c9d1df;
    }

    .monthsales {
      background-color: #c9dee3;
    }

    .nextedition {
      background-color: #cac5e6;
      // .charts {
      //   max-width: 230px;
      //   max-height: 230px;
      // }
      // canvas {
      //   width: 230px !important;
      //   height: 230px !important;
      //   display: flex !important;
      //   align-items: center !important;
      // }
    }

    .totalyear {
      background-color: #d6e1d9;
    }

    .activeusers {
      background-color: #e6d5e6;
    }

    .latestactive {
      background-color: #cccddb;
    }
  }

  .users {
    background-color: #196e7d;
  }
}
</style>
