import axios from "../../axios";

const state = {
  users: [],
};

const mutations = {
  FETCH_USERS(state, payload) {
    state.users = payload;
  },
};

const actions = {
  fetchUsers({ rootState, commit }, year) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("year", year);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=budgetUsers", formData)
        .then((response) => {
          commit("FETCH_USERS", response.data.data);
          resolve(response);
        })
        .then((error) => {
          reject(error);
        });
    });
  },

  setUserBudget({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("userID", data.userID);
      formData.append("period", data.period);
      formData.append("categoryID", data.categoryID);
      formData.append("budget", data.budget);
      formData.append("key", rootState.auth.apiKey);

      axios
        .post(rootState.baseUrl + "/edit.php?type=setUserBudget", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
