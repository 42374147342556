<template>
  <main>
    <div class="magazines w-100 h-100">
      <navbar title="Tidningar" />
      <div class="content p-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end py-2">
              <button
                type="button"
                class="btn btn-secondary float-end"
                @click="addMagazine()"
              >
                <i class="bi bi-plus-circle-fill"></i> Lägg till tidning
              </button>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="border-bottom border-top">Upplaga</th>
                  <th class="border-bottom border-top">Tidningsår</th>
                  <th class="border-bottom border-top">Budget</th>
                  <th class="border-bottom border-top">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(magazine, index) in magazines" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'Issue',
                        params: {
                          magazineID: magazine.magazineID,
                          selectedYear: magazine.magazineYear,
                        },
                      }"
                    >
                      {{ magazine.magazineName }}</router-link
                    >
                  </td>
                  <td>{{ magazine.magazineYear }}</td>
                  <td v-if="magazine.annualBudget">
                    {{
                      magazine.annualBudget.toLocaleString("sv-SE", {
                        style: "currency",
                        currency: "SEK",
                        maximumFractionDigits: 0,
                      })
                    }}
                  </td>
                  <td class="actions">
                    <button
                      type="button"
                      class="btn btn-default"
                      @click="deleteMagazine(magazine)"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      @click="editMagazine(magazine)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="row g-3 mb-3">
          <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
            <p
              class="mb-1 fst-italic"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
          <div class="mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Upplaga"
              v-model="magazineName"
            />
          </div>
          <div class="d-grid gap-2 mb-3">
            <button
              type="button"
              class="btn btn-secondary fw-bold"
              v-if="!editable"
              @click="saveMagazine()"
            >
              Spara
            </button>
            <button
              type="button"
              class="btn btn-secondary fw-bold"
              v-if="editable"
              @click="updateMagazine()"
            >
              Uppdatera
            </button>
          </div>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "../components/Navbar.vue";
import Modal from "@/components/Modal.vue";
export default {
  name: "admin-magazines",
  data() {
    return {
      modalTitle: "Lägg till tidning",
      magazines: [],
      magazineID: null,
      magazineName: null,
      magazineYear: null,
      annualBudget: null,
      editable: false,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: { Navbar, Modal },

  mounted() {
    this.checkRouteName();

    document.title = "Tidningar";
    this.fetchMagazines();
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    addMagazine() {
      this.$refs.refModal.open();
    },

    fetchMagazines() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http
        .post(this.baseUrl + "/get.php?type=magazines", formData)
        .then((response) => {
          this.magazines = response.data.data;
        });
    },

    // magazineName, magazineYear, annualBudget
    saveMagazine() {
      this.errors = [];
      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("magazineName", this.magazineName);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=addMagazine", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchMagazines();
              this.resetForm();
              this.$refs.refModal.close();
            }
          });
      }
    },

    openIssues(magazine) {
      this.$router.push({
        name: "Issue",
        params: { magazineID: magazine.magazineID },
      });
    },

    editMagazine(magazine) {
      this.magazineID = magazine.magazineID;
      this.magazineName = magazine.magazineName;
      this.modalTitle = "Redigera tidning";
      this.editable = true;
      this.$refs.refModal.open();
    },

    updateMagazine() {
      this.errors = [];
      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("magazineID", this.magazineID);
        formData.append("magazineName", this.magazineName);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateMagazine", formData)
          .then((response) => {
            if (response.data.status === "ok") {
              this.resetForm();
              this.fetchMagazines();
              this.$refs.refModal.close();
            }
          });
      }
    },

    deleteMagazine(magazine) {
      console.log(magazine);
      this.$swal({
        title: "Vill du verkligen radera?",
        text: "Du kommer inte att kunna återställa detta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, radera!",
        cancelButtonText: "Avbryt",
      }).then((result) => {
        if (result.isConfirmed) {
          // Call http request to delete
        }
      });
    },

    validateForm() {
      let hasError = false;
      if (!this.magazineName) {
        hasError = true;
        this.errors.push("Ange upplaga");
      }
      return hasError;
    },

    resetForm() {
      this.magazineName = null;
      this.magazineYear = null;
      this.annualBudget = 0;
      this.release = null;
      this.materialDay = null;
    },

    clear() {
      this.resetForm();
      this.errors = [];
      this.editable = false;
    },
  },
};
</script>
