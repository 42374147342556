<template>
  <canvas id="stackedChart" width="400" height="400"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import stackedChartData from "@/charts/stacked-chart.js";
export default {
  name: "stackedChart",
  data() {
    return {
      chartData: stackedChartData,
    };
  },

  mounted() {
    const ctx = document.getElementById("stackedChart");
    ctx.width = 250;
    ctx.height = 250;
    new Chart(ctx, this.chartData);
  },
};
</script>
