<template>
  <div class="user-box">
    <img :src="baseUrl + '/profile.php?userID=0&key=' + apiKey" height="100" style="margin-bottom: 12px;"/>
    <p class="text-center">{{ realname }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["realname"],
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

};
</script>

<style lang="scss" scoped>
.user-box {
  text-align: center;
  padding-top: 12px;
}
img {
  margin-top: 1rem;
}
.sidebar-header {
  background-color: #d7e8ee;
  color: #333334;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
