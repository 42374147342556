import axios from "../../axios";

const state = {
  deals: [],
};

const mutations = {
  FETCH_DEALS(state, payload) {
    state.deals = payload;
  },
};

const actions = {
  fetchDeals({ rootState, commit }, dealID) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", dealID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=dealContent", formData)
        .then((response) => {
          commit("FETCH_DEALS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  assign({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("userID", data.userID);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=assignDeal", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addFee({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", data.dealID);
      formData.append("proc", data.proc);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addFee", formData)
        .then((response) => {
          if (response.data.status == "ok") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addDeal({ rootState, dispatch }, deal) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("dealID", deal.dealID);
      formData.append("productID", deal.productID);
      formData.append("quantity", deal.quantity ?? null);
      formData.append("discount", deal.discount ?? null);
      formData.append("issueID", deal.issueID ?? null);
      formData.append("productDate", deal.productDate ?? null);
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/edit.php?type=addDealContent", formData)
        .then((response) => {
          if (response.data.status === "ok") {
            dispatch("fetchDeals");
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
