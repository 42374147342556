import axios from "../../axios";

// State objects
const state = {
  apiKey: JSON.parse(localStorage.getItem("_mogul_apiKey")) || null,
  isAuthenticated: JSON.parse(localStorage.getItem("_mogul_apiKey")),
  realname: JSON.parse(localStorage.getItem("_mogul_realname")) || null,
  accessLevel: JSON.parse(localStorage.getItem("_mogul_accessLevel")) || null,
};

// Getter functions
const getters = {};

// Mutations
const mutations = {
  AUTH_LOGIN(state, payload) {
    state.isAuthenticated = payload.key.length > 0;
    state.realname = payload.realname;
    state.apiKey = payload.key;
    state.accessLevel = payload.accessLevel;
    localStorage.setItem("_mogul_apiKey", JSON.stringify(payload.key));
  },

  AUTH_LOGOUT(state) {
    state.apiKey = null;
    state.isAuthenticated = false;
    state.realname = null;
    state.accessLevel = null;
  },
};

// Actions
const actions = {
  authLogin({ rootState }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("user", data.email);
      formData.append("pass", data.password);
      axios
        .post(rootState.baseUrl + "/auth.php", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  authLogout({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/logout.php", formData)
        .then((response) => {
          if (response.data.status === "error") {
            commit("AUTH_LOGOUT");
            window.location.href = "/login";
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
