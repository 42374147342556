<template>
  <main>
    <div class="contacts h-100 w-100">
      <navbar :title="navbar" />
      <div class="content p-3">
        <div class="card pb-3">
          <div class="card-body">
            <div class="d-flex justify-content-end py-2">
              <button
                type="button"
                class="btn btn-secondary float-end"
                @click="addCompany"
              >
                <i class="bi bi-plus-circle-fill"></i> Ny kontakt
              </button>
            </div>
            <template v-if="sortedCompanies.length > 0 && !loading">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" @click="sortBy('personName')">
                      Kontaktperson<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col">Titel</th>
                    <th scope="col" @click="sortBy('company')">
                      Företag<i class="bi bi-arrow-down-up mx-2"></i>
                    </th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Mobil</th>
                    <th scope="col">E-post</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(company, index) in sortedCompanies" :key="index">
                    <td @click="showCustomer(company)">
                      {{ company.personName }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{ company.title }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{ company.company }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{ company.phone }}
                    </td>
                    <td @click="showCustomer(company)">
                      {{ company.mobile }}
                    </td>
                    <td @click="showCustomer(customer)">
                      {{ company.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                v-if="pagination.last_page > 1"
                :pagination="pagination"
                :offset="5"
                @paginate="paginate"
              />
            </template>
            <div
              class="alert alert-info"
              v-if="sortedCompanies.length === 0 && !loading"
            >
              <p class="m-0 fst-italic">Inget företag och kontakter hittades</p>
            </div>
          </div>
        </div>

        <div class="card my-2" v-if="loading">
          <div class="card-body">
            <pulse-loader :loading="loading" class="p-3"></pulse-loader>
          </div>
        </div>

        <!-- <pre>{{ customers.slice(4, 6) }}</pre> -->
      </div>

      <modal ref="refModal" :title="modalTitle" @hidden="clear()">
        <div class="row g-3 mb-3">
          <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
            <p
              class="m-0 fst-italic"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
          <fieldset class="border p-2 rounded">
            <legend class="fs-5">Grunduppgifter</legend>
            <div class="row g-3 mb-3">
              <div class="col-md-4">
                <label for="company" class="form-label">Företagsnamn</label>
                <input
                  type="text"
                  class="form-control"
                  id="company"
                  v-model="company"
                />
              </div>
              <div class="col-md-4">
                <label for="orgnr" class="form-label">Organisationsnr</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Tio siffror"
                  id="orgnr"
                  v-model="orgnr"
                />
              </div>
              <div class="col-md-4">
                <label for="kundnummer" class="form-label">Kundnummer</label>
                <input
                  type="number"
                  class="form-control"
                  id="kundnummer"
                  v-model="customerNr"
                />
              </div>
              <div class="col-md-6">
                <label for="vatnr" class="form-label">VAT</label>
                <input
                  type="text"
                  class="form-control"
                  id="vatnr"
                  v-model="vatnr"
                />
              </div>
              <div class="col-md-6">
                <label for="status" class="form-label">Status</label>
                <input
                  type="text"
                  class="form-control"
                  id="status"
                  v-model="status"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="border p-3 rounded">
            <div class="row g-3 mb-3">
              <legend class="fs-5">Kontaktuppgifter</legend>
              <div class="col-md-4">
                <label for="email" class="form-label">E-postadress</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  v-model="email"
                />
              </div>
              <div class="col-md-4">
                <label for="billing-email" class="form-label"
                  >Fakturaadress</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="E-post"
                  id="billing-email"
                  v-model="billingEmail"
                />
              </div>
              <div class="col-md-4">
                <label for="website" class="form-label">Hemsida</label>
                <input
                  type="text"
                  class="form-control"
                  id="website"
                  v-model="website"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="border p-3 rounded">
            <div class="row g-3 mb-3">
              <legend class="fs-5">Övrigt om företaget</legend>
              <div class="col-md-4">
                <label for="payment-terms" class="form-label"
                  >Betalningsvillkor</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="payment-terms"
                  v-model="paymentTerms"
                />
              </div>
              <div class="col-md-4">
                <label for="category" class="form-label">Välj bransch</label>
                <select id="category" class="form-control" v-model="category">
                  <option :value="null">---</option>
                  <option
                    :value="category"
                    v-for="(category, index) in categories"
                    :key="index"
                  >
                    {{ category.category }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="region" class="form-label">Välj region</label>
                <select id="region" class="form-control" v-model="region">
                  <option :value="null">---</option>
                  <option
                    :value="region"
                    v-for="(region, index) in regions"
                    :key="index"
                  >
                    {{ region.region }}
                  </option>
                </select>
              </div>
              <div class="col-md-12">
                <label for="comment" class="form-label">Kommentar</label>
                <textarea
                  class="form-control"
                  id="comment"
                  rows="3"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="d-grid gap-2 mb-3">
          <button
            type="button"
            class="btn btn-secondary fw-bold"
            v-if="!editable"
            @click="addCustomer()"
          >
            Spara
          </button>
          <button
            type="button"
            class="btn btn-secondary fw-bold"
            v-if="editable"
            @click="updateCustomer()"
          >
            Uppdatera
          </button>
        </div>
      </modal>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Modal from "@/components/Modal.vue";
import { mapState } from "vuex";
import Pagination from "@/components/Pagination.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "customer-contacts",
  data() {
    return {
      companies: [],
      navbar: "Kontakter",
      modalTitle: null,
      customerID: null,
      company: null,
      orgnr: null,
      customerNr: null,
      email: null,
      billingEmail: null,
      vatnr: null,
      paymentTerms: null,
      category: null,
      region: null,
      parentCustomerID: null,
      website: null,
      status: null,
      comment: null,
      contactPersons: [],
      errors: [],
      editable: false,
      contacts: [],
      pagination: {
        current_page: 1,
        last_page: 0,
        size_page: 20,
        start_index: 0,
        end_index: 2,
      },
      currentSort: "personName",
      sortDirection: "asc",
      loading: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      customers: (state) => state.contact.contacts,
      regions: (state) => state.region.regions,
      agencies: (state) => state.agency.agencies,
      categories: (state) => state.category.categories,
    }),

    sortedCompanies() {
      return this.companies.slice().sort((a, b) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },

  components: {
    Navbar,
    Modal,
    // Customer,
    Pagination,
    PulseLoader,
  },

  mounted() {
    document.title = "Kontakter";
    this.loading = true;
    this.$store.dispatch("contact/fetchAllContacts").then(() => {
      this.loading = false;
      this.companies = this.customers.slice(
        this.pagination.start_index,
        this.pagination.end_index
      );
      this.pagination.last_page = Math.ceil(
        this.customers.length / this.pagination.size_page
      );

      let pageQuery = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : this.pagination.current_page;
      this.pagination = {
        current_page: pageQuery,
        last_page: this.pagination.last_page,
        size_page: this.pagination.size_page,
        start_index:
          pageQuery * this.pagination.size_page - this.pagination.size_page,
        end_index: pageQuery * this.pagination.size_page,
      };
      this.companies = this.customers.slice(
        this.pagination.start_index,
        this.pagination.end_index
      );

      this.$router.replace({ query: { page: pageQuery } });
    });
    this.$store.dispatch("agency/fetchAgency");
    this.$store.dispatch("category/fetchCategory");
    this.$store.dispatch("region/fetchRegions");
  },

  methods: {
    sortBy(column) {
      if (column === this.currentSort) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.currentSort = column;
    },

    paginate(page) {
      let currentPage = !page
        ? parseInt(this.pagination.current_page)
        : parseInt(page);
      let startIndex =
        currentPage * this.pagination.size_page - this.pagination.size_page;
      let endIndex = currentPage * this.pagination.size_page;
      this.pagination = {
        current_page: currentPage,
        last_page: this.pagination.last_page,
        size_page: this.pagination.size_page,
        start_index: startIndex,
        end_index: endIndex,
      };
      this.companies = [];
      this.companies = this.customers.slice(
        this.pagination.start_index,
        this.pagination.end_index
      );

      this.$router.replace({ query: { page: currentPage } });
    },

    fetchCustomers() {
      this.$store.dispatch("customer/fetchCustomers");
    },

    getCategory(categoryID) {
      if (this.categories.length > 0) {
        let category = this.categories.find(
          (category) => category.categoryID === categoryID
        );
        return category.category;
      }
    },

    getRegion(regionID) {
      if (this.regions.length > 0) {
        let region = this.regions.find(
          (region) => region.regionID === regionID
        );
        return region.region;
      }
    },

    addCompany() {
      this.modalTitle = "Lägg till företag";
      this.$refs.refModal.open();
    },

    fetchAgencies() {
      let formData = new FormData();
      formData.append("key", this.apiKey);
      this.$http.post(this.baseUrl + "/get.php?type=agency", formData);
    },

    // type=addCustomer
    addCustomer() {
      this.errors = [];

      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("company", this.company);
        formData.append("orgnr", this.orgnr);
        formData.append("customerNr", this.customerNr);
        formData.append("email", this.email);
        formData.append("billingEmail", this.billingEmail);
        formData.append("vatnr", this.vatnr);
        formData.append("paymentTerms", this.paymentTerms);
        formData.append("categoryID", this.category.categoryID);
        formData.append("regionID", this.region.regionID);
        // formData.append("parentCustomerID");
        formData.append("website", this.website);
        formData.append("status", this.status);
        formData.append("comment", this.comment);

        this.$http
          .post(this.baseUrl + "/edit.php?type=addCustomer", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.resetForm();
              this.$store.dispatch("customer/fetchCustomers");
              this.$refs.refModal.close();
            }
          });
      }
    },

    validateForm() {
      let hasError = false;
      if (!this.company) {
        hasError = true;
        this.errors.push("Ange företag namn");
      }
      if (!this.orgnr) {
        hasError = true;
        this.errors.push("Du måste ange organisation nummer");
      }
      if (!this.email) {
        hasError = true;
        this.errors.push("Ange e-postadress");
      }
      if (!this.category) {
        hasError = true;
        this.errors.push("Välj kategori");
      }
      if (!this.region) {
        hasError = true;
        this.errors.push("Välj region");
      }
      return hasError;
    },

    resetForm() {
      this.company = null;
      this.orgnr = null;
      this.customerNr = null;
      this.email = null;
      this.billingEmail = null;
      this.vatnr = null;
      this.paymentTerms = null;
      this.category = null;
      this.region = null;
      this.website = null;
      this.status = null;
      this.comment = null;
    },

    showCustomer(customer) {
      this.$router.push({
        name: "ShowCustomer",
        params: { customerID: customer.customerID },
      });
    },

    editCustomer(customer) {
      this.customerID = customer.customerID;
      this.company = customer.company;
      this.orgnr = customer.orgnr;
      this.customerNr = customer.customerNr;
      this.email = customer.email;
      this.billingEmail = customer.billingEmail;
      this.vatnr = customer.vatnr;
      this.paymentTerms = customer.paymentTerms;
      this.category = this.categories.find(
        (category) => category.categoryID == customer.categoryID
      );
      this.region = this.regions.find(
        (region) => region.regionID === customer.regionID
      );
      this.website = customer.website;
      this.status = customer.status;
      this.comment = customer.comment;

      this.fetchContactPersons();
      this.fetchAgencies();

      this.editable = true;
      this.modalTitle = "Redigera företag";
      this.$refs.refModal.open();
    },

    updateCustomer() {
      this.errors = [];
      if (!this.validateForm()) {
        let formData = new FormData();
        formData.append("customerID", this.customerID);
        formData.append("company", this.company);
        formData.append("orgnr", this.orgnr);
        formData.append("customerNr", this.customerNr);
        formData.append("email", this.email);
        formData.append("billingEmail", this.billingEmail);
        formData.append("vatnr", this.vatnr);
        formData.append("paymentTerms", this.paymentTerms);
        formData.append("categoryID", this.category.categoryID);
        formData.append("regionID", this.region.regionID);
        // formData.append("parentCustomerID");
        formData.append("website", this.website);
        formData.append("status", this.status);
        formData.append("comment", this.comment);

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateCustomer", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.resetForm();
              this.$store.dispatch("customer/fetchCustomers");
              this.$refs.refModal.close();
            }
          });
      }
    },

    deleteCustomer() {
      this.$swal({
        title: "Vill du verkligen radera?",
        text: "Du kommer inte att kunna återställa detta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, radera!",
        cancelButtonText: "Avbryt",
      }).then((result) => {
        if (result.isConfirmed) {
          // Call http request to delete
        }
      });
    },

    clear() {
      this.resetForm();
      this.errors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  .table {
    thead {
      th {
        cursor: pointer;
        padding: 0.8rem;
        &:hover {
          color: #34495e;
          background: #f0f8fe;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background: #d6eaf8;
        }
      }
    }
  }
  .modal {
    .alert p {
      font-size: 14px;
    }
    fieldset {
      background: #f8f9f9;
    }
    .btn-link {
      text-decoration: none;
    }
    .contacts {
      .row {
        background: #f1f1f1;
        position: relative;
        .close {
          margin: 0;
          padding: 0;
          position: absolute;
          top: -5px;
          right: -5px;
          width: auto;
          cursor: pointer;
        }
      }
    }
    .media {
      .row {
        background: #f1f1f1;
        position: relative;
        .close {
          margin: 0;
          padding: 0;
          position: absolute;
          top: -5px;
          right: -5px;
          width: auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
