<template>
  <main>
    <div class="orders w-100 h-100">
      <navbar title="Offert" />
      <div class="content p-3">
        <div class="row">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div class="mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="product"
                  >
                    <option :value="null">Välj produkt</option>
                    <option
                      :value="product"
                      v-for="(product, index) in products"
                      :key="index"
                    >
                      {{ product.productName }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <input
                    type="number"
                    class="form-control"
                    id="price"
                    placeholder="Antal"
                    v-model="quantity"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="number"
                    class="form-control"
                    id="price"
                    placeholder="Rabatt"
                    v-model="discount"
                  />
                </div>
                <div class="d-grid gap-2 mb-3">
                  <button
                    class="btn btn-secondary fw-bold"
                    type="button"
                    @click="saveDeal()"
                  >
                    Spara
                  </button>
                </div>
              </div>

              <div class="deals p-3">
                {{ deals }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";
export default {
  name: "DealContent",
  data() {
    return {
      dealID: null,
      product: null,
      quantity: null,
      discount: null,
    };
  },

  computed: {
    ...mapState({
      products: (state) => state.product.products,
      deals: (state) => state.deal.deals,
    }),
  },

  components: { Navbar },

  mounted() {
    this.dealID = parseInt(this.$route.params.dealID);
    this.$store.dispatch("product/fetchProducts");
    this.$store.dispatch("deal/fetchDeals", this.dealID);
  },

  methods: {
    // edit.php?type=addDealContent
    async saveDeal() {
      let response = await this.$store.dispatch("deal/addDeal", {
        dealID: this.dealID,
        productID: this.product.productID,
        quantity: this.quantity,
        discount: this.discount,
      });

      if (response) {
        this.$store.dispatch("deal/fetchDeals");
      }
    },
  },
};
</script>
