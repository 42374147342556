<template>
  <main>
    <div class="category h-100 w-100">
      <navbar title="Kategori" />
      <div class="content p-3">
        <div class="row">
          <div class="col-lg-5">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">Lägg till bransch</h5>
                <div class="mb-3">
                  <label class="form-label">Bransch</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Ange bransch namn"
                    v-model="category"
                  />
                </div>
                <div class="d-grid gap-3">
                  <button
                    type="button"
                    class="btn btn-success fw-bold"
                    v-if="!editable"
                    @click="addCategory()"
                  >
                    Spara
                  </button>
                  <button
                    class="btn btn-success fw-bold"
                    type="button"
                    v-if="editable"
                    @click="updateCategory()"
                  >
                    Uppdatera
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary fw-bold"
                    v-if="editable"
                    @click="close"
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card">
              <div class="card-body">
                <h5 class="fw-light fst-italic mb-3">Branscher</h5>
                <table class="table table-striped" v-if="categories.length > 0">
                  <thead>
                    <tr>
                      <th class="border-bottom border-top">Bransch</th>
                      <th class="border-bottom border-top">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(category, index) in categories" :key="index">
                      <td>{{ category.category }}</td>
                      <td class="actions">
                        <button type="button" class="btn btn-default">
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="editCategory(category)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-primary" role="alert" v-else>
                  <p class="m-0">Ingen bransch hittades</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "../components/Navbar.vue";
export default {
  name: "admin-branches",
  data() {
    return {
      categoryID: null,
      category: null,
      editable: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      categories: (state) => state.category.categories,
    }),
  },

  components: { Navbar },

  mounted() {
    this.checkRouteName();

    document.title = "Branscher";
    this.$store.dispatch("category/fetchCategory");
  },

  methods: {
    checkRouteName() {
      let currentRoute = this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.name;
      this.$store.commit("sidebar/SET_ROUTE_NAME", currentRoute);
    },

    addCategory() {
      if (this.category) {
        let formData = new FormData();
        formData.append("category", this.category);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=addCategory", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.dispatch("category/fetchCategory");
              this.category = null;
            }
          });
      }
    },

    editCategory(category) {
      this.categoryID = category.categoryID;
      this.category = category.category;
      this.editable = true;
    },

    updateCategory() {
      if (this.categoryID !== null && this.editable) {
        let formData = new FormData();
        formData.append("categoryID", this.categoryID);
        formData.append("category", this.category);
        formData.append("key", this.apiKey);

        this.$http
          .post(this.baseUrl + "/edit.php?type=updateCategory", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.dispatch("category/fetchCategory");
              this.categoryID = null;
              this.category = null;
              this.editable = false;
            }
          });
      }
    },

    close() {
      this.categoryID = null;
      this.category = null;
      this.editable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  .table {
    .actions {
      text-align: right;
      button {
        margin: 0 5px;
        padding: 0;
      }
    }
  }
}
</style>
