import axios from "../../axios";

const state = {
  categories: [],
};

const getters = {
  getCategories(state) {
    return state.categories;
  },
};

const mutations = {
  FETCH_CATEGORY(state, payload) {
    state.categories = payload;
  },
};

const actions = {
  fetchCategory({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("key", rootState.auth.apiKey);
      axios
        .post(rootState.baseUrl + "/get.php?type=categories", formData)
        .then((response) => {
          commit("FETCH_CATEGORY", response.data.data);
          resolve(response);
        })
        .then((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
